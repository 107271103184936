import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { IUser, AuthError, AuthErrorType, defaultDebugSettings } from '@/Models/UserSession';

import useUserSessionStore from '@/Models/useUserSessionStore';
import { TKeyboardSettings } from '@/Models/Keyboard/types';

import API_URL from '@/Config/api.config';
import { defaultUserSettings, IUserSettings } from '@/Models/UserSession/types/configuration';
import { KEYBOARD_TYPE } from '@/Models/Keyboard/types/types';

// Constantes
const ENV = process.env.REACT_APP_ENV;
const IS_LOCAL_DEV = ENV === 'development' && API_URL.includes('192.168.');

// Headers por defecto
const defaultHeaders = {
  'Content-Type': 'application/json',
};

// Headers adicionales para desarrollo
const developmentHeaders = ENV === 'development' ? { 'Access-Control-Allow-Origin': '*' } : {};

// Configuración base de axios
const axiosConfig: AxiosRequestConfig = {
  baseURL: API_URL,
  withCredentials: true,
  timeout: 10000,
  headers: {
    ...defaultHeaders,
    ...developmentHeaders,
  },
};

// Crear instancia de axios con la configuración
const axiosInstance = axios.create(axiosConfig);

// Interceptor para manejar errores de certificado en desarrollo local
if (IS_LOCAL_DEV) {
  axiosInstance.interceptors.request.use(config => {
    if (config.url && !config.url.startsWith('https://')) {
      config.url = config.url.replace('http://', 'https://');
    }
    return config;
  });
}

export const getAxiosInstance = () => {
  return axiosInstance;
};

export const getAuthToken = () => {
  const userToken = localStorage.getItem('user');
  if (userToken) return { authToken: userToken };
  return { authToken: null };
};

const handleAuthError = (error: unknown): AuthError => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;

    if (axiosError.code === 'ECONNABORTED') {
      return {
        type: AuthErrorType.TIMEOUT,
        message: 'The request timed out. Please check your internet connection and try again.',
      };
    }

    if (!axiosError.response) {
      return {
        type: AuthErrorType.NETWORK,
        message: 'Unable to connect to the server. Please check your internet connection.',
      };
    }

    switch (axiosError.response.status) {
      case 401:
        return {
          type: AuthErrorType.UNAUTHORIZED,
          message: 'Invalid email or password.',
        };
      case 500:
      case 502:
      case 503:
      case 504:
        return {
          type: AuthErrorType.SERVER,
          message: 'Server error. Please try again later.',
        };
      default:
        return {
          type: AuthErrorType.UNKNOWN,
          message: 'An unexpected error occurred. Please try again.',
        };
    }
  }

  return {
    type: AuthErrorType.UNKNOWN,
    message: 'An unexpected error occurred. Please try again.',
  };
};

const handleSettings = (settings: IUserSettings): IUserSettings => {
  const keyboardInputMode = settings?.keyboardInputMode;
  const keyboardDistribution = settings?.keyboardDistribution;

  const selectedKeyboard =
    keyboardInputMode === 'POINTER'
      ? KEYBOARD_TYPE.HORIZONTAL_TRACKING_MOUSE
      : keyboardDistribution === 'HORIZONTAL'
        ? KEYBOARD_TYPE.HORIZONTAL_SPLIT_SELECTION
        : KEYBOARD_TYPE.VERTICAL_SPLIT_SELECTION;
  if (!settings?.version) {
    console.log('[STATUS] settings', settings);

    const { navigation, id } = settings;
    return {
      ...defaultUserSettings,
      id,
      navigation,
      selectedKeyboard,
      selectedKeyboardSettings: defaultUserSettings[selectedKeyboard] as TKeyboardSettings,
    };
  }
  console.log('[STATUS] settings', settings);
  return settings;
};

const bootstrapUser = async ({ authToken, settings: serverSettings, birthdate, email, id, name, role }: IUser): Promise<void> => {
  try {
    console.log('[STATUS] bootstrapUser: Starting user bootstrap process');

    if (!authToken) {
      console.error('[ERROR] bootstrapUser: Missing authToken');
      throw new Error('Missing authentication token');
    }

    console.log('[STATUS] bootstrapUser: Processing user settings');
    const settings: IUserSettings = handleSettings(serverSettings);

    console.log('[STATUS] bootstrapUser: Setting user session state', {
      hasAuthToken: !!authToken,
      hasSettings: !!settings,
      email,
      id,
      role,
    });

    useUserSessionStore.getState().setUserSessionState({
      authToken,
      settings,
      birthdate,
      email,
      id,
      name,
      role,
      debug: defaultDebugSettings, // Inicializamos con los valores por defecto
    });

    console.log('[SUCCESS] bootstrapUser: User session state updated successfully');
  } catch (error) {
    console.error('[ERROR] bootstrapUser: Failed to bootstrap user', error);
    throw new Error('Failed to initialize user session: ' + (error instanceof Error ? error.message : 'Unknown error'));
  }
};

export const login = async (email: string, password: string): Promise<IUser> => {
  try {
    console.log('[STATUS] login: Logging in user', { email });
    const response = await axiosInstance.post<IUser>('/user/login', {
      email,
      password,
    });
    console.log('[SUCCESS] login: User logged in successfully');
    await bootstrapUser(response.data);
    return response.data;
  } catch (error) {
    throw handleAuthError(error);
  }
};
