import styled from 'styled-components';
import { device } from '@/Layout/breakpoints';

export const HelperContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 4px 8px;
  border-top-left-radius: 8px;
  z-index: 9999;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 300;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  display: flex;
  flex-direction: column;
  gap: 2px;

  ${device.mobileLandscape} {
    font-size: 10px;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;

  span:first-child {
    opacity: 0.7;
    font-weight: 400;
  }

  span:last-child {
    font-weight: 300;
  }
`;
