import { TEYEGESTURE_TYPE, TGestureSettings, useGestureTrackingStore, EYEGESTURE_TYPE, getGestureSettingsFromState } from '@/Models/Gesture';

export const useAllGestureSettings = (): Record<TEYEGESTURE_TYPE, TGestureSettings> => {
  const store = useGestureTrackingStore();

  return Object.values(EYEGESTURE_TYPE).reduce(
    (acc, gestureType) => {
      acc[gestureType] = getGestureSettingsFromState(store, gestureType);
      return acc;
    },
    {} as Record<TEYEGESTURE_TYPE, TGestureSettings>
  );
};
