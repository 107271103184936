import React from 'react';
import logoutIcon from '@/Assets/icons/logout-svgrepo-com.svg';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { ButtonIcon } from '@/Components/Shared/ButtonIcon';
// import { LogoutButtonStyled } from './LogoutButton.styles';
import { BaseButton } from '@/Sections/Home/Shared/LoginButtonStyles';

const LogoutButton: React.FC = () => {
  const { logout } = useUserSessionStore();

  const handleLogout = () => {
    logout();
  };

  return (
    <BaseButton onClick={handleLogout}>
      <ButtonIcon src={logoutIcon} alt="logout icon" />
      <span>Cerrar Sesión</span>
    </BaseButton>
  );
};

export default LogoutButton;
