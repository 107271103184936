import { device } from '@/Layout/breakpoints';
import { FONT_SIZES, FontSize } from '@/Layout/typography';
import styled from 'styled-components';

export const PhraseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 2px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    line-height: 1em;
    margin: 0 0.8em;
  }

  /* ${device.mobilePortrait} {
    gap: 1px;
  } */
`;

export const StyledKeyboardKey = styled.div<{
  $isphrase: boolean;
  fontSize: FontSize;
  $isDark?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: 1px solid ${props => (props.$isDark ? '#ffffff30' : '#00000030')};
  border-radius: 8px;
  color: ${props => (props.$isDark ? 'rgb(227, 227, 227)' : 'rgb(33, 33, 33)')};
  background-color: ${props => (props.$isDark ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.95)')};
  font-size: ${({ fontSize }) => `${FONT_SIZES[fontSize]}px`};
  width: ${({ $isphrase }) => ($isphrase ? 'auto' : '2.4ch')};
  height: 33.33%;
  max-height: 33.33%;
  /* aspect-ratio: 1; */
  /* margin: 0; */
  /* font-weight: bold; */
  font-family: 'Roboto', sans-serif;
  /* text-align: center; */
  /* line-height: 1.2; */
  box-shadow: ${props => (props.$isDark ? '1px 2px 3px rgba(0, 0, 0, 0.2)' : '1px 2px 3px rgba(0, 0, 0, 0.1)')};
  text-shadow: ${props => (props.$isDark ? '1px 1px 2px rgb(0, 0, 0)' : '1px 1px 2px rgba(255, 255, 255, 0.5)')};
  text-transform: uppercase;
  white-space: pre-line;
  word-break: break-word;
  text-overflow: ellipsis;

  /* Mobile Portrait */
  ${device.mobilePortrait} {
    outline: 1px solid ${props => (props.$isDark ? '#ffffff30' : '#00000030')};
    font-size: ${({ fontSize }) => `${Math.round(FONT_SIZES[fontSize] * 0.8)}px`};
    width: ${({ $isphrase }) => ($isphrase ? '30%' : '2.2ch')};
  }

  /* Ajustes para vistas landscape */
  /* @media (orientation: landscape) {
    max-width: 33.33%;
    width: ${({ $isphrase }) => ($isphrase ? '48%' : '2.4ch')};
  } */

  /* Ajustes específicos para mobile landscape */
  ${device.mobileLandscape} {
    font-size: ${({ fontSize }) => `${Math.round(FONT_SIZES[fontSize] * 0.85)}px`};
    width: ${({ $isphrase }) => ($isphrase ? 'auto' : '2.5em')};
    height: 33.33%;
    max-height: 33.33%;
    min-width: 2.5em;
  }

  /* Ajustes específicos para tablet landscape */
  ${device.tabletPortrait} {
    font-size: ${({ fontSize }) => `${Math.round(FONT_SIZES[fontSize] * 0.95)}px`};
    width: ${({ $isphrase }) => ($isphrase ? 'auto' : '3em')};
    height: 30%;
    max-height: 33.33%;
    min-width: 3em;
  }

  /* Ajustes específicos para tablet landscape */
  ${device.tabletLandscape} {
    font-size: ${({ fontSize }) => `${Math.round(FONT_SIZES[fontSize] * 0.95)}px`};
    width: ${({ $isphrase }) => ($isphrase ? 'auto' : '3em')};
    height: 21%;
    max-height: 3em;
    min-width: 3em;
  }

  /* Ajustes específicos para desktop landscape */
  ${device.desktopLandscape} {
    outline: 1px solid ${props => (props.$isDark ? 'rgba(255, 255, 255, 0.469)' : 'rgba(0, 0, 0, 0.469)')};
    color: ${props => (props.$isDark ? 'rgb(227, 227, 227)' : 'rgb(33, 33, 33)')};
    font-size: ${({ fontSize }) => `${FONT_SIZES[fontSize]}px`};
    width: ${({ $isphrase }) => ($isphrase ? 'auto' : '3.2em')};
    height: 33.33%;
    min-height: 4em;
    min-width: 3em;
  }

  &:hover {
    transform: translateY(-1px);
    box-shadow: ${props => (props.$isDark ? '2px 4px 6px rgba(0, 0, 0, 0.3)' : '2px 4px 6px rgba(0, 0, 0, 0.2)')};
    cursor: pointer;
    background-color: ${props => (props.$isDark ? 'rgba(0, 0, 0, 0.9)' : 'rgba(255, 255, 255, 1)')};
  }

  &:active {
    transform: translateY(1px);
    box-shadow: none;
    transform: scale(0.98);
  }

  transition: all 0.1s ease-in-out;
`;
