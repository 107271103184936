import { useDetectionStore } from '@/Models/Detection';
import React, { useEffect, useRef } from 'react';
import { CounterContainer } from '../Detector.styles';
interface FpsCounterProps {
  className?: string;
}

export const FpsCounter: React.FC<FpsCounterProps> = ({ className }) => {
  const benchmark = useDetectionStore(state => state.benchmark);
  const setBenchmark = useDetectionStore(state => state.setBenchmark);
  const updateCountRef = useRef(0);
  const fpsRef = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      fpsRef.current = updateCountRef.current;
      setBenchmark({ FPS: updateCountRef.current });
      updateCountRef.current = 0;
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateCountRef.current++;
  });

  return <CounterContainer className={className}>FPS: {String(Math.round(benchmark.FPS)).padStart(3, '0')}</CounterContainer>;
};
