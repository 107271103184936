import React from 'react';
import { ReactComponent as AlertIcon } from '@/Assets/icons/alert-circle.svg';
import { AlertContainer } from './Alert.styles';

interface AlertProps {
  message: string;
  className?: string;
}

const Alert: React.FC<AlertProps> = ({ message, className }) => {
  return (
    <AlertContainer className={className} role="alert">
      <AlertIcon />
      {message}
    </AlertContainer>
  );
};

export default Alert;
