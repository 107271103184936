import React from 'react';
import { Container, Title } from '@/Components/ConfigModal/ConfigModal.styles';
import { KEYBOARD_TYPE } from '@/Models/Keyboard/types/types';
import { KeyboardIcons } from '../KeyboardIcons';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { OptionsContainer, Option, OptionLabel } from './KeyboardTypeSelector.styles';

interface KeyboardTypeSelectorProps {
  id?: string;
}

const KeyboardTypeSelector: React.FC<KeyboardTypeSelectorProps> = ({ id }) => {
  const { selectedType, setKeyboardType } = useUserSessionStore(state => ({
    selectedType: state.user.settings.selectedKeyboard,
    setKeyboardType: state.setKeyboardType,
  }));

  const options = [
    {
      value: KEYBOARD_TYPE.VERTICAL_SPLIT_SELECTION,
      label: 'Vertical Split',
    },
    {
      value: KEYBOARD_TYPE.HORIZONTAL_SPLIT_SELECTION,
      label: 'Horizontal Split',
    },
    {
      value: KEYBOARD_TYPE.HORIZONTAL_TRACKING_MOUSE,
      label: 'Mouse Tracking',
    },
  ];

  return (
    <Container id={id}>
      <Title>Tipo de Teclado</Title>
      <OptionsContainer>
        {options.map(({ value, label }) => {
          const Icon = KeyboardIcons[value];
          const checked = selectedType === value;

          return (
            <Option key={value} $checked={checked}>
              <input type="radio" name="keyboardType" value={value} checked={checked} onChange={() => setKeyboardType(value)} />
              <Icon checked={checked} />
              <OptionLabel $checked={checked}>{label}</OptionLabel>
            </Option>
          );
        })}
      </OptionsContainer>
    </Container>
  );
};

export default React.memo(KeyboardTypeSelector);
