import React, { memo, useMemo } from 'react';
import Cursor from '@/Components/Cursor/Cursor';
import { IKeyboardGestureStore, useGestureKeyboardStore } from '@/Models/Keyboard/store/useGestureKeyboardStore';
import { IUseTextToSpeechStore, useTextToSpeechStore } from '@/Models/useTextToSpeechStore';
import useUserSessionStore, { IUserSessionState } from '@/Models/useUserSessionStore';
import { KEYBOARD_TYPE } from '@/Models/Keyboard/types/types';
import { StyledContainer, PhraseText } from './PhraseContainer.styles';
import { useGestureState } from '@/Models/Gesture';
import { GESTUREMACHINE_STATE } from '@/Models/Gesture';

const { ACTIVE, CONFIRMING } = GESTUREMACHINE_STATE;
const { VERTICAL_SPLIT_SELECTION, HORIZONTAL_SPLIT_SELECTION } = KEYBOARD_TYPE;

// Separate selectors for each piece of state
const selectPhrase = (state: IKeyboardGestureStore) => state.phrase;
const selectKeyboardSettings = (state: IUserSessionState) => ({
  selectedKeyboard: state.user.settings.selectedKeyboard,
  phraseFontSize: state.user.settings.selectedKeyboardSettings.phraseFontSize,
});

const selectIsSynthesizing = (state: IUseTextToSpeechStore) => state.isSynthesizing;

const PhraseContainer: React.FC = () => {
  // Use specific selectors to only subscribe to needed state changes
  const keyboardSpeakGesture = useUserSessionStore.getState().getSpeakGesture();
  const gestureStatus = useGestureState(keyboardSpeakGesture);
  const phrase = useGestureKeyboardStore(selectPhrase);
  const { selectedKeyboard, phraseFontSize } = useUserSessionStore(selectKeyboardSettings);
  const isSynthesizing = useTextToSpeechStore(selectIsSynthesizing);

  const isVerticalLayout = useMemo(() => selectedKeyboard === VERTICAL_SPLIT_SELECTION || selectedKeyboard === HORIZONTAL_SPLIT_SELECTION, [selectedKeyboard]);

  const placeholderText = useMemo(() => 'Escribe un mensaje con tus ojos...', []);

  // console.log('gestureStatus', gestureStatus);

  return (
    <StyledContainer $active={gestureStatus === ACTIVE || gestureStatus === CONFIRMING} id="phrase-container" $isVertical={isVerticalLayout} $isSynthesizing={isSynthesizing}>
      <PhraseText $fontSize={phraseFontSize} $isSynthesizing={isSynthesizing}>
        {phrase === '' ? placeholderText : phrase}
      </PhraseText>
      <Cursor />
    </StyledContainer>
  );
};

PhraseContainer.displayName = 'PhraseContainer';

export default memo(PhraseContainer);
