import React, { useState, useMemo } from 'react';
import { useGestureState, TGESTUREMACHINE_STATE, useGestureSetting, GESTURE_SETTING_KEYS, EYEGESTURE_TYPE, TEYEGESTURE_TYPE } from '@/Models/Gesture';
import { useDetectionStore } from '@/Models/Detection';
import { DoomPanel, ToggleButton, PanelHeader, PanelContent, Section, SectionTitle, Table, Th, Td } from './DoomDebugPanel.styles';
import GestureRow from './GestureRow'; // Import the new GestureRow component

/**
 * Panel de depuración que muestra información sobre los gestos oculares
 * @returns Componente React
 */
const DoomDebugPanel: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    gestures: { scores, normalizedScores },
  } = useDetectionStore();

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <DoomPanel $isVisible={isVisible}>
      <ToggleButton onClick={toggleVisibility}>{isVisible ? '<' : '>'}</ToggleButton>
      <PanelHeader>DOOM Debug Panel</PanelHeader>
      <PanelContent>
        <Section>
          <SectionTitle>Gesture States</SectionTitle>
          <Table>
            <thead>
              <tr>
                <Th>Gesture</Th>
                <Th>Raw</Th>
                <Th>Nor</Th>
                <Th>Factor</Th>
                <Th>Ignore</Th>
                <Th>Confirm</Th>
                <Th>State</Th>
              </tr>
            </thead>
            <tbody>
              {(Object.values(EYEGESTURE_TYPE) as TEYEGESTURE_TYPE[]).map(gesture => {
                return <GestureRow key={gesture} gesture={gesture} />;
              })}
            </tbody>
          </Table>
        </Section>
      </PanelContent>
    </DoomPanel>
  );
};

export default DoomDebugPanel;
