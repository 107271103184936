import { device } from './breakpoints';

export const FONT_SIZES = {
  xs: 14,
  sm: 18,
  md: 22,
  lg: 26,
  xl: 32,
} as const;

export type FontSize = keyof typeof FONT_SIZES;

// Factores de escala para diferentes dispositivos
export const DEVICE_SCALE_FACTORS = {
  mobile: {
    portrait: 0.8,
    landscape: 0.85,
  },
  tablet: {
    portrait: 0.9,
    landscape: 0.95,
  },
  desktop: 1,
} as const;

// Función para obtener el tamaño de fuente escalado según el dispositivo
export const getScaledFontSize = (size: FontSize, deviceType: keyof typeof DEVICE_SCALE_FACTORS, orientation?: 'portrait' | 'landscape'): number => {
  const baseSize = FONT_SIZES[size];
  const deviceFactor = DEVICE_SCALE_FACTORS[deviceType];

  if (typeof deviceFactor === 'number') {
    return Math.round(baseSize * deviceFactor);
  }

  return Math.round(baseSize * deviceFactor[orientation || 'landscape']);
};
