import styled from 'styled-components';

export const FeedbackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 50%;
  transition: box-shadow 0.3s ease;
  scale: 0.7;
`;
