import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import SpeakGestureSelector from '@/Components/ConfigModal/Section/SpeakGestureSelector/SpeakGestureSelector';
import DetectionControl from '@/Components/ConfigModal/Section/DetectionControl';
import FontSizeControl from '@/Components/ConfigModal/Section/FontSizeControl';
import ThemeControl from '@/Components/ConfigModal/Section/ThemeControl';
import { ModalContent, Overlay, Modal, Header, CloseButton, DetectionColumn, ConfigColumn, TabsContainer, Tab, ConfigContent, DetectionWrapper } from '@/Components/ConfigModal/ConfigModal.styles';
import GestureControl from './Section/GestureControl/GestureControl';
import EyeStatusIndicator from './Section/EyeStatusIndicator/EyeStatusIndicator';
import KeyboardTypeSelector from './Section/KeyboardTypeSelector/KeyboardTypeSelector';
import { configModalTheme } from '@/theme/configModalTheme';

interface ConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ConfigModal = ({ isOpen, onClose }: ConfigModalProps) => {
  const [activeTab, setActiveTab] = useState<string>('keyboard');

  if (!isOpen) return null;

  const configSections = [
    { id: 'keyboard', title: 'Tipo de Teclado', component: KeyboardTypeSelector },
    { id: 'gestures', title: 'Control de Gestos', component: GestureControl },
    { id: 'font', title: 'Tamaño de Fuente', component: FontSizeControl },
    { id: 'theme', title: 'Tema de Color', component: ThemeControl },
    { id: 'voice', title: 'Gestos de Voz', component: SpeakGestureSelector },
    { id: 'eyes', title: 'Estado de los Ojos', component: EyeStatusIndicator },
  ];

  const activeSection = configSections.find(section => section.id === activeTab);
  const ActiveComponent = activeSection?.component;

  return (
    <ThemeProvider theme={configModalTheme}>
      <Overlay id="config-modal-overlay">
        <Modal id="config-modal">
          <Header id="config-modal-header">
            <h1>Configuración</h1>
            <CloseButton id="config-modal-close" onClick={onClose}>
              &times;
            </CloseButton>
          </Header>
          <ModalContent id="config-modal-content">
            <DetectionColumn id="detection-column">
              <DetectionWrapper id="detection-wrapper">
                <DetectionControl id="detection-control" />
              </DetectionWrapper>
              <TabsContainer id="config-tabs">
                {configSections.map(section => (
                  <Tab key={section.id} id={`tab-${section.id}`} $active={activeTab === section.id} onClick={() => setActiveTab(section.id)}>
                    {section.title}
                  </Tab>
                ))}
              </TabsContainer>
            </DetectionColumn>
            <ConfigColumn id="config-content-column">
              <ConfigContent id={`config-content-${activeSection?.id}`}>{ActiveComponent && <ActiveComponent id={`${activeSection.id}-control`} />}</ConfigContent>
            </ConfigColumn>
          </ModalContent>
        </Modal>
      </Overlay>
    </ThemeProvider>
  );
};

export default ConfigModal;
