import React, { useMemo } from 'react';
import { GESTUREMACHINE_STATE, TEYEGESTURE_TYPE, useGestureSetting, useGestureState, GESTURE_SETTING_KEYS } from '@/Models/Gesture';
import { useDetectionGetGestureScore } from '@/Models/Detection';
import { Td } from '@/Components/Debug/DoomDebugPanel.styles';

interface GestureRowProps {
  gesture: TEYEGESTURE_TYPE;
}

const { COMPLETED } = GESTUREMACHINE_STATE;
const { NORMALIZATION_FACTOR } = GESTURE_SETTING_KEYS;

const GestureRow: React.FC<GestureRowProps> = ({ gesture }) => {
  // Memoize the values to avoid unnecessary re-renders
  const { score, normalizedScore } = useDetectionGetGestureScore(gesture);
  const state = useGestureState(gesture);

  const normalizationFactor = useGestureSetting(gesture, NORMALIZATION_FACTOR);
  const ignoreThreshold = useGestureSetting(gesture, GESTURE_SETTING_KEYS.IGNORE_THRESHOLD);
  const confirmThreshold = useGestureSetting(gesture, GESTURE_SETTING_KEYS.CONFIRM_THRESHOLD);

  // Memoize the computed values
  const memoizedScore = useMemo(() => score, [score]);
  const memoizedNormalizedScore = useMemo(() => normalizedScore, [normalizedScore]);
  const memoizedNormalizationFactor = useMemo(() => normalizationFactor, [normalizationFactor]);

  return (
    <tr>
      <Td>{gesture}</Td>
      <Td>{memoizedScore?.toFixed(2) ?? 'N/A'}</Td>
      <Td>{memoizedNormalizedScore?.toFixed(2) ?? 'N/A'}</Td>
      <Td>{memoizedNormalizationFactor?.toFixed(2) ?? 'N/A'}</Td>
      <Td>{ignoreThreshold?.toFixed(2) ?? 'N/A'}</Td>
      <Td>{confirmThreshold?.toFixed(2) ?? 'N/A'}</Td>

      <Td $highlight={state === COMPLETED}>{state}</Td>
    </tr>
  );
};

export default GestureRow;
