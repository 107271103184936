export type DeviceType = 'desktop' | 'tablet' | 'mobile';

// Device breakpoints in pixels
export const BREAKPOINTS = {
  // Mobile breakpoints
  MOBILE_MIN_WIDTH: 360, // Smallest common mobile width
  MOBILE_MAX_WIDTH: 430, // Largest mobile width (iPhone 15 Pro Max)
  MOBILE_MIN_HEIGHT: 640, // Minimum height for modern phones
  MOBILE_MAX_HEIGHT: 932, // Maximum height (iPhone 15 Pro Max)
  MOBILE_LANDSCAPE_MAX_HEIGHT: 500, // Maximum height for mobile in landscape

  // Tablet breakpoints (incluyendo iPad Pro)
  TABLET_MIN_WIDTH: 600, // Smaller tablets
  TABLET_MAX_WIDTH: 1366, // iPad Pro max width
  TABLET_MIN_HEIGHT: 800, // Minimum height for tablets
  TABLET_MAX_HEIGHT: 1024, // Regular tablet height

  // Desktop breakpoints (1080p y superiores)
  DESKTOP_MIN: 1920, // Full HD width
};

// Helper function to determine device type based on screen dimensions
export const getDeviceType = (width: number, height: number): DeviceType => {
  const aspectRatio = width / height;
  const isLandscape = aspectRatio > 1;

  // Check if it's a mobile device first
  if (width <= BREAKPOINTS.MOBILE_MAX_WIDTH || (height <= BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT && isLandscape)) {
    return 'mobile';
  }

  // Check if it's desktop (1080p o superior)
  if (width >= BREAKPOINTS.DESKTOP_MIN) {
    return 'desktop';
  }

  // Si no es mobile ni desktop, es tablet
  return 'tablet';
};

// Media queries
export const device = {
  // Mobile portrait
  mobilePortrait: `@media 
    (max-width: ${BREAKPOINTS.MOBILE_MAX_WIDTH}px) and 
    (orientation: portrait)`,

  // Mobile landscape
  mobileLandscape: `@media 
    (max-height: ${BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT}px) and 
    (orientation: landscape)`,

  // Tablet portrait
  tabletPortrait: `@media 
    (min-width: ${BREAKPOINTS.TABLET_MIN_WIDTH}px) and 
    (max-width: ${BREAKPOINTS.DESKTOP_MIN - 1}px) and 
    (orientation: portrait)`,

  // Tablet landscape
  tabletLandscape: `@media 
    (min-width: ${BREAKPOINTS.TABLET_MIN_WIDTH}px) and 
    (max-width: ${BREAKPOINTS.DESKTOP_MIN - 1}px) and 
    (orientation: landscape)`,

  // Desktop portrait
  desktopPortrait: `@media 
    (min-width: ${BREAKPOINTS.DESKTOP_MIN}px) and 
    (orientation: portrait)`,

  // Desktop landscape
  desktopLandscape: `@media 
    (min-width: ${BREAKPOINTS.DESKTOP_MIN}px) and 
    (orientation: landscape)`,
};
