import React from 'react';
import ReactDOM from 'react-dom/client';
// import { ThemeProvider } from 'styled-components';
import reportWebVitals from './reportWebVitals';
import App from './App';
// import { theme } from './Theme/theme';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <ThemeProvider theme={theme}>
  <App />
  // </ThemeProvider>
);

reportWebVitals();
