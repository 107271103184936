import React, { useCallback, useState } from 'react';
import { useDetectionComputeScores, useDetectionLandMarker, useDetectionRangeFiltering } from '@/Models/Detection';
import { TEYEGESTURE_TYPE } from '@/Models/Gesture';
import { DetectionContainer, PauseButtonContainer } from './Detector.styles';
import DetectionFeedBack from './DetectionFeedBack';
import WebcamComponent from './WebcamComponent';
import { PauseButton } from '@/Components/PauseButton';
import { FpsCounter } from './Counters/FpsCounter';
import { ComputesCounter } from './Counters/ComputesCounter';
import { PredictionsCounter } from './Counters/PredictionsCounter';
import { useGestureTrackerMachine } from '@/Models/Gesture';

export const Detector: React.FC = () => {
  // Estado para almacenar los gestos completados
  // const [completedGestures, setCompletedGestures] = useState<string[]>([]);

  // Callback para cuando un gesto se completa
  // const handleGestureCompleted = useCallback((gestureType: TEYEGESTURE_TYPE) => {
  //   console.log(`Gesto completado: ${gestureType}`);
  //   // Agregar el gesto completado al estado
  //   setCompletedGestures(prev => [...prev, gestureType]);
  //   // Aquí puedes agregar la lógica adicional que necesites cuando un gesto se completa
  // }, []);

  // Otros hooks
  useDetectionLandMarker();
  useDetectionRangeFiltering();
  useDetectionComputeScores();
  useGestureTrackerMachine();

  return (
    <DetectionContainer id="detector">
      <WebcamComponent />
      <DetectionFeedBack />
      <PauseButtonContainer>
        <PauseButton size="small" />
      </PauseButtonContainer>
      {/* Inicializador de gestos */}
      {/* Mostrar los últimos 5 gestos completados */}

      <FpsCounter />
      {/* <ComputesCounter /> */}
      {/* <PredictionsCounter /> */}
    </DetectionContainer>
  );
};
