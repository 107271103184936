import styled from 'styled-components';
import { device } from '@/Layout/breakpoints';

export const RangeContainer = styled.div`
  position: relative;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const RangeLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1.5em;
  width: 100%;

  span:last-child {
    min-width: 2.5em;
    text-align: right;
  }
`;

export const RangeSlider = styled.div`
  position: relative;
  width: 100%;
  height: 24px;
  user-select: none;
`;

export const RangeProgress = styled.div`
  position: absolute;
  width: 100%;
  height: ${({ theme }) => theme.sizes.slider.track.height};
  border-radius: ${({ theme }) => theme.sizes.slider.track.borderRadius};
  outline: ${({ theme }) => theme.colors.border.width.sm} solid ${({ theme }) => theme.colors.control.border.default};
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  overflow: hidden;

  > div {
    position: absolute;
    height: 100%;
    border-radius: ${({ theme }) => theme.sizes.slider.track.borderRadius};
  }
`;

export const SliderTrack = styled.div<{ $isDragging: boolean }>`
  position: absolute;
  width: 100%;
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
  ${({ $isDragging }) => $isDragging && 'pointer-events: none;'}
`;

export const SliderRange = styled.div<{ $active?: boolean; $score?: number }>`
  position: absolute;
  height: 8px;
  background: ${props => (props.$score ? '#fff' : props.$active ? '#28a745' : 'rgba(0, 123, 255, 0.5)')};
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
`;

export const SliderThumb = styled.div<{ $active?: boolean; $isDragging?: boolean }>`
  position: absolute;
  width: 1em;
  height: 3em;
  background: ${props => (props.$active ? '#28a745' : '#007bff')};
  border-radius: 1em;
  top: 50%;
  transform: translate(-50%, -50%) ${props => (props.$isDragging ? 'scale(1.1)' : 'scale(1)')};
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition:
    transform 0.2s,
    background-color 0.2s;
  z-index: 2;

  &:hover {
    background: ${props => (props.$active ? '#218838' : '#0056b3')};
  }

  &:active {
    background: ${props => (props.$active ? '#1e7e34' : '#004494')};
    transform: translate(-50%, -50%) scale(1.1);
  }
`;

export const ThumbValue = styled.div<{ $left: string }>`
  position: absolute;
  bottom: -3.5em;
  left: ${props => props.$left};
  transform: translateX(-50%);
  background: rgba(0, 0, 0);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 2rem;
  pointer-events: none;
  opacity: 1;
  z-index: 100;
  outline: 1px solid rgba(255, 255, 255, 0.7);
`;
