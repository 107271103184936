import React, { useCallback, useMemo } from 'react';
import Gestureflag from '..';
import waveform from '@/Assets/icons/waveform.svg';
import volumeUp from '@/Assets/icons/volume-up.svg';
import useGestureKeyboardStore from '@/Models/Keyboard/store/useGestureKeyboardStore';

import useTextToSpeech from '@/Services/useTextToSpeech';
import { SpeakWrapper, SpeakText, GestureFlagWrapper } from './Speak.styles';
import { KEYBOARD_TYPE } from '@/Models/Keyboard/types/types';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { GESTUREMACHINE_STATE, TEYEGESTURE_TYPE, useGestureState } from '@/Models/Gesture';

const Speak: React.FC = React.memo(function Speak() {
  const {
    user: {
      settings: { selectedKeyboard, selectedKeyboardSettings },
    },
  } = useUserSessionStore();
  const { keyboardSpeakGesture } = selectedKeyboardSettings;
  const { currentGesture, phrase } = useGestureKeyboardStore();

  const { isSynthesizing, handleSynthesis } = useTextToSpeech();

  const { HORIZONTAL_SPLIT_SELECTION, VERTICAL_SPLIT_SELECTION, HORIZONTAL_TRACKING_MOUSE } = KEYBOARD_TYPE;
  const { IDLE, ACTIVE, CONFIRMING, COMPLETED } = GESTUREMACHINE_STATE;

  // Obtener el estado actual del gesto
  const gestureState = useGestureState(keyboardSpeakGesture);

  // Memoize the check for valid keyboard types
  const isValidKeyboardType = useMemo(() => {
    return [HORIZONTAL_SPLIT_SELECTION, VERTICAL_SPLIT_SELECTION].includes(selectedKeyboard);
  }, [HORIZONTAL_SPLIT_SELECTION, VERTICAL_SPLIT_SELECTION, selectedKeyboard]);

  // Handle click on the speak button
  const handleClick = useCallback(() => {
    if (isValidKeyboardType && phrase && !isSynthesizing) {
      handleSynthesis(phrase);
    }
  }, [isValidKeyboardType, phrase, isSynthesizing, handleSynthesis]);

  // Memoize the icon selection
  const iconUrl = useMemo(() => (isSynthesizing ? volumeUp : waveform), [isSynthesizing]);

  return (
    <SpeakWrapper id="speak-feedback" $running={isSynthesizing} $keyboardType={selectedKeyboard} onClick={handleClick}>
      <GestureFlagWrapper>
        <Gestureflag active={gestureState !== IDLE} svgUrl={iconUrl} fill={isSynthesizing} />
      </GestureFlagWrapper>
      <SpeakText $keyboardType={selectedKeyboard}>PRONUNCIAR</SpeakText>
    </SpeakWrapper>
  );
});

// Añadir displayName para mejor debugging
Speak.displayName = 'Speak';

export default Speak;
