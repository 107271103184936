import { useState, useEffect } from 'react';
import { UI_CONTROL } from '@/Models/UserSession/types/domain';

interface DebounceOptions {
  delay?: number;
  immediate?: boolean;
  onChange?: (value: number) => void;
  minDiff?: number;
}

export function useDebounceValue(initialValue: number, options: DebounceOptions = {}) {
  const { delay = UI_CONTROL.DEBOUNCE.DEFAULT_DELAY, immediate = false, onChange, minDiff = UI_CONTROL.DEBOUNCE.MIN_DIFF } = options;

  const [immediateValue, setImmediateValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);
  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  useEffect(() => {
    // Si el cambio es muy pequeño, no actualizamos
    if (Math.abs(debouncedValue - immediateValue) < minDiff) {
      return;
    }

    const now = Date.now();
    // Si ha pasado poco tiempo desde la última actualización, esperamos más
    if (now - lastUpdateTime < delay / 2) {
      const handler = setTimeout(() => {
        setDebouncedValue(immediateValue);
        setLastUpdateTime(now);
        onChange?.(immediateValue);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    } else {
      // Si ha pasado suficiente tiempo, actualizamos inmediatamente
      setDebouncedValue(immediateValue);
      setLastUpdateTime(now);
      onChange?.(immediateValue);
    }
  }, [immediateValue, delay, minDiff, onChange, lastUpdateTime, debouncedValue]);

  return {
    value: immediate ? immediateValue : debouncedValue,
    immediateValue,
    debouncedValue,
    setValue: setImmediateValue,
  };
}
