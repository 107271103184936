// Spacing
export const SPACING = {
  XXS: '0.5em',
  XS: '1em',
  SM: '1.25em',
  MD: '1.5em',
  LG: '2em',
  XL: '3em',
} as const;

// Heights
export const HEIGHTS = {
  HEADER: {
    MIN: '70px',
    MAX: '256px',
    MOBILE: {
      MIN: '90px',
      MAX: '140px',
      CLAMP: 'clamp(90px, 16%, 140px)',
    },
    TABLET: {
      PORTRAIT: {
        MIN: '100px',
        MAX: '140px',
        CLAMP: 'clamp(100px, 18vh, 140px)',
      },
      LANDSCAPE: {
        MIN: '80px',
        MAX: '120px',
        CLAMP: 'clamp(80px, 15vh, 120px)',
      },
    },
    DESKTOP: {
      PORTRAIT: {
        MIN: '70px',
        MAX: '240px',
        CLAMP: 'clamp(70px, 20vh, 240px)',
      },
      LANDSCAPE: {
        MIN: '80px',
        MAX: '256px',
        CLAMP: 'clamp(80px, 25vh, 256px)',
      },
    },
  },
} as const;

// Flex ratios
export const FLEX = {
  DETECTOR_COLUMN: {
    MOBILE: 2,
  },
  ACTIONS_COLUMN: {
    MOBILE: 3,
    TABLET: 1,
  },
} as const;

// Colors with alpha
export const COLORS = {
  BACKGROUND: {
    PRIMARY: '#000000',
    SECONDARY: '#1a1a1a',
    OVERLAY: 'rgba(0, 0, 0, 0.7)',
    DEBUG: {
      GREEN: 'rgba(5, 255, 80, 0.5)',
      GREEN_LIGHT: 'rgba(5, 255, 80, 0.1)',
      PURPLE: 'rgba(128, 0, 128, 0.1)',
    },
  },
  BORDER: {
    DEBUG: 'red',
    PRIMARY: '#333',
  },
} as const;
