import React, { useCallback, useMemo } from 'react';
import useUserSessionStore from '@/Models/useUserSessionStore';
import waveform from '@/Assets/icons/waveform.svg';
import volumeUp from '@/Assets/icons/volume-up.svg';
import useTextToSpeech from '@/Services/useTextToSpeech';
import Gestureflag from '@/Components/GestureFlag';
import { KEYBOARD_TYPE } from '@/Models/Keyboard/types/types';
import { EYEGESTURE_TYPE, GESTUREMACHINE_STATE } from '@/Models/Gesture';
import { useDetectionStore } from '@/Models/Detection';
import useGestureKeyboardStore from '@/Models/Keyboard/store/useGestureKeyboardStore';

import { BaseButton } from '@/Sections/Home/Shared/LoginButtonStyles';
import { useGestureState } from '../../../Models/Gesture/hooks/useGestureState';

const SpeakButton: React.FC = () => {
  const { isSynthesizing, handleSynthesis } = useTextToSpeech();

  const { ACTIVE } = GESTUREMACHINE_STATE;

  const keyboardSpeakGesture = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.keyboardSpeakGesture);
  const speakGestureState = useGestureState(keyboardSpeakGesture);

  const selectedKeyboard = useUserSessionStore(state => state.user.settings.selectedKeyboard);
  const phrase = useGestureKeyboardStore(state => state.phrase);

  const iconUrl = useMemo(() => (isSynthesizing ? volumeUp : waveform), [isSynthesizing]);

  // Memoize handleClick to prevent recreation on every render
  const handleClick = useCallback(() => {
    if (selectedKeyboard === KEYBOARD_TYPE.HORIZONTAL_TRACKING_MOUSE) {
      handleSynthesis(phrase);
    }
  }, [selectedKeyboard, handleSynthesis, phrase]);

  return (
    <BaseButton onClick={handleClick}>
      {/* <ButtonIcon src={logoutIcon} alt="logout icon" /> */}
      <Gestureflag active={speakGestureState === ACTIVE} svgUrl={iconUrl} fill={true} />
      {/* <Speak /> */}
      <span>Pronunciar</span>
    </BaseButton>
  );
};

export default SpeakButton;
