import {
  TGestureTrackingStore,
  TEYEGESTURE_TYPE,
  TGESTURE_SETTING_KEYS,
  TGestureSettings,
  defaultGestureSettings,
  TGESTUREMACHINE_STATE,
  useGestureTrackingStore,
  GESTUREMACHINE_STATE,
} from '@/Models/Gesture';

/**
 * Función interna para obtener el estado de un gesto desde el store
 * @param state - Estado del store
 * @param gestureType - Tipo de gesto
 * @returns Estado actual del gesto
 */
export function getGestureStateFromState(state: TGestureTrackingStore, gestureType: TEYEGESTURE_TYPE): TGESTUREMACHINE_STATE {
  const interpreter = state.gestures[gestureType];
  if (!interpreter) {
    console.warn(`Advertencia: El intérprete para el gesto ${gestureType} no existe en el store. Usando estado por defecto.`);
    return GESTUREMACHINE_STATE.IDLE;
  }

  const context = interpreter.getSnapshot().context;
  if (!context) {
    console.warn(`Advertencia: El contexto para el gesto ${gestureType} no existe. Usando estado por defecto.`);
    return GESTUREMACHINE_STATE.IDLE;
  }

  return (state.gestures[gestureType]?.getSnapshot().value as TGESTUREMACHINE_STATE) ?? GESTUREMACHINE_STATE.IDLE;
}

/**
 * Función interna para obtener la configuración de un gesto desde el store
 * @param state - Estado del store
 * @param gestureType - Tipo de gesto
 * @returns Configuración del gesto
 */
export function getGestureSettingsFromState(state: TGestureTrackingStore, gestureType: TEYEGESTURE_TYPE): TGestureSettings {
  const interpreter = state.gestures[gestureType];
  if (!interpreter) {
    console.warn(`Advertencia: El intérprete para el gesto ${gestureType} no existe en el store. Usando configuración por defecto.`);
    return { ...defaultGestureSettings };
  }

  const context = interpreter.getSnapshot().context;
  if (!context?.settings) {
    console.warn(`Advertencia: La configuración para el gesto ${gestureType} no existe en el contexto de la máquina de estados. Usando configuración por defecto.`);
    return { ...defaultGestureSettings };
  }

  return context.settings;
}

export function getGestureSettingFromState<K extends TGESTURE_SETTING_KEYS>(state: TGestureTrackingStore, gestureType: TEYEGESTURE_TYPE | undefined, settingKey: K | undefined): TGestureSettings[K] {
  if (!gestureType) {
    throw new Error('Error crítico: El tipo de gesto no está definido');
  }

  if (!settingKey) {
    throw new Error('Error crítico: La clave de configuración no está definida');
  }

  const settings = getGestureSettingsFromState(state, gestureType);

  const settingValue = settings[settingKey];
  if (settingValue === undefined) {
    console.warn(`Advertencia: El atributo ${String(settingKey)} no existe en la configuración del gesto ${gestureType}. Usando valor por defecto.`);
    return defaultGestureSettings[settingKey];
  }

  return settingValue;
}
