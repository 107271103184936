import React from 'react';
import settingsIcon from '@/Assets/icons/settings.svg';
import { ButtonIcon } from '@/Components/Shared/ButtonIcon';
import { BaseButton } from '@/Sections/Home/Shared/LoginButtonStyles';

interface ConfigButtonProps {
  onClick: () => void;
}

const ConfigButton: React.FC<ConfigButtonProps> = ({ onClick }) => {
  return (
    <BaseButton onClick={onClick}>
      <ButtonIcon src={settingsIcon} alt="settings icon" />
      <span>Configurar</span>
    </BaseButton>
  );
};

export default ConfigButton;
