import React, { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import useGestureKeyboardStore from '@/Models/Keyboard/store/useGestureKeyboardStore';

import useUserSessionStore from '@/Models/useUserSessionStore';
import { FONT_SIZES, FontSize } from '@/Layout/typography';
import { device } from '@/Layout/breakpoints';

const blink = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StyledCursor = styled.div<{ $withSpace: boolean; $fontSize: FontSize }>`
  display: inline-block;
  animation: ${blink} 0.7s infinite;
  border-bottom: 5px solid white;
  margin-left: ${props => (props.$withSpace ? '0.25em' : '0')};
  font-size: ${({ $fontSize }) => `${FONT_SIZES[$fontSize]}px`};

  /* Mobile Portrait */
  ${device.mobilePortrait} {
    font-size: ${({ $fontSize }) => `${Math.round(FONT_SIZES[$fontSize] * 0.8)}px`};
    border-bottom-width: 3px;
  }

  /* Mobile Landscape */
  ${device.mobileLandscape} {
    font-size: ${({ $fontSize }) => `${Math.round(FONT_SIZES[$fontSize] * 0.85)}px`};
    border-bottom-width: 3px;
  }

  /* Tablet Portrait */
  ${device.tabletPortrait} {
    font-size: ${({ $fontSize }) => `${Math.round(FONT_SIZES[$fontSize] * 0.9)}px`};
    border-bottom-width: 4px;
  }

  /* Tablet Landscape */
  ${device.tabletLandscape} {
    font-size: ${({ $fontSize }) => `${Math.round(FONT_SIZES[$fontSize] * 0.95)}px`};
    border-bottom-width: 4px;
  }
`;

const Cursor: React.FC = React.memo(() => {
  const phraseFontSize = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.phraseFontSize);
  const phrase = useGestureKeyboardStore(state => state.phrase);

  const isVisible = phrase !== '';
  const withSpace = useMemo(() => phrase.length !== 0 && phrase[phrase.length - 1] !== ' ', [phrase]);

  if (!isVisible) return null;

  return (
    <StyledCursor $withSpace={withSpace} $fontSize={phraseFontSize}>
      _
    </StyledCursor>
  );
});

Cursor.displayName = 'Cursor';

export default Cursor;
