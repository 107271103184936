import React, { useMemo } from 'react';
import { Title } from '@/Components/ConfigModal/ConfigModal.styles';
import { Container } from '../../ConfigModal.styles';
import { EYEGESTURE_TYPE, TEYEGESTURE_TYPE } from '@/Models/Gesture';
import { KEYBOARD_AVAILABLE_GESTURES } from '@/Models/Keyboard/types/domain';
import PerGestureSettings from './PerGestureSettings';
import useUserSessionStore from '@/Models/useUserSessionStore';

interface GestureControlProps {
  id?: string;
}

const { LOOK_LEFT, LOOK_RIGHT, LOOK_UP, LOOK_DOWN, BLINK } = EYEGESTURE_TYPE;

const GestureControl: React.FC<GestureControlProps> = React.memo(({ id }) => {
  const selectedKeyboard = useUserSessionStore(state => state.user.settings.selectedKeyboard);
  const setKeyboardType = useUserSessionStore(state => state.setKeyboardType);

  const gestureSettings = useMemo(
    () =>
      [
        { gesture: LOOK_LEFT, title: 'Rango de detección gesto mirar a la izquierda' },
        { gesture: LOOK_RIGHT, title: 'Rango de detección gesto mirar a la derecha' },
        { gesture: LOOK_UP, title: 'Rango de detección gesto mirar arriba' },
        { gesture: LOOK_DOWN, title: 'Rango de detección gesto mirar abajo' },
        { gesture: BLINK, title: 'Detección gesto parpadear' },
      ].filter(gesture => KEYBOARD_AVAILABLE_GESTURES[selectedKeyboard].includes(gesture.gesture as TEYEGESTURE_TYPE)),
    [selectedKeyboard]
  );

  console.log(gestureSettings);
  return (
    <Container id={id}>
      <Title>Control de Gestos</Title>
      {gestureSettings.map(({ gesture, title }) => (
        <PerGestureSettings key={gesture} gesture={gesture} title={title} />
      ))}
    </Container>
  );
});

GestureControl.displayName = 'GestureControl';

export default GestureControl;
