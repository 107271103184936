import styled from 'styled-components';
import { device } from '@/Layout/breakpoints';

export const ControlGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5em, 1fr));
  gap: ${({ theme }) => theme.spacing.lg};
  width: 100%;
  padding: ${({ theme }) => theme.spacing.lg};

  ${device.mobilePortrait} {
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spacing.sm};
    padding: ${({ theme }) => theme.spacing.sm};
  }

  ${device.mobileLandscape} {
    grid-template-columns: repeat(auto-fit, minmax(12.5em, 1fr));
  }
`;

export const RadioOption = styled.label<{ checked: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${({ theme }) => theme.colors.border.width.md} solid ${({ checked, theme }) => (checked ? theme.colors.accent.blue : theme.colors.border.primary)};
  cursor: pointer;
  transition: all ${({ theme }) => theme.transitions.normal};
  background: ${({ checked, theme }) => (checked ? theme.colors.background.hover : theme.colors.background.primary)};

  input[type='radio'] {
    display: none;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.background.hover};
    border-color: ${({ theme }) => theme.colors.accent.blue};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  width: 100%;
  height: 5em;
`;

export const OptionText = styled.span<{ checked: boolean }>`
  font-size: 14px;
  color: ${({ checked, theme }) => (checked ? theme.colors.text.primary : theme.colors.text.secondary)};
  text-align: center;
  transition: color 0.3s ease;
  margin-top: 10px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.colors.background.secondary};
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-top: ${({ theme }) => theme.spacing.md};
`;

export const Option = styled.label<{ $checked: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.sm};
  border: ${({ theme }) => theme.colors.border.width.md} solid ${({ $checked, theme }) => ($checked ? theme.colors.accent.blue : theme.colors.border.primary)};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: pointer;
  transition: all ${({ theme }) => theme.transitions.normal};
  background: ${({ theme }) => theme.colors.background.primary};

  &:hover {
    border-color: ${({ $checked, theme }) => ($checked ? theme.colors.accent.blue : theme.colors.text.primary)};
    background: ${({ theme }) => theme.colors.background.hover};
  }

  input {
    display: none;
  }
`;

export const OptionLabel = styled.span<{ $checked: boolean }>`
  color: ${({ $checked, theme }) => ($checked ? theme.colors.accent.blue : theme.colors.text.secondary)};
  font-size: 0.9em;
  transition: color ${({ theme }) => theme.transitions.normal};
  margin-top: ${({ theme }) => theme.spacing.xs};

  ${Option}:hover & {
    color: ${({ $checked, theme }) => ($checked ? theme.colors.accent.blue : theme.colors.text.primary)};
  }
`;
