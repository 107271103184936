import React from 'react';
import { TEYEGESTURE_TYPE } from '@/Models/Gesture';
import MultiRangeSlider from './MultiRangeSlider/MultiRangeSlider';
import ConfirmationSlider from './ConfirmationSlider/ConfirmationSlider';
import ConfirmationFeedback from './ConfirmationFeedback/ConfirmationFeedback';
import NormalizationSlider from './NormalizationSlider/NormalizationSlider';
import { GroupContainer, SliderLabel, GridContainer, GridItem } from './PerGestureSettings.styles';

interface PerGestureSettingsProps {
  gesture: TEYEGESTURE_TYPE;

  title: string;
}

const MemoizedGridItem = React.memo(({ children, isDetection = false }: { children: React.ReactNode; isDetection?: boolean }) => <GridItem $isDetection={isDetection}>{children}</GridItem>);

MemoizedGridItem.displayName = 'MemoizedGridItem';

const PerGestureSettings: React.FC<PerGestureSettingsProps> = React.memo(({ gesture, title }) => {
  return (
    <GroupContainer>
      <SliderLabel>
        <span>{title}</span>
      </SliderLabel>
      <GridContainer>
        {/* <MemoizedGridItem isDetection>
          <DetectionStatus gesture={gesture} />
        </MemoizedGridItem> */}
        <MemoizedGridItem isDetection>
          <ConfirmationFeedback gesture={gesture} />
        </MemoizedGridItem>
        <MemoizedGridItem>
          <MultiRangeSlider gesture={gesture} />
        </MemoizedGridItem>
        <MemoizedGridItem>
          <ConfirmationSlider gesture={gesture} />
        </MemoizedGridItem>
        <MemoizedGridItem>
          <NormalizationSlider gesture={gesture} />
        </MemoizedGridItem>
      </GridContainer>
    </GroupContainer>
  );
});

PerGestureSettings.displayName = 'PerGestureSettings';

export default PerGestureSettings;
