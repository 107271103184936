import { create } from 'zustand';
import { interpret, Interpreter } from 'xstate';
import createGestureTrackingMachine from '../gestureTrackingMachine';
import { TEYEGESTURE_TYPE } from '../types/base/gestureEnums';
import { IGestureMachineContext, TGestureSettings } from '../types';
import { GestureMachineEvent, TGestureMachineEvent } from '../types';
import { IGestureStateSchema, TGestureTypestate } from '../types';
import useDetectionStore from '@/Models/Detection/store/useDetectionStore';

/**
 * Tipo para las funciones de cancelación de suscripciones
 */
type TUnsubscribeFunction = () => void;

/**
 * Define store state for gesture tracking
 */
export type TGestureTrackingStore = {
  /**
   * Record of gesture interpreters indexed by gesture type
   */
  gestures: Record<TEYEGESTURE_TYPE, Interpreter<IGestureMachineContext, IGestureStateSchema, TGestureMachineEvent, TGestureTypestate>>;

  /**
   * Record of unsubscribe functions for each gesture's score subscription
   */
  subscriptions: Record<TEYEGESTURE_TYPE, TUnsubscribeFunction | undefined>;

  /**
   * Configure a gesture with specific settings
   * @param gestureType - Type of gesture to configure
   * @param config - Configuration settings for the gesture
   */
  configureGesture: (gestureType: TEYEGESTURE_TYPE, config: TGestureSettings) => void;

  /**
   * Update the score for a specific gesture
   * @param gestureType - Type of gesture to update
   * @param score - New score value
   */
  updateScore: (gestureType: TEYEGESTURE_TYPE, score: number) => void;

  /**
   * Cancel a gesture detection
   * @param gestureType - Type of gesture to cancel
   */
  cancelGesture: (gestureType: TEYEGESTURE_TYPE) => void;
};

/**
 * Create store with Zustand for tracking gesture states
 */
export const useGestureTrackingStore = create<TGestureTrackingStore>((set, get) => ({
  gestures: {} as Record<TEYEGESTURE_TYPE, Interpreter<IGestureMachineContext, IGestureStateSchema, TGestureMachineEvent, TGestureTypestate>>,
  subscriptions: {} as Record<TEYEGESTURE_TYPE, TUnsubscribeFunction | undefined>,

  /**
   * Configure a gesture with specific settings
   * @param gestureType - Type of gesture to configure
   * @param config - Configuration settings for the gesture
   *
   * This function creates a new gesture tracking machine for the specified gesture type,
   * initializes the interpreter, and sends the initial configuration event.
   * It also subscribes to the detection store to automatically update scores.
   */
  configureGesture: (gestureType: TEYEGESTURE_TYPE, config: TGestureSettings) =>
    set((state: TGestureTrackingStore) => {
      // Create the state machine for the specified gesture type
      const machine = createGestureTrackingMachine(gestureType, config);
      // Start the interpreter
      const interpreter = interpret(machine).start();
      // Send the initial configuration event
      interpreter.send({
        type: GestureMachineEvent.CONFIG,
        ...config,
      } as TGestureMachineEvent);

      // Cancelar cualquier suscripción existente para este gesto
      const unsubscribeFn = state.subscriptions[gestureType];
      if (unsubscribeFn && typeof unsubscribeFn === 'function') {
        unsubscribeFn();
      }

      // Suscribirse a los cambios en el detection store para este gesto específico
      // Guardamos el valor actual para comparar
      let currentScore = useDetectionStore.getState().gestures.normalizedScores[gestureType];

      // Creamos una suscripción que se ejecuta cuando cambia el estado
      const unsubscribe = useDetectionStore.subscribe(state => {
        const newScore = state.gestures.normalizedScores[gestureType];
        // Solo actualizamos si el score ha cambiado
        if (newScore !== undefined && newScore !== currentScore) {
          currentScore = newScore;
          get().updateScore(gestureType, newScore);
        }
      });

      // Update the store with the new interpreter and subscription
      return {
        gestures: {
          ...state.gestures,
          [gestureType]: interpreter,
        },
        subscriptions: {
          ...state.subscriptions,
          [gestureType]: unsubscribe,
        },
      };
    }),

  /**
   * Update the score for a specific gesture
   * @param gestureType - Type of gesture to update
   * @param score - New score value
   *
   * This function updates the score for the specified gesture type by sending an UPDATE_SCORE event to the interpreter.
   */
  updateScore: (gestureType: TEYEGESTURE_TYPE, score: number) =>
    set((state: TGestureTrackingStore) => {
      // Get the interpreter for the gesture type
      const gestureInterpreter = state.gestures[gestureType];
      // If it exists, send the UPDATE_SCORE event
      if (gestureInterpreter) {
        gestureInterpreter.send({
          type: GestureMachineEvent.UPDATE_SCORE,
          score,
        } as TGestureMachineEvent);
      }
      // Update the store (even if there are no actual changes, to maintain the reference)
      return {
        gestures: {
          ...state.gestures,
          [gestureType]: gestureInterpreter,
        },
      };
    }),

  /**
   * Cancel a gesture detection
   * @param gestureType - Type of gesture to cancel
   *
   * This function cancels the gesture detection for the specified gesture type by sending a CANCEL event to the interpreter.
   * It also cancels any active subscription to the detection store.
   */
  cancelGesture: (gestureType: TEYEGESTURE_TYPE) =>
    set((state: TGestureTrackingStore) => {
      // Get the interpreter for the gesture type
      const gestureInterpreter = state.gestures[gestureType];
      // If it exists, send the CANCEL event
      if (gestureInterpreter) {
        gestureInterpreter.send({
          type: GestureMachineEvent.CANCEL,
        } as TGestureMachineEvent);
      }

      // Cancelar la suscripción si existe
      const unsubscribeFn = state.subscriptions[gestureType];
      if (unsubscribeFn && typeof unsubscribeFn === 'function') {
        unsubscribeFn();
      }

      // Update the store (even if there are no actual changes, to maintain the reference)
      return {
        gestures: {
          ...state.gestures,
          [gestureType]: gestureInterpreter,
        },
        subscriptions: {
          ...state.subscriptions,
          [gestureType]: undefined,
        },
      };
    }),
}));
