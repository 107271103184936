import { useGestureTrackingStore, TGestureTrackingStore, TEYEGESTURE_TYPE, TGestureSettings, TGESTURE_SETTING_KEYS, getGestureSettingsFromState, getGestureSettingFromState } from '@/Models/Gesture';

/**
 * Hook para obtener la configuración actual de un gesto
 */
export const useGestureSettings = (gestureType: TEYEGESTURE_TYPE): TGestureSettings => {
  return useGestureTrackingStore((state: TGestureTrackingStore) => getGestureSettingsFromState(state, gestureType));
};

/**
 * Hook para obtener un atributo específico de la configuración de un gesto
 */
export const useGestureSetting = (gestureType: TEYEGESTURE_TYPE, settingKey: TGESTURE_SETTING_KEYS): TGestureSettings[TGESTURE_SETTING_KEYS] => {
  return useGestureTrackingStore((state: TGestureTrackingStore) => getGestureSettingFromState(state, gestureType, settingKey));
};
