import styled from 'styled-components';
import { device } from '@/Layout/breakpoints';
import { SPACING, GRID, LAYOUT, COLORS, BORDERS, ANIMATIONS, KeyboardLayout } from './Keyboard.constants';

export const KeyboardContainer = styled.div`
  width: ${LAYOUT.WIDTH.FULL};
  height: ${LAYOUT.HEIGHT.FULL};
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all ${ANIMATIONS.DURATION.NORMAL} ${ANIMATIONS.TIMING.EASE};

  /* ${device.mobilePortrait} {
    outline: ${BORDERS.WIDTH.MEDIUM} ${BORDERS.STYLE.DOTTED} ${COLORS.DEBUG.OUTLINE.PINK};
  }

  ${device.mobileLandscape} {
    outline: ${BORDERS.WIDTH.MEDIUM} ${BORDERS.STYLE.DOUBLE} ${COLORS.DEBUG.OUTLINE.BLUE};
  }

  ${device.tabletPortrait} {
    outline: ${BORDERS.WIDTH.MEDIUM} ${BORDERS.STYLE.DOTTED} ${COLORS.DEBUG.OUTLINE.GREEN};
  }

  ${device.tabletLandscape} {
    outline: ${BORDERS.WIDTH.MEDIUM} ${BORDERS.STYLE.INSET} ${COLORS.DEBUG.OUTLINE.GOLD};
  } */
`;

interface KeyboardSectionsProps {
  $layout: KeyboardLayout;
}

export const KeyboardSections = styled.div<KeyboardSectionsProps>`
  width: ${LAYOUT.WIDTH.FULL};
  height: ${LAYOUT.HEIGHT.FULL};
  display: grid;
  position: relative;
  flex: 1;

  /* outline: ${props =>
    props.$layout === 'vertical' ? `${BORDERS.WIDTH.MEDIUM} ${BORDERS.STYLE.DOTTED} ${COLORS.DEBUG.OUTLINE.PINK}` : `${BORDERS.WIDTH.MEDIUM} ${BORDERS.STYLE.DOTTED} ${COLORS.DEBUG.OUTLINE.BLUE}`}; */
  /* Default grid layout */
  grid-template-columns: ${props => GRID.TEMPLATE.COLUMNS[props.$layout.toUpperCase() as keyof typeof GRID.TEMPLATE.COLUMNS]};
  grid-template-rows: ${props => (props.$layout === 'vertical' ? GRID.TEMPLATE.ROWS.VERTICAL.DEFAULT : GRID.TEMPLATE.ROWS.HORIZONTAL.DEFAULT)};
  gap: ${props => (props.$layout === 'vertical' ? SPACING.NONE : SPACING.XS)};
  padding: ${SPACING.XXS};

  ${device.mobilePortrait} {
    gap: ${props => (props.$layout === 'vertical' ? SPACING.NONE : SPACING.XS)};
    padding: ${props => (props.$layout === 'vertical' ? SPACING.NONE : SPACING.XXS)};
    height: ${LAYOUT.HEIGHT.FULL};
    width: ${LAYOUT.WIDTH.FULL};
    max-height: ${LAYOUT.HEIGHT.FILL};
    grid-template-columns: ${props => GRID.TEMPLATE.COLUMNS[props.$layout.toUpperCase() as keyof typeof GRID.TEMPLATE.COLUMNS]};
    grid-template-rows: ${props => (props.$layout === 'vertical' ? GRID.TEMPLATE.ROWS.VERTICAL.DEFAULT : GRID.TEMPLATE.ROWS.HORIZONTAL.DEFAULT)};
  }

  ${device.mobileLandscape} {
    padding: ${SPACING.XXS};
    gap: ${SPACING.XS};
    grid-template-columns: ${props => GRID.TEMPLATE.COLUMNS[props.$layout.toUpperCase() as keyof typeof GRID.TEMPLATE.COLUMNS]};
    grid-template-rows: ${props => (props.$layout === 'vertical' ? GRID.TEMPLATE.ROWS.VERTICAL.TABLET : GRID.TEMPLATE.ROWS.HORIZONTAL.TABLET)};
  }

  ${device.tabletPortrait} {
    grid-template-columns: ${props => GRID.TEMPLATE.COLUMNS[props.$layout.toUpperCase() as keyof typeof GRID.TEMPLATE.COLUMNS]};
    grid-template-rows: ${props => (props.$layout === 'vertical' ? GRID.TEMPLATE.ROWS.VERTICAL.TABLET : GRID.TEMPLATE.ROWS.HORIZONTAL.TABLET)};
    gap: ${props => (props.$layout === 'vertical' ? SPACING.NONE : SPACING.SM)};
  }

  ${device.tabletLandscape} {
    height: ${LAYOUT.HEIGHT.FULL};
    width: ${LAYOUT.WIDTH.FULL};
    grid-template-columns: ${props => GRID.TEMPLATE.COLUMNS[props.$layout.toUpperCase() as keyof typeof GRID.TEMPLATE.COLUMNS]};
    grid-template-rows: ${props => (props.$layout === 'vertical' ? GRID.TEMPLATE.ROWS.VERTICAL.TABLET : GRID.TEMPLATE.ROWS.HORIZONTAL.TABLET)};
    gap: ${props => (props.$layout === 'vertical' ? SPACING.XS : SPACING.SM)};
  }

  ${device.desktopPortrait} {
    grid-template-columns: ${props => GRID.TEMPLATE.COLUMNS[props.$layout.toUpperCase() as keyof typeof GRID.TEMPLATE.COLUMNS]};
    grid-template-rows: ${props => (props.$layout === 'vertical' ? GRID.TEMPLATE.ROWS.VERTICAL.DESKTOP : GRID.TEMPLATE.ROWS.HORIZONTAL.DEFAULT)};
    gap: ${SPACING.MD};
  }

  ${device.desktopLandscape} {
    grid-template-columns: ${props => GRID.TEMPLATE.COLUMNS[props.$layout.toUpperCase() as keyof typeof GRID.TEMPLATE.COLUMNS]};
    grid-template-rows: ${props => (props.$layout === 'vertical' ? GRID.TEMPLATE.ROWS.VERTICAL.DESKTOP : GRID.TEMPLATE.ROWS.HORIZONTAL.DEFAULT)};
    gap: ${SPACING.XL};
  }
`;

export interface KeyboardSectionProps {
  $isActive: boolean;
  $isIdle: boolean;
}

export const KeyboardSection = styled.div<
  KeyboardSectionProps & {
    $isLeft?: boolean;
    $isTop?: boolean;
    $isBottom?: boolean;
    $isActive?: boolean;
    $isIdle?: boolean;
  }
>`
  position: relative;
  border-radius: 8px;
  background-color: ${({ $isActive, $isIdle }) => ($isActive ? COLORS.BACKGROUND.ACCENT.BLUE : $isIdle ? COLORS.BACKGROUND.OVERLAY : 'transparent')};
  transition: all ${ANIMATIONS.DURATION.NORMAL} ${ANIMATIONS.TIMING.EASE};
  grid-row: ${({ $isTop, $isBottom }) => {
    if ($isTop) return '1';
    if ($isBottom) return '3';
    return 'auto';
  }};
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  /* overflow: hidden; */
  /* Mobile Portrait */
  ${device.mobilePortrait} {
    // padding: 4px;
    max-height: 100%;
  }

  /* Mobile Landscape */
  ${device.mobileLandscape} {
    // padding: 6px;
  }

  /* Tablet Portrait & Landscape */
  ${device.tabletPortrait}, ${device.tabletLandscape} {
    // padding: 8px;
    gap: 0.5em;
  }

  /* Desktop */
  ${device.desktopLandscape} {
    // padding: 12px;
    gap: 1em;
  }
`;

export const SectionSeparator = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-row: 2;
  padding: 0.5em;

  /* Mobile Portrait */
  ${device.mobilePortrait} {
    /* outline: 2px inset #ff1493; */
    padding: 0.25em;
  }

  /* Mobile Landscape */
  ${device.mobileLandscape} {
    outline: 2px inset #4169e1;
  }

  /* Tablet Portrait */
  ${device.tabletPortrait} {
    /* outline: 2px inset #32cd32; */
  }

  /* Tablet Landscape */
  ${device.tabletLandscape} {
    /* background-color: #ff1493; */
    /* outline: 2px inset #ffd700; */
    /* gap: 1em; */
  }
`;

export const KeyboardKeys = styled.div<{ $isLeft?: boolean; $layout?: KeyboardLayout }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ $isLeft }) => ($isLeft ? 'flex-end' : 'flex-start')};
  align-items: flex-start;
  /* row-gap: 0px; */
  /* column-gap: 1px; */
  width: 100%;
  height: auto;
  min-height: 0;
  align-content: center;
  /* overflow-y: auto; */
  /* padding: 1px; */
  /* outline: 1px solid red; */

  /* Mobile Portrait */
  ${device.mobilePortrait} {
    row-gap: 5px;
    column-gap: 0.5em;
    align-items: center;
    align-content: center;
    justify-content: ${({ $isLeft }) => ($isLeft ? 'flex-end' : 'flex-start')};
  }

  /* Mobile Landscape */
  ${device.mobileLandscape} {
    row-gap: 0px;
    column-gap: 1px;
    /* padding: 1px; */
    justify-content: ${({ $isLeft }) => ($isLeft ? 'flex-end' : 'flex-start')};
  }

  /* Tablet Portrait */
  ${device.tabletPortrait} {
    row-gap: 1px;
    column-gap: 2px;
    /* padding: 2px; */
    justify-content: ${({ $isLeft }) => ($isLeft ? 'flex-end' : 'flex-start')};
  }

  /* Tablet Landscape */
  ${device.tabletLandscape} {
    flex-grow: 1;
    row-gap: ${SPACING.XS};
    column-gap: ${SPACING.XS};
    /* padding: 2px; */
    justify-content: center;
  }

  /* Desktop */
  ${device.desktopLandscape} {
    row-gap: 1em;
    column-gap: 0.5em;
    padding: 2px;
    align-content: center;
    justify-content: ${({ $isLeft }) => ($isLeft ? 'flex-end' : 'flex-start')};
  }
`;
