import { device } from '@/Layout/breakpoints';
import styled from 'styled-components';

export const LogoWrapper = styled.div`
  background-color: var(--dark-text-color);
  border-radius: 100%;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
  padding: 1.5em;
  position: relative;
  width: auto;

  aspect-ratio: 1;

  ${device.mobilePortrait} {
    padding: 0.9em;
    /* padding: 1em; */
    /* height: 25vh; */
  }

  ${device.mobileLandscape} {
    /* padding: 1.5em; */
    /* padding: 1em; */
    /* height: 25vh; */
  }
`;

export const LogoImage = styled.img`
  width: 200px;
  height: 200px;
  aspect-ratio: 1;
  position: relative;
  top: -0.4em;
  ${device.mobilePortrait} {
    width: 25vw;
    height: auto;
  }

  ${device.mobileLandscape} {
    /* outline: none; */
    /* outline: 1px solid red; */
    /* height: 45vh; */
    /* height: 40vh; */
    width: 13vw;
    height: auto;
  }
`;
