// hooks/useGestureScores.ts
import { useMemo } from 'react';
import { useDetectionStore } from '@/Models/Detection';
import { TEYEGESTURE_TYPE } from '@/Models/Gesture';

export const useDetectionGetGestureScore = (gesture: TEYEGESTURE_TYPE) => {
  const { gestures } = useDetectionStore();

  // Memoize scores and normalizedScores to avoid unnecessary re-renders
  const { score, normalizedScore } = useMemo(() => {
    return {
      score: gestures.scores[gesture],
      normalizedScore: gestures.normalizedScores[gesture],
    };
  }, [gestures.scores, gestures.normalizedScores, gesture]);

  return { score, normalizedScore };
};
