export const GestureMachineEvent = {
  CONFIG: 'CONFIG',
  UPDATE_SCORE: 'UPDATE_SCORE',
  CANCEL: 'CANCEL',
} as const;

export type TGestureMachineEvent =
  | { type: typeof GestureMachineEvent.CONFIG; ignoreThreshold: number; confirmThreshold: number; confirmTime: number; cooldownTime: number }
  | { type: typeof GestureMachineEvent.UPDATE_SCORE; score: number | undefined | null }
  | { type: typeof GestureMachineEvent.CANCEL };
