import { createMachine, assign, MachineConfig } from 'xstate';
import { TEYEGESTURE_TYPE } from '../types/base/gestureEnums';
import { TGestureSettings } from '../types/base/gestureTypes';
import { GESTUREMACHINE_STATE, IGestureMachineContext, IGestureStateSchema } from '../types/stateMachine/gestureStates';
import { GestureMachineEvent, TGestureMachineEvent } from '../types/stateMachine/gestureEvents';
import useGestureKeyboardStore from '@/Models/Keyboard/store/useGestureKeyboardStore';

/**
 * Creates a state machine for gesture tracking
 * @param gestureType - Type of gesture to detect
 * @param defaultConfig - Default configuration for the gesture
 * @returns State machine configured for the specified gesture type
 */

export const createGestureTrackingMachine = (gestureType: TEYEGESTURE_TYPE, defaultConfig: TGestureSettings) => {
  // Definir la configuración de la máquina
  const machineConfig: MachineConfig<IGestureMachineContext, IGestureStateSchema, TGestureMachineEvent> = {
    predictableActionArguments: true,
    id: `gesture-${gestureType}`,
    initial: GESTUREMACHINE_STATE.IDLE,
    context: {
      initial: { ...defaultConfig },
      settings: { ...defaultConfig },
      confirmationStartTime: 0,
      cooldownStartTime: 0,
    },
    on: {
      [GestureMachineEvent.CANCEL]: {
        target: `.${GESTUREMACHINE_STATE.IDLE}`,
        actions: assign(context => ({
          ...context,
          settings: { ...context.settings },
          confirmationStartTime: 0,
          cooldownStartTime: 0,
        })),
      },
      [GestureMachineEvent.CONFIG]: {
        actions: assign((context: IGestureMachineContext, event: TGestureMachineEvent) => {
          if (!event || event.type !== GestureMachineEvent.CONFIG) return context;

          // Validación de ignoreThreshold
          const isValidIgnore = event.ignoreThreshold !== undefined && !isNaN(event.ignoreThreshold) && event.ignoreThreshold >= 0 && event.ignoreThreshold <= 1;

          // Validación de confirmThreshold
          const isValidConfirm = event.confirmThreshold !== undefined && !isNaN(event.confirmThreshold) && event.confirmThreshold >= 0 && event.confirmThreshold <= 1;

          // Validación de confirmTime
          const isValidConfirmTime = event.confirmTime !== undefined && !isNaN(event.confirmTime) && event.confirmTime >= 100;

          // Validación de cooldownTime
          const isValidCooldownTime = event.cooldownTime !== undefined && !isNaN(event.cooldownTime) && event.cooldownTime >= 100;

          // Obtener valores, usando los existentes si los nuevos no son válidos
          let ignoreThreshold = isValidIgnore ? event.ignoreThreshold : context.settings.ignoreThreshold;
          let confirmThreshold = isValidConfirm ? event.confirmThreshold : context.settings.confirmThreshold;
          const confirmTime = isValidConfirmTime ? event.confirmTime : context.settings.confirmTime;
          const cooldownTime = isValidCooldownTime ? event.cooldownTime : context.settings.cooldownTime;

          // Asegurar que ignoreThreshold sea menor que confirmThreshold
          if (ignoreThreshold >= confirmThreshold) {
            // Si la relación es inválida, mantener los valores originales
            ignoreThreshold = context.settings.ignoreThreshold;
            confirmThreshold = context.settings.confirmThreshold;
          }

          return {
            ...context,
            settings: {
              ...context.settings,
              ignoreThreshold,
              confirmThreshold,
              confirmTime,
              cooldownTime,
            },
          };
        }),
      },
    },
    states: {
      [GESTUREMACHINE_STATE.IDLE]: {
        on: {
          [GestureMachineEvent.UPDATE_SCORE]: [
            {
              target: GESTUREMACHINE_STATE.CONFIRMING,
              cond: (context: IGestureMachineContext, event: TGestureMachineEvent) => {
                return event.type === GestureMachineEvent.UPDATE_SCORE && event.score !== undefined && event.score !== null && event.score > context.settings.confirmThreshold;
              },
            },
            {
              target: GESTUREMACHINE_STATE.ACTIVE,
              cond: (context: IGestureMachineContext, event: TGestureMachineEvent) => {
                return event.type === GestureMachineEvent.UPDATE_SCORE && event.score !== undefined && event.score !== null && event.score > context.settings.ignoreThreshold;
              },
            },
          ],
        },
      },
      [GESTUREMACHINE_STATE.ACTIVE]: {
        on: {
          [GestureMachineEvent.UPDATE_SCORE]: [
            {
              target: GESTUREMACHINE_STATE.CONFIRMING,
              cond: (context: IGestureMachineContext, event: TGestureMachineEvent) => {
                return event.type === GestureMachineEvent.UPDATE_SCORE && event.score !== undefined && event.score !== null && event.score > context.settings.confirmThreshold;
              },
            },
            {
              target: GESTUREMACHINE_STATE.IDLE,
              cond: (context: IGestureMachineContext, event: TGestureMachineEvent) => {
                return event.type === GestureMachineEvent.UPDATE_SCORE && (event.score === undefined || event.score === null || event.score < context.settings.ignoreThreshold);
              },
            },
          ],
        },
      },
      [GESTUREMACHINE_STATE.CONFIRMING]: {
        entry: assign({
          confirmationStartTime: () => new Date().getTime(),
        }),
        after: {
          // Usar el tiempo de confirmación dinámico desde la configuración
          CONFIRMATION_TIMER: {
            target: GESTUREMACHINE_STATE.COMPLETED,
            actions: (context: IGestureMachineContext) => {
              const { setKeyboardGesture } = useGestureKeyboardStore.getState();
              setKeyboardGesture(gestureType); // Call the method with the gesture type
            },
          },
        },
        on: {
          [GestureMachineEvent.UPDATE_SCORE]: [
            {
              target: GESTUREMACHINE_STATE.IDLE,
              actions: assign({ confirmationStartTime: 0 }), // Reset timer state when going back to IDLE
              cond: (context: IGestureMachineContext, event: TGestureMachineEvent) => {
                return event.type === GestureMachineEvent.UPDATE_SCORE && (event.score === undefined || event.score === null || event.score < context.settings.ignoreThreshold);
              },
            },
            {
              target: GESTUREMACHINE_STATE.ACTIVE,
              actions: assign({ confirmationStartTime: 0 }), // Reset timer state to 0 instead of undefined
              cond: (context: IGestureMachineContext, event: TGestureMachineEvent) => {
                return event.type === GestureMachineEvent.UPDATE_SCORE && event.score !== undefined && event.score !== null && event.score < context.settings.confirmThreshold;
              },
            },
          ],
        },
      },
      [GESTUREMACHINE_STATE.COMPLETED]: {
        entry: assign({
          cooldownStartTime: () => new Date().getTime(),
        }),
        after: {
          // Usar el tiempo de enfriamiento dinámico desde la configuración
          COOLDOWN_TIMER: {
            target: GESTUREMACHINE_STATE.IDLE,
          },
        },
        on: {
          [GestureMachineEvent.CANCEL]: {
            target: GESTUREMACHINE_STATE.IDLE,
            actions: assign(context => ({
              ...context,
              settings: { ...context.settings },
              confirmationStartTime: 0,
              cooldownStartTime: 0,
            })),
          },
        },
        exit: assign({
          confirmationStartTime: 0,
          cooldownStartTime: 0,
        }),
      },
    },
  };

  // Crear la máquina con opciones adicionales para los delays
  return createMachine<IGestureMachineContext, TGestureMachineEvent>(machineConfig, {
    delays: {
      CONFIRMATION_TIMER: (context: IGestureMachineContext) => context.settings.confirmTime,
      COOLDOWN_TIMER: (context: IGestureMachineContext) => context.settings.cooldownTime,
    },
  });
};

// Exportar tanto como default como nombrado para mantener compatibilidad
export default createGestureTrackingMachine;
