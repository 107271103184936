export enum LANDMARK_SCORE_KEY {
  EYE_BLINK_LEFT = 'eyeBlinkLeft',
  EYE_BLINK_RIGHT = 'eyeBlinkRight',
  EYE_LOOK_DOWN_LEFT = 'eyeLookDownLeft',
  EYE_LOOK_DOWN_RIGHT = 'eyeLookDownRight',
  EYE_LOOK_IN_LEFT = 'eyeLookInLeft',
  EYE_LOOK_IN_RIGHT = 'eyeLookInRight',
  EYE_LOOK_OUT_LEFT = 'eyeLookOutLeft',
  EYE_LOOK_OUT_RIGHT = 'eyeLookOutRight',
  EYE_LOOK_UP_LEFT = 'eyeLookUpLeft',
  EYE_LOOK_UP_RIGHT = 'eyeLookUpRight',
}

export type TLandMarkScoreKey = (typeof LANDMARK_SCORE_KEY)[keyof typeof LANDMARK_SCORE_KEY];
