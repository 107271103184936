import { defaultTheme } from './defaultTheme';
import { EYEGESTURE_TYPE, TEYEGESTURE_TYPE } from '@/Models/Gesture';

// Constantes específicas del ConfigModal
const _modalConstants = {
  maxWidth: {
    default: '1200px',
    desktop: '1800px',
  },
  detectionColumn: {
    width: {
      default: '30%',
      desktop: '240px',
    },
  },
} as const;

type GestureColors = {
  [key in TEYEGESTURE_TYPE]: string;
};

import { Theme } from './defaultTheme';

export interface ConfigModalTheme extends Omit<Theme, 'spacing' | 'colors' | 'borderRadius' | 'shadows'> {
  spacing: Theme['spacing'] & {
    section: string;
    control: string;
    groupPadding: string;
    modal: {
      default: string;
      mobile: string;
      desktop: string;
    };
  };
  colors: Theme['colors'] & {
    control: {
      border: {
        default: string;
        hover: string;
      };
      background: {
        default: string;
        hover: string;
      };
    };
    gesture: GestureColors;
    modal: {
      background: string;
      header: string;
      overlay: string;
      danger: {
        hover: string;
        active: string;
      };
    };
  };
  sizes: {
    control: {
      minHeight: string;
      desktop: {
        minHeight: string;
      };
    };
    slider: {
      track: {
        height: string;
        borderRadius: string;
      };
      thumb: {
        size: string;
        borderRadius: string;
      };
    };
  };
  grid: {
    columns: {
      default: string;
      desktop: string;
    };
    gap: {
      default: string;
      desktop: string;
    };
  };
  borderRadius: Theme['borderRadius'];
  shadows: Theme['shadows'] & {
    hover: string;
  };
  layout: {
    modal: {
      default: string;
      desktop: string;
    };
    detection: {
      default: string;
      desktop: string;
    };
  };
}

export const configModalTheme: ConfigModalTheme = {
  ...defaultTheme,
  spacing: {
    ...defaultTheme.spacing,
    section: '1.5em',
    control: '1em',
    groupPadding: '2em',
    modal: {
      default: '1.5em',
      mobile: '1em',
      desktop: '2em',
    },
  },
  colors: {
    ...defaultTheme.colors,
    modal: {
      background: '#1a1a1a',
      header: '#1a1a1aa6',
      overlay: 'rgba(0, 0, 0, 0.7)',
      danger: {
        hover: '#ff000066',
        active: '#ff000088',
      },
    },
    control: {
      border: {
        default: 'rgba(0, 123, 255, 0.2)',
        hover: 'rgba(0, 123, 255, 0.4)',
      },
      background: {
        default: 'rgba(0, 123, 255, 0.03)',
        hover: 'rgba(0, 123, 255, 0.05)',
      },
    },
    gesture: {
      [EYEGESTURE_TYPE.LOOK_LEFT]: '#FF6B6B',
      [EYEGESTURE_TYPE.LOOK_RIGHT]: '#4ECDC4',
      [EYEGESTURE_TYPE.LOOK_UP]: '#45B7D1',
      [EYEGESTURE_TYPE.LOOK_DOWN]: '#96CEB4',
      [EYEGESTURE_TYPE.BLINK]: '#9B59B6',
    },
  },
  sizes: {
    control: {
      minHeight: '3.75em',
      desktop: {
        minHeight: '5em',
      },
    },
    slider: {
      track: {
        height: '0.5em',
        borderRadius: '0.25em',
      },
      thumb: {
        size: '1.5em',
        borderRadius: '0.75em',
      },
    },
  },
  grid: {
    columns: {
      default: '35% 65%',
      desktop: '30% 70%',
    },
    gap: {
      default: '1em',
      desktop: '1.5em',
    },
  },
  borderRadius: defaultTheme.borderRadius,
  shadows: {
    ...defaultTheme.shadows,
    hover: '0 0.25em 0.75em rgba(0, 0, 0, 0.1)',
  },
  transitions: defaultTheme.transitions,
  layout: {
    modal: _modalConstants.maxWidth,
    detection: _modalConstants.detectionColumn.width,
  },
};
