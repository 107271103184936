import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Container, Title } from '@/Components/ConfigModal/ConfigModal.styles';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { device } from '@/Layout/breakpoints';

interface ThemeControlProps {
  id?: string;
}

const PreviewContainer = styled.div`
  background: #2a2a2a;
  border: 1px solid #444;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.2s ease;

  ${device.mobilePortrait} {
    height: 100px;
    padding: 16px;
    margin: 16px 0;
  }
`;

const PreviewKey = styled.div<{ $isDark: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  font-size: 1.5em;
  font-weight: bold;
  transition: all 0.2s ease;
  background-color: ${props => (props.$isDark ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.95)')};
  color: ${props => (props.$isDark ? 'rgb(227, 227, 227)' : 'rgb(33, 33, 33)')};
  border: 1px solid ${props => (props.$isDark ? '#ffffff30' : '#00000030')};
  box-shadow: ${props => (props.$isDark ? '1px 2px 3px rgba(0, 0, 0, 0.2)' : '1px 2px 3px rgba(0, 0, 0, 0.1)')};
  text-shadow: ${props => (props.$isDark ? '1px 1px 2px rgb(0, 0, 0)' : '1px 1px 2px rgba(255, 255, 255, 0.5)')};

  ${device.mobilePortrait} {
    width: 60px;
    height: 60px;
    font-size: 1.2em;
  }
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 10px;
`;

const ThemeButton = styled.button<{ $active: boolean }>`
  background: ${props => (props.$active ? '#007bff' : '#2a2a2a')};
  color: ${props => (props.$active ? '#fff' : 'rgba(255, 255, 255, 0.7)')};
  border: 1px solid ${props => (props.$active ? '#0056b3' : '#444')};
  padding: 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9em;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${props => (props.$active ? '#0056b3' : '#333')};
    color: white;
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }

  ${device.mobilePortrait} {
    padding: 10px;
    font-size: 0.85em;
  }
`;

const ThemeControl: React.FC<ThemeControlProps> = ({ id }) => {
  const { isDarkTheme, setKeyboardTheme } = useUserSessionStore(state => ({
    isDarkTheme: state.user.settings.selectedKeyboardSettings.isDarkTheme,
    setKeyboardTheme: state.setKeyboardTheme,
  }));

  const handleThemeChange = useCallback(
    (isDark: boolean) => {
      setKeyboardTheme(isDark);
    },
    [setKeyboardTheme]
  );

  return (
    <Container id={id}>
      <Title>Tema del Teclado</Title>
      <PreviewContainer>
        <PreviewKey $isDark={isDarkTheme}>A</PreviewKey>
      </PreviewContainer>
      <ButtonGrid>
        <ThemeButton $active={isDarkTheme} onClick={() => handleThemeChange(true)}>
          Oscuro
        </ThemeButton>
        <ThemeButton $active={!isDarkTheme} onClick={() => handleThemeChange(false)}>
          Claro
        </ThemeButton>
      </ButtonGrid>
    </Container>
  );
};

export default React.memo(ThemeControl);
