export const EYEGESTURE_TYPE = {
  LOOK_LEFT: 'look_left',
  LOOK_RIGHT: 'look_right',
  LOOK_UP: 'look_up',
  LOOK_DOWN: 'look_down',
  BLINK: 'blink',
} as const;

export type TEYEGESTURE_TYPE = (typeof EYEGESTURE_TYPE)[keyof typeof EYEGESTURE_TYPE];

export type TEYEGESTURE_TYPE_KEY = keyof typeof EYEGESTURE_TYPE;

export const EyeGestureSource = {
  LEFT_EYE: 'leftEye',
  RIGHT_EYE: 'rightEye',
} as const;

export type TEyeGestureSource = (typeof EyeGestureSource)[keyof typeof EyeGestureSource];
