import React, { useState, useEffect } from 'react';
import enterFullScreen from '@/Assets/icons/enterFullScreen.svg';
import exitFullScreen from '@/Assets/icons/exitFullScreen.svg';
import { ButtonIcon } from '@/Components/Shared/ButtonIcon';
import { BaseButton } from '@/Sections/Home/Shared/LoginButtonStyles';

const FullScreenButton: React.FC = () => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const toggleFullScreen = async () => {
    try {
      if (!document.fullscreenElement) {
        await document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          await document.exitFullscreen();
        }
      }
    } catch (error) {
      console.error('Error toggling fullscreen:', error);
    }
  };

  return (
    <BaseButton onClick={toggleFullScreen}>
      <ButtonIcon src={isFullScreen ? exitFullScreen : enterFullScreen} alt={isFullScreen ? 'exit fullscreen icon' : 'enter fullscreen icon'} />

      <span>{isFullScreen ? 'Salir Pantalla Completa' : 'Pantalla Completa'}</span>
    </BaseButton>
  );
};

export default FullScreenButton;
