import { useCallback } from 'react';
import { useGestureTrackingStore, TGestureTrackingStore, TEYEGESTURE_TYPE, TGestureSettings, getGestureSettingsFromState, TGESTURE_SETTING_KEYS } from '@/Models/Gesture';

/**
 * Hook para actualizar la configuración de un gesto
 * @returns Función para actualizar la configuración de un gesto
 */
export const useGestureSettingsUpdate = () => {
  const { configureGesture } = useGestureTrackingStore(
    useCallback(
      (state: TGestureTrackingStore) => ({
        configureGesture: state.configureGesture,
      }),
      []
    )
  );

  /**
   * Actualiza la configuración de un gesto específico
   * @param gestureType - Tipo de gesto a actualizar
   * @param settingsUpdate - Actualización de la configuración
   */
  const updateGestureSettings = useCallback(
    (gestureType: TEYEGESTURE_TYPE, settingsUpdate: TGestureSettings) => {
      try {
        // Obtener la configuración actual
        const currentSettings = getGestureSettingsFromState(useGestureTrackingStore.getState(), gestureType);

        // Combinar la configuración actual con las actualizaciones
        const newSettings: TGestureSettings = {
          ...currentSettings,
          ...settingsUpdate,
        };

        console.log(`[INFO] Actualizando configuración para ${gestureType}:`, newSettings);
        // Aplicar la nueva configuración
        configureGesture(gestureType, newSettings);

        return true;
      } catch (error) {
        console.error(`[ERROR] No se pudo actualizar la configuración para ${gestureType}:`, error);
        return false;
      }
    },
    [configureGesture]
  );

  /**
   * Actualiza una propiedad específica de la configuración de un gesto
   * @param gestureType - Tipo de gesto a actualizar
   * @param settingKey - Clave de la propiedad a actualizar
   * @param value - Nuevo valor para la propiedad
   */
  const updateGestureSetting = useCallback(
    (gestureType: TEYEGESTURE_TYPE, settingKey: TGESTURE_SETTING_KEYS, value: TGestureSettings[TGESTURE_SETTING_KEYS]) => {
      try {
        // Obtener la configuración actual
        const currentSettings = getGestureSettingsFromState(useGestureTrackingStore.getState(), gestureType);

        // Actualizar solo la propiedad especificada
        const newSettings: TGestureSettings = {
          ...currentSettings,
          [settingKey]: value,
        };

        // Aplicar la nueva configuración
        configureGesture(gestureType, newSettings);

        return true;
      } catch (error) {
        console.error(`[ERROR] No se pudo actualizar la propiedad ${settingKey} para ${gestureType}:`, error);
        return false;
      }
    },
    [configureGesture]
  );

  return { updateGestureSettings, updateGestureSetting };
};
