import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Container, Title } from '@/Components/ConfigModal/ConfigModal.styles';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { FONT_SIZES, FontSize } from '@/Layout/typography';
import { device } from '@/Layout/breakpoints';

interface FontSizeControlProps {
  id?: string;
}

const PreviewContainer = styled.div`
  background: #2a2a2a;
  border: 1px solid #444;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.2s ease;

  ${device.mobilePortrait} {
    height: 100px;
    padding: 16px;
    margin: 16px 0;
  }
`;

const PreviewText = styled.div<{ $fontSize: FontSize }>`
  color: rgba(255, 255, 255, 0.9);
  font-size: ${props => FONT_SIZES[props.$fontSize]}px;
  text-align: center;
  transition: font-size 0.2s ease;
`;

const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 10px;
  margin-top: 10px;
`;

const SizeButton = styled.button<{ $active: boolean }>`
  background: ${props => (props.$active ? '#007bff' : '#2a2a2a')};
  color: ${props => (props.$active ? '#fff' : 'rgba(255, 255, 255, 0.7)')};
  border: 1px solid ${props => (props.$active ? '#0056b3' : '#444')};
  padding: 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9em;
  min-height: 44px;

  &:hover {
    background: ${props => (props.$active ? '#0056b3' : '#333')};
    color: white;
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }

  ${device.mobilePortrait} {
    padding: 10px;
    font-size: 0.85em;
  }
`;

const FONT_SIZE_OPTIONS = Object.keys(FONT_SIZES) as FontSize[];

const FontSizeControl: React.FC<FontSizeControlProps> = ({ id }) => {
  const { keyFontSize, setKeyFontSize } = useUserSessionStore(state => ({
    keyFontSize: state.user.settings.selectedKeyboardSettings.keyFontSize,
    setKeyFontSize: state.setKeyFontSize,
  }));

  const handleFontSizeChange = useCallback(
    (size: FontSize) => {
      setKeyFontSize(size);
    },
    [setKeyFontSize]
  );

  return (
    <Container id={id}>
      <Title>Tamaño de Fuente</Title>
      <PreviewContainer>
        <PreviewText $fontSize={keyFontSize}>Texto de Ejemplo</PreviewText>
      </PreviewContainer>
      <ControlContainer>
        <ButtonGrid>
          {FONT_SIZE_OPTIONS.map(size => (
            <SizeButton key={size} $active={keyFontSize === size} onClick={() => handleFontSizeChange(size)}>
              {size.toUpperCase()}
            </SizeButton>
          ))}
        </ButtonGrid>
      </ControlContainer>
    </Container>
  );
};

FontSizeControl.displayName = 'FontSizeControl';

export default React.memo(FontSizeControl);
