import { useEffect, useMemo, useRef } from 'react';
import { EYEGESTURE_TYPE, TGestureSettings, TEYEGESTURE_TYPE, useGestureTrackingStore } from '@/Models/Gesture';
import useDetectionStore from '@/Models/Detection/store/useDetectionStore';
import useConfigurationStore from '@/Models/UserSession/store/useConfigurationStore';

/**
 * Hook that initializes gesture trackers and subscribes to detection store updates
 * @returns null - This is a utility hook with no UI
 */
export const useGestureTrackerMachine = () => {
  // Access to gesture tracking store functions
  const { configureGesture, cancelGesture, updateScore } = useGestureTrackingStore();
  const { getAllStoredGestureSettings } = useConfigurationStore();

  // Get user settings for gestures
  // const selectedKeyboardSettings = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings);

  // Reference to track initialized gestures for cleanup and updates
  const initializedGesturesRef = useRef<TEYEGESTURE_TYPE[]>([]);

  // Memoize gesture settings to prevent unnecessary re-renders
  const gestureSettings = useMemo(() => {
    const temp = getAllStoredGestureSettings();
    console.log('DEBUG: gestureSettings', temp);
    return temp;
  }, [getAllStoredGestureSettings]);

  // Effect para inicializar gestos y suscribirse a cambios en los scores
  useEffect(() => {
    // Verificar si el detection store está disponible
    const detectionState = useDetectionStore.getState();

    // Inicializar gestos solo si hay configuración disponible
    if (gestureSettings) {
      console.log('DEBUG: Initializing gestures with settings');
      const initializedGestures: TEYEGESTURE_TYPE[] = [];

      // Configurar cada gesto disponible en la configuración
      Object.values(EYEGESTURE_TYPE).forEach(gestureType => {
        const settings = gestureSettings[gestureType];
        if (settings) {
          console.log('DEBUG: initializing gesture', gestureType);
          configureGesture(gestureType, settings as TGestureSettings);
          initializedGestures.push(gestureType);
        }
      });

      // Guardar referencia a los gestos inicializados
      initializedGesturesRef.current = initializedGestures;

      // Si hay scores normalizados disponibles, actualizar los scores iniciales
      if (detectionState.gestures && detectionState.gestures.normalizedScores) {
        const { normalizedScores } = detectionState.gestures;
        initializedGestures.forEach(gestureType => {
          const score = normalizedScores[gestureType];
          if (score !== undefined) {
            updateScore(gestureType, score);
          }
        });
      }

      // Suscribirse a cambios en los scores normalizados
      console.log('DEBUG: Setting up normalized scores subscription');
      const unsubscribe = useDetectionStore.subscribe(state => {
        // Solo procesar si hay scores normalizados
        if (state.gestures && state.gestures.normalizedScores) {
          const { normalizedScores } = state.gestures;

          // Actualizar scores para cada gesto inicializado
          initializedGesturesRef.current.forEach(gestureType => {
            const score = normalizedScores[gestureType];
            if (score !== undefined) {
              updateScore(gestureType, score);
            }
          });
        }
      });

      // Cleanup function
      return () => {
        // Cancelar la suscripción
        unsubscribe();

        // Cancelar todos los gestos inicializados
        initializedGestures.forEach(gestureType => {
          cancelGesture(gestureType);
        });
      };
    }
  }, [configureGesture, cancelGesture, updateScore, gestureSettings]);

  // No UI to render
  return null;
};
