import React from 'react';
import { KEYBOARD_TYPE } from '@/Models/Keyboard/types/types';
import styled from 'styled-components';

const IconWrapper = styled.div<{ $checked: boolean }>`
  width: 100px;
  height: 60px;
  margin-bottom: 10px;

  svg {
    width: 100%;
    height: 100%;

    rect,
    path {
      fill: ${({ $checked, theme }) => ($checked ? theme.colors.accent.blue : theme.colors.text.secondary)};
      stroke: ${({ theme }) => theme.colors.border.primary};
      stroke-width: 1;
      transition: all ${({ theme }) => theme.transitions.normal};
    }
  }

  &:hover svg rect,
  &:hover svg path {
    fill: ${({ $checked, theme }) => ($checked ? theme.colors.accent.blue : theme.colors.text.primary)};
  }
`;

// Teclado Vertical Split
const VerticalSplitIcon = ({ checked }: { checked: boolean }) => (
  <IconWrapper $checked={checked}>
    <svg viewBox="0 0 100 60" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="5" width="90" height="20" rx="4" />
      <rect x="5" y="35" width="90" height="20" rx="4" />
    </svg>
  </IconWrapper>
);

// Teclado Horizontal Split
const HorizontalSplitIcon = ({ checked }: { checked: boolean }) => (
  <IconWrapper $checked={checked}>
    <svg viewBox="0 0 100 60" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="5" width="40" height="50" rx="4" />
      <rect x="55" y="5" width="40" height="50" rx="4" />
    </svg>
  </IconWrapper>
);

// Teclado con Mouse/Tracking
const MouseTrackingIcon = ({ checked }: { checked: boolean }) => (
  <IconWrapper $checked={checked}>
    <svg viewBox="0 0 100 60" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="5" width="90" height="40" rx="4" />
      <path d="M45,50 L55,50 L50,55 Z" /> {/* Cursor del mouse */}
    </svg>
  </IconWrapper>
);

export const KeyboardIcons: Record<KEYBOARD_TYPE, React.FC<{ checked: boolean }>> = {
  [KEYBOARD_TYPE.VERTICAL_SPLIT_SELECTION]: VerticalSplitIcon,
  [KEYBOARD_TYPE.HORIZONTAL_SPLIT_SELECTION]: HorizontalSplitIcon,
  [KEYBOARD_TYPE.HORIZONTAL_TRACKING_MOUSE]: MouseTrackingIcon,
};
