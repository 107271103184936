/**
 * @file useRangeFiltering.tsx
 * @description Hook para filtrar y procesar landmarks faciales dentro de un rango específico
 */

// Importar referencias a tipos DOM explícitamente
/// <reference path="../../../types/dom.d.ts" />

import { useState, useEffect, useMemo, useCallback } from 'react';
import { BoundingBox, FaceLandmarker, NormalizedLandmark } from '@mediapipe/tasks-vision';
import { useDetectionStore } from '@/Models/Detection';

interface FaceBounds {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
}

/**
 * Hook para filtrar y procesar landmarks faciales dentro de un rango específico
 */
export const useDetectionRangeFiltering = () => {
  const config = useDetectionStore(state => state.config);
  const result = useDetectionStore(state => state.faceLandMark.result);
  const videoElement = useDetectionStore(state => state.webcam.videoElement);
  const setRangeFilter = useDetectionStore(state => state.setRangeFilter);

  const [boundingBox, setBoundingBox] = useState<BoundingBox | null>(null);

  // Memoizar los landmarks del óvalo facial
  const getFaceOvalLandmarks = useCallback((facelandmarks: NormalizedLandmark[]) => {
    return facelandmarks.filter((point, index) => FaceLandmarker.FACE_LANDMARKS_FACE_OVAL.some(connection => connection.start === index || connection.end === index));
  }, []);

  // Calcular límites faciales
  const calculateFaceBounds = useCallback((faceOvalLandmarks: NormalizedLandmark[]): FaceBounds => {
    return faceOvalLandmarks.reduce(
      (bounds, point) => ({
        minX: Math.min(bounds.minX, point.x),
        maxX: Math.max(bounds.maxX, point.x),
        minY: Math.min(bounds.minY, point.y),
        maxY: Math.max(bounds.maxY, point.y),
      }),
      {
        minX: Infinity,
        maxX: -Infinity,
        minY: Infinity,
        maxY: -Infinity,
      }
    );
  }, []);

  const updateFacelandmarks = useCallback(
    (facelandmarks: NormalizedLandmark[]) => {
      if (!facelandmarks?.length || !videoElement) {
        return;
      }

      try {
        const faceOvalLandmarks = getFaceOvalLandmarks(facelandmarks);
        const bounds = calculateFaceBounds(faceOvalLandmarks);

        const width = bounds.maxX - bounds.minX;
        const height = bounds.maxY - bounds.minY;

        const newBoundingBox: BoundingBox = {
          originX: bounds.minX * videoElement.videoWidth,
          originY: bounds.minY * videoElement.videoHeight,
          width: width * videoElement.videoWidth,
          height: height * videoElement.videoHeight,
          angle: 0,
        };

        const isWidthInRange =
          newBoundingBox.width >= config.rangeFiltering.faceWidthMin * videoElement.videoWidth && newBoundingBox.width <= config.rangeFiltering.faceWidthMax * videoElement.videoWidth;

        setBoundingBox(newBoundingBox);
        setRangeFilter(newBoundingBox, isWidthInRange);
      } catch (error) {
        console.error('Error al actualizar landmarks faciales:', error);
        setBoundingBox(null);
        setRangeFilter(undefined, false);
      }
    },
    [videoElement, config.rangeFiltering, getFaceOvalLandmarks, calculateFaceBounds, setRangeFilter]
  );

  useEffect(() => {
    if (result?.faceLandmarks && result.faceLandmarks[0]) {
      updateFacelandmarks(result.faceLandmarks[0]);
    }
  }, [result, updateFacelandmarks]);

  return { boundingBox, updateFacelandmarks };
};
