import React from 'react';
import { SelectContainer, StyledSelect, DisabledMessage } from './SpeakGestureSelector.style';
import { GestureLabels, TEYEGESTURE_TYPE } from '@/Models/Gesture';
import { Container, Title } from '@/Components/ConfigModal/ConfigModal.styles';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { KEYBOARD_AVAILABLE_SPEAK_GESTURES } from '@/Models/Keyboard/types';
import { KEYBOARD_TYPE } from '@/Models/Keyboard/types/types';

interface SpeakGestureSelectorProps {
  id?: string;
}

const SpeakGestureSelector: React.FC<SpeakGestureSelectorProps> = ({ id }) => {
  const {
    user: {
      settings: {
        selectedKeyboard,
        selectedKeyboardSettings: { keyboardSpeakGesture },
      },
    },
    // setSpeakGesture,
  } = useUserSessionStore();

  // const selectedKeyboard = useUserSessionStore(state => state.user.settings.selectedKeyboard);
  // const keyboardSpeakGesture = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.keyboardSpeakGesture);
  // const setSpeakGesture = useUserSessionStore(state => state.setSpeakGesture);

  const handleSpeakGestureChange = React.useCallback((gesture: TEYEGESTURE_TYPE) => {
    console.log(gesture);
    // setSpeakGesture(gesture);
  }, []);

  const availableGestures = KEYBOARD_AVAILABLE_SPEAK_GESTURES[selectedKeyboard as KEYBOARD_TYPE];

  const hasAvailableGestures = availableGestures ? availableGestures.length > 0 : false;
  React.useEffect(() => {
    if (hasAvailableGestures && !availableGestures.includes(keyboardSpeakGesture)) {
      handleSpeakGestureChange(availableGestures[0]);
    }
  }, [keyboardSpeakGesture, handleSpeakGestureChange, selectedKeyboard, availableGestures, hasAvailableGestures]);

  return (
    <Container id={id}>
      <Title>Gesto Seleccionado</Title>
      <SelectContainer>
        <StyledSelect value={keyboardSpeakGesture} onChange={e => handleSpeakGestureChange(e.target.value as TEYEGESTURE_TYPE)} disabled={!hasAvailableGestures}>
          {hasAvailableGestures ? (
            availableGestures.map((gesture: TEYEGESTURE_TYPE) => (
              <option key={gesture} value={gesture}>
                {GestureLabels[gesture]}
              </option>
            ))
          ) : (
            <option value="">No hay gestos disponibles</option>
          )}
        </StyledSelect>
        {!hasAvailableGestures && <DisabledMessage>Este tipo de teclado no tiene gestos de habla disponibles</DisabledMessage>}
      </SelectContainer>
    </Container>
  );
};

export default SpeakGestureSelector;
