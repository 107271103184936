import { TEYEGESTURE_TYPE } from '@/Models/Gesture';
import { IUserSettings } from '@/Models/UserSession/types/configuration';

/**
 * Enum de comandos disponibles.
 */
export enum CommandsTypes {
  Restart = 'Reiniciar',
  Space = 'Espacio',
  Enter = 'Enter',
  Delete = 'Borrar',
  DeleteAll = 'Borrar Todo',
}

/**
 * Enum de comandos disponibles.
 */
export enum COMMANDS {
  DELETE = 'BORRAR',
  DELETE_ALL = 'BORRAR TODO',
  SPACE = 'ESPACIO',
  SPEAK = 'PRONUNCIAR',
  RESET = 'REINICIAR',
}

/**
 * Lista de teclas disponibles.
 */
export const KEY_LIST = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  '.',
  ',',
];

/**
 * Lista de comandos disponibles.
 */

export const LEFT_COMMANDS = [COMMANDS.DELETE, COMMANDS.DELETE_ALL];
export const RIGHT_COMMANDS = [COMMANDS.SPACE, COMMANDS.RESET];

/**
 * Interfaz que representa un registro de gesto.
 */
export interface IGestureRecord {
  gesture: TEYEGESTURE_TYPE;
  timeStamp: string;
}

/**
 * Default font sizes for keyboard and phrase
 */
export const DEFAULT_FONT_SIZES = {
  KEY_FONT_SIZE: 'md',
  PHRASE_FONT_SIZE: 'md',
} as const;

export const THRESHOLD_SLIDER_STEP = 0.1;

export enum EyeGestures {
  EyeBlinkLeft = 'eyeBlinkLeft',
  EyeBlinkRight = 'eyeBlinkRight',
  EyeLookDownLeft = 'eyeLookDownLeft',
  EyeLookDownRight = 'eyeLookDownRight',
  EyeLookInLeft = 'eyeLookInLeft',
  EyeLookInRight = 'eyeLookInRight',
  EyeLookOutLeft = 'eyeLookOutLeft',
  EyeLookOutRight = 'eyeLookOutRight',
  EyeLookUpLeft = 'eyeLookUpLeft',
  EyeLookUpRight = 'eyeLookUpRight',
  EyeSquintLeft = 'eyeSquintLeft',
  EyeSquintRight = 'eyeSquintRight',

  Neutral = '_neutral',
}

/**
 * UI Control Settings
 */
export const UI_CONTROL = {
  DEBOUNCE: {
    DEFAULT_DELAY: 500,
    MIN_DIFF: 0.1,
  },
  GESTURE: {
    REACTION_TIME: {
      MIN: 0.1,
      MAX: 2.0,
      STEP: 0.1,
    },
    THRESHOLD: {
      MIN: 0.1,
      MAX: 1.0,
      STEP: 0.05,
    },
  },
} as const;

/**
 * Represents a user in the system with their authentication and personal information
 */

export interface IDebugSettings {
  traceGestureEvents: boolean;
}

export interface IUser {
  authToken: string;
  role: string;
  name: string;
  birthdate: string;
  id: string;
  settings: IUserSettings;
  email: string;
  debug: IDebugSettings;
}

/**
 * Type for font sizes used in the application
 */
export type TFontSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const defaultDebugSettings: IDebugSettings = {
  traceGestureEvents: true, // Habilitado por defecto
};

export enum AuthErrorType {
  TIMEOUT = 'TIMEOUT',
  NETWORK = 'NETWORK',
  UNAUTHORIZED = 'UNAUTHORIZED',
  SERVER = 'SERVER',
  UNKNOWN = 'UNKNOWN',
}

export interface AuthError {
  type: AuthErrorType;
  message: string;
}

export interface LoginResponse {
  token: string;
  user: IUser;
}
