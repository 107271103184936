import styled from 'styled-components';

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 10vw;
  padding: ${({ theme }) => theme.spacing.md};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const SliderLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1.5em;
  width: 100%;

  span:last-child {
    min-width: 2.5em;
    text-align: right;
  }
`;

export const SliderTrack = styled.div`
  position: absolute;
  width: 100%;
  height: ${({ theme }) => theme.sizes.slider.track.height};
  background: ${({ theme }) => theme.colors.control.background.default};
  border-radius: ${({ theme }) => theme.sizes.slider.track.borderRadius};
  outline: ${({ theme }) => theme.colors.border.width.sm} solid ${({ theme }) => theme.colors.control.border.default};
  top: 50%;
  transform: translateY(-50%);
`;

export const Slider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: transparent;
  position: absolute;
  z-index: 2;
  margin: 0;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    background: #007bff;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background 0.2s;

    &:hover {
      background: #0056b3;
    }

    &:active {
      background: #004494;
      transform: scale(1.1);
    }
  }

  &::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: #007bff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background 0.2s;

    &:hover {
      background: #0056b3;
    }

    &:active {
      background: #004494;
      transform: scale(1.1);
    }
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: linear-gradient(to right, rgba(0, 123, 255, 0.3) 0%, rgba(0, 123, 255, 0.3) var(--progress), rgba(0, 0, 0, 0.1) var(--progress), rgba(0, 0, 0, 0.1) 100%);
    border-radius: 4px;
    border: none;
  }

  &::-moz-range-track {
    width: 100%;
    height: 8px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: none;
  }

  &:focus {
    outline: none;
  }
`;

export const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 8px 0;
  height: 40px;
`;
