import { device } from '@/Layout/breakpoints';
import styled from 'styled-components';
import { FontSize, FONT_SIZES } from '@/Layout/typography';

export interface ContainerProps {
  $isVertical: boolean;
  $isSynthesizing: boolean;
  $active: boolean;
}

export interface PhraseTextProps {
  $fontSize: FontSize;
  $isSynthesizing: boolean;
}

export const StyledContainer = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(100%);
  min-height: 10vh;
  opacity: 1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  ${props =>
    props.$active &&
    `
    outline: 3px solid white;
  `}

  ${device.mobilePortrait} {
    margin: 0.5em 0;
  }

  ${device.mobileLandscape} {
    margin: 0.5em 0;
  }

  ${device.tabletPortrait} {
    outline: 2px ridge #32cd32;
  }
`;

export const PhraseText = styled.h1<PhraseTextProps>`
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: ${({ $fontSize }) => `${FONT_SIZES[$fontSize]}px`};
  color: ${props => (props.$isSynthesizing ? 'gray' : 'white')};
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  word-break: break-word;
  transition:
    font-size 0.3s ease,
    color 0.3s ease;

  /* Mobile Portrait */
  ${device.mobilePortrait} {
    font-size: ${({ $fontSize }) => `${Math.round(FONT_SIZES[$fontSize] * 0.8)}px`};
  }

  /* Mobile Landscape */
  ${device.mobileLandscape} {
    font-size: ${({ $fontSize }) => `${Math.round(FONT_SIZES[$fontSize] * 0.85)}px`};
  }

  /* Tablet Portrait */
  ${device.tabletPortrait} {
    font-size: ${({ $fontSize }) => `${Math.round(FONT_SIZES[$fontSize] * 0.9)}px`};
  }

  /* Tablet Landscape */
  ${device.tabletLandscape} {
    font-size: ${({ $fontSize }) => `${Math.round(FONT_SIZES[$fontSize] * 0.95)}px`};
  }

  @media (max-height: 700px) {
    font-size: ${({ $fontSize }) => `${Math.round(FONT_SIZES[$fontSize] * 0.7)}px`} !important;
  }
`;
