import styled from 'styled-components';
import { device } from '@/Layout/breakpoints';

// Componente base para los botones
export const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7em 1em;
  height: 55%;
  background: rgba(128, 128, 128, 0.1);
  border: none;
  cursor: pointer;
  color: white;
  font-size: 2em;
  transition: all 0.3s ease;
  border-radius: 8px;
  outline: 1px solid rgba(255, 255, 255, 0.5);

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:active {
    transform: scale(0.95);
  }

  ${device.mobilePortrait} {
    outline: none;
    background: rgba(128, 128, 128, 0.15);
    font-size: 0.9em;
    span {
      display: none;
    }
  }

  ${device.mobileLandscape} {
    padding: 0.5em;
    font-size: 1em;
    gap: 0.5em;
    margin-left: 0.7em;

    &:first-child {
      margin-left: 0;
    }
  }

  ${device.tabletPortrait} {
    font-size: 1.1em;
    min-width: calc(50% - 2em);
    gap: 1em;
  }

  ${device.tabletLandscape} {
    /* padding: 0.8em 1.8em; */
    font-size: 1em;
    gap: 1em;
  }

  ${device.desktopPortrait} {
    min-width: calc(50% - 1em);
    /* font-size: 1.3em; */
    gap: 1em;
    span {
      font-size: 0.1em;
    }
  }

  ${device.desktopLandscape} {
    min-width: calc(50% - 1em);
    min-height: 60px;
    /* font-size: 1.3em; */
    padding: 0.2em;

    gap: 0.5em;
    span {
      font-size: 0.8em;
    }
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;
  min-width: 10px;
  flex-direction: row;
  justify-content: space-evenly;

  ${device.mobilePortrait} {
    justify-content: space-evenly;
    width: 100%;
  }

  ${device.mobileLandscape} {
    font-size: 0.8em;
    width: auto;
    &:last-child {
      margin-left: 0.7em;
    }
  }

  ${device.tabletPortrait} {
    flex-direction: row;
    width: 100%;
    gap: 1em;
    justify-content: center;
  }

  ${device.tabletLandscape} {
    gap: 1em;
    /* outline: 1px solid red; */
    /* flex-grow: 1; */

    /* &:last-child {
      margin-left: 1em;
    } */
  }

  ${device.desktopPortrait} {
    outline: 1px solid black;
    justify-content: center;
    gap: 1em;
    height: auto;
  }
  ${device.desktopLandscape} {
    outline: 1px solid black;
  }
`;
