import styled from 'styled-components';
import { device } from '@/Layout/breakpoints';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.modal.overlay};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: ${({ theme }) => theme.spacing.modal.default};

  ${device.mobilePortrait} {
    padding: 0;
  }

  ${device.mobileLandscape} {
    padding: 0;
  }

  ${device.tabletPortrait} {
    padding: 0;
  }

  ${device.tabletLandscape} {
    padding: 0;
    height: 100vh;
  }

  ${device.desktopLandscape} {
    padding: ${({ theme }) => theme.spacing.modal.desktop};
  }
`;

export const Modal = styled.div`
  background: ${({ theme }) => theme.colors.modal.background};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.shadows.hover};
  position: relative;
  width: 100%;
  max-width: ${({ theme }) => theme.layout.modal.default};
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${device.mobilePortrait} {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
  }

  ${device.desktopLandscape} {
    max-width: ${({ theme }) => theme.layout.modal.desktop};
  }

  ${device.tabletPortrait} {
    height: 100vh;
    width: 100vw;
    border-radius: 0;
  }

  ${device.tabletLandscape} {
    height: 100vh;
    border-radius: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  top: 0;
  z-index: 10;
  border-radius: ${({ theme }) => `${theme.borderRadius.md} ${theme.borderRadius.md} 0 0`};
  padding: ${({ theme }) => theme.spacing.sm};

  h1 {
    margin: 0;
    color: ${({ theme }) => theme.colors.text.primary};
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.125em;
    background: ${({ theme }) => theme.colors.modal.header};
    display: flex;
    align-items: center;
  }

  ${device.mobilePortrait} {
    border-radius: 0;
  }
`;

export const CloseButton = styled.button`
  background: ${({ theme }) => theme.colors.modal.header};
  color: white;
  font-size: 1.5em;
  border: none;
  aspect-ratio: 1;
  width: 2em;
  height: 2em;

  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  padding: 0;

  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
    background: ${({ theme }) => theme.colors.modal.danger.hover};
  }

  &:active {
    background: ${({ theme }) => theme.colors.modal.danger.active};
  }

  /* outline: 2px solid red; */
  line-height: 1em;
`;

export const ModalContent = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.modal.default};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xl};
  min-height: 0;
  height: 100%;
  overflow: auto;

  ${device.mobilePortrait} {
    padding: ${({ theme }) => theme.spacing.modal.mobile};
    gap: ${({ theme }) => theme.spacing.md};
  }

  ${device.mobileLandscape}, ${device.tabletLandscape}, ${device.desktopLandscape} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const DetectionWrapper = styled.div`
  width: ${({ theme }) => theme.layout.detection.default};
  min-width: 0;

  ${device.mobileLandscape}, ${device.tabletLandscape}, ${device.desktopLandscape} {
    width: ${({ theme }) => theme.layout.detection.desktop};
    min-width: ${({ theme }) => theme.layout.detection.desktop};
  }
`;

export const DetectionColumn = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xl};
  width: 100%;

  ${device.mobileLandscape} {
    flex-direction: row;
    align-items: flex-start;
    padding-right: ${({ theme }) => theme.spacing.xl};
  }

  ${device.tabletLandscape} {
    flex-direction: row;
    border-right: ${({ theme }) => `${theme.colors.border.width.sm} solid ${theme.colors.border.primary}`};
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  ${device.desktopLandscape} {
    gap: ${({ theme }) => theme.spacing.xxl};
  }
`;

export const ConfigColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  /* min-height: 300px; */
  overflow: hidden;
  height: 100%;
  /* outline: 2px solid red; */

  ${device.desktopLandscape} {
    min-height: 50vh;
    max-height: 50vh;
    outline: 2px solid blue;
  }

  /* outline: 2px solid red; */
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-evenly;
  width: auto;
  gap: ${({ theme }) => theme.spacing.sm};
  flex: 1;
  min-width: 0;

  ${device.mobilePortrait} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${({ theme }) => theme.spacing.xs};
  }

  ${device.tabletPortrait} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${({ theme }) => theme.spacing.xs};
  }

  ${device.mobileLandscape}, ${device.tabletLandscape} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: ${({ theme }) => theme.spacing.sm};
  }

  ${device.desktopLandscape} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${({ theme }) => theme.spacing.md};
  }
`;

export const Tab = styled.button<{ $active: boolean }>`
  background: ${({ theme, $active }) => ($active ? theme.colors.button.primary.bg : theme.colors.button.secondary.bg)};
  color: ${({ theme, $active }) => ($active ? theme.colors.text.primary : theme.colors.text.secondary)};
  border: ${({ theme, $active }) => `${theme.colors.border.primary} solid ${$active ? theme.colors.button.primary.border : theme.colors.button.secondary.border}`};
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  cursor: pointer;
  transition: all 0.2s;
  font-size: 1.2em;
  white-space: normal;
  text-align: center;
  min-height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${({ theme, $active }) => ($active ? theme.colors.button.primary.hover : theme.colors.button.secondary.hover)};
    color: ${({ theme }) => theme.colors.text.primary};
  }

  ${device.mobilePortrait} {
    padding: ${({ theme }) => theme.spacing.xs};
    font-size: 0.85em;
    height: 52px;
  }

  ${device.desktopLandscape} {
    padding: ${({ theme }) => theme.spacing.md};
    font-size: 0.95em;
    min-height: 48px;
  }
`;

export const ConfigContent = styled.div`
  flex: 1;
  overflow-y: auto;
  min-height: 300px;
  /* outline: 2px solid red; */
  /* max-height: 50%; */
  height: 100%;

  ${device.mobilePortrait} {
    padding-right: 0;
    padding-bottom: ${({ theme }) => theme.spacing.xl};
    min-height: auto;
  }

  ${device.desktopLandscape} {
  }
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background.secondary};
  padding: ${({ theme }) => theme.spacing.xl};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  color: ${({ theme }) => theme.colors.text.primary};
  height: 100%;
  min-height: inherit;
`;

export const Title = styled.h3`
  margin: 0 0 ${({ theme }) => theme.spacing.md} 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1.1em;
`;
