import { device } from '@/Layout/breakpoints';
import styled from 'styled-components';

export const ButtonIcon = styled.img<{ $color?: string }>`
  fill: ${props => props.$color || 'white'};
  stroke: ${props => props.$color || 'white'};
  width: clamp(1.7em, 4vw, 40px);
  height: auto;
  /* outline: 1px solid ${props => props.color || 'white'}; */

  ${device.mobilePortrait} {
    width: clamp(2.3em, 9vw, 3em);
    outline: none;
  }
  ${device.mobileLandscape} {
    width: clamp(1.7em, 4vw, 40px);
  }
  ${device.tabletPortrait} {
    width: clamp(1.7em, 4vw, 40px);
  }
  ${device.tabletLandscape} {
    width: clamp(1.7em, 4vw, 40px);
  }
  ${device.desktopPortrait} {
    width: clamp(1.7em, 4vw, 40px);
  }
  ${device.desktopLandscape} {
    width: clamp(1.7em, 4vw, 40px);
  }
  /* margin-right: 1vw; */
`;
