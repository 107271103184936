import styled from 'styled-components';
import { device } from '@/Layout/breakpoints';
import { EYEGESTURE_TYPE } from '@/Models/Gesture';

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.control};
  padding: ${({ theme }) => theme.spacing.groupPadding};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: ${({ theme }) => theme.colors.border.width.md} solid ${({ theme }) => theme.colors.control.border.default};
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  transition: all 0.3s ease;
  background: ${({ theme }) => theme.colors.control.background.default};

  &:hover {
    border-color: ${({ theme }) => theme.colors.control.border.hover};
    background: ${({ theme }) => theme.colors.control.background.hover};
    box-shadow: ${({ theme }) => theme.shadows.hover};
  }

  ${device.desktopLandscape} {
    gap: ${({ theme }) => theme.spacing.section};
    padding: ${({ theme }) => theme.spacing.groupPadding};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    margin-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;

export const SliderLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.5rem;
  font-weight: 500;

  span:last-child {
    min-width: 40px;
    text-align: right;
  }

  ${device.desktopLandscape} {
    font-size: 1.8rem;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.grid.columns.default};
  gap: ${({ theme }) => theme.grid.gap.default};
  width: 100%;

  ${device.desktopLandscape} {
    grid-template-columns: ${({ theme }) => theme.grid.columns.desktop};
    gap: ${({ theme }) => theme.grid.gap.desktop};
  }
`;

export const GridItem = styled.div<{ $isDetection?: boolean }>`
  display: flex;
  align-items: center;
  min-height: ${({ theme }) => theme.sizes.control.minHeight};
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.sm};

  &:nth-child(1) {
    outline: ${({ theme }) => theme.colors.border.width.md} solid ${({ theme }) => theme.colors.gesture.lookLeft};
  }
  &:nth-child(2) {
    outline: ${({ theme }) => theme.colors.border.width.md} solid ${({ theme }) => theme.colors.gesture.lookRight};
  }
  &:nth-child(3) {
    outline: ${({ theme }) => theme.colors.border.width.md} solid ${({ theme }) => theme.colors.gesture.lookUp};
  }
  &:nth-child(4) {
    outline: ${({ theme }) => theme.colors.border.width.md} solid ${({ theme }) => theme.colors.gesture.lookDown};
  }
  &:nth-child(5) {
    outline: ${({ theme }) => theme.colors.border.width.md} solid ${({ theme }) => theme.colors.gesture.blink};
  }

  ${({ $isDetection, theme }) =>
    $isDetection &&
    `
    justify-content: flex-end;
    padding-right: ${theme.spacing.md};
  `}

  ${device.desktopLandscape} {
    min-height: ${({ theme }) => theme.sizes.control.desktop.minHeight};
    ${({ $isDetection, theme }) =>
      $isDetection &&
      `
      padding-right: ${theme.spacing.lg};
    `}
  }
`;
