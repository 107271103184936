import { GESTUREMACHINE_STATE, EYEGESTURE_TYPE } from './types';

export const DEFAULT_IGNORE_THRESHOLD = 0.3;
export const DEFAULT_CONFIRM_THRESHOLD = 0.5;
export const DEFAULT_CONFIRMATION_TIME = 500;
export const DEFAULT_COOLDOWN_TIME = 1000;

export const DEFAULT_GESTURE_THRESHOLDS = {
  ignoreThreshold: DEFAULT_IGNORE_THRESHOLD,
  confirmThreshold: DEFAULT_CONFIRM_THRESHOLD,
};

export const DEFAULT_GESTURE_TIMES = {
  confirmTime: DEFAULT_CONFIRMATION_TIME,
  cooldownTime: DEFAULT_COOLDOWN_TIME,
};

export const TEST_CONSTANTS = {
  // Configuration thresholds
  CONFIG: {
    SHORT: {
      ignoreThreshold: 0.2,
      confirmThreshold: 0.5,
      confirmTime: 1000,
      cooldownTime: 500,
      normalizationFactor: 1,
      historyDepth: 10,
    } as const,
    LONG: {
      ignoreThreshold: 0.6,
      confirmThreshold: 0.9,
      confirmTime: 1500,
      cooldownTime: 1500,
      normalizationFactor: 1,
      historyDepth: 10,
    } as const,
    INITIAL: {
      ignoreThreshold: 0.4,
      confirmThreshold: 0.85,
      confirmTime: 1000,
      cooldownTime: 1000,
      normalizationFactor: 1,
      historyDepth: 10,
    } as const,

    EXCLUSIVE: {
      ignoreThreshold: 0.4,
      confirmThreshold: 0.85,
      confirmTime: 1000,
      cooldownTime: 1000,
      normalizationFactor: 1,
      historyDepth: 10,
    } as const,
    // Updated thresholds for testing
    UPDATED: {
      ignoreThreshold: 0.6, // Higher ignore threshold
      confirmThreshold: 0.95, // Higher confirm threshold
      confirmTime: 1500, // Shorter confirmation time
      cooldownTime: 800, // Shorter cooldown time
      normalizationFactor: 1,
      historyDepth: 10,
    } as const,
    INVALID: {
      ignoreThreshold: 1.1, // Invalid ignore threshold
      confirmThreshold: -0.85, // Valid confirm threshold
      confirmTime: 1, // Shorter confirmation time
      cooldownTime: 3, // Shorter cooldown time
      normalizationFactor: -1,
      historyDepth: -10,
    } as const,
    INVALID_UPDATED: {
      ignoreThreshold: 0.4,
      confirmThreshold: 0.85,
      confirmTime: 1000,
      cooldownTime: 1000,
    },
  } as const,

  // Score test values
  SCORES: {
    IGNORE_SHORT: 0.1, // Score below ignoreThreshold
    IGNORE_SHORT_BELOW: 0.19, // Score below ignoreThreshold
    IGNORE_SHORT_ABOVE: 0.21, // Score below ignoreThreshold

    IGNORE_LONG: 0.5, // Score below ignoreThreshold
    IGNORE_LONG_ABOVE: 0.61, // Score below ignoreThreshold

    ACTIVE_SHORT: 0.3, // Score for ACTIVE state
    ACTIVE_SHORT_TWO: 0.41, // Score for ACTIVE state

    ACTIVE_LONG: 0.7, // Score for ACTIVE state
    CONFIRM_SHORT: 0.55, // Score above confirmThreshold
    CONFIRM_LONG: 0.94, // Score above confirmThreshold
    CONFIRM_LONG_TWO: 0.95, // Score above confirmThreshold
    AT_IGNORE_THRESHOLD: 0.2, // Score exactly at ignoreThreshold
    AT_CONFIRM_THRESHOLD: 0.5, // Score exactly at confirmThreshold
  } as const,

  // Expected state transitions
  STATE_TRANSITIONS: [
    { score: 0.35, expectedState: GESTUREMACHINE_STATE.ACTIVE },
    { score: 0.9, expectedState: GESTUREMACHINE_STATE.CONFIRMING },
    { score: 0.2, expectedState: GESTUREMACHINE_STATE.IDLE },
    { score: 0.1, expectedState: GESTUREMACHINE_STATE.IDLE }, // Below ignore
    { score: 0.4, expectedState: GESTUREMACHINE_STATE.ACTIVE }, // Between ignore and confirm
    { score: 0.6, expectedState: GESTUREMACHINE_STATE.CONFIRMING }, // Above ignore, below confirm
    { score: 0.95, expectedState: GESTUREMACHINE_STATE.CONFIRMING }, // Above confirm
    { score: 0.2, expectedState: GESTUREMACHINE_STATE.IDLE }, // At ignore threshold
    { score: 0.5, expectedState: GESTUREMACHINE_STATE.CONFIRMING }, // At confirm threshold
  ] as const,

  // Test scores for different threshold scenarios
  THRESHOLD_SCORES: {
    VALID_FOR_OLD_IGNORE: 0.5, // Above old ignore (0.4) but below new ignore (0.6)
    VALID_FOR_NEW_IGNORE: 0.7, // Above new ignore threshold (0.6)
    VALID_FOR_OLD_CONFIRM: 0.9, // Above old confirm (0.85) but below new confirm (0.95)
    VALID_FOR_NEW_CONFIRM: 0.98, // Above new confirm threshold (0.95)
  } as const,

  // Gestures
  GESTURES: {
    PRIMARY: [EYEGESTURE_TYPE.LOOK_UP, EYEGESTURE_TYPE.LOOK_DOWN, EYEGESTURE_TYPE.LOOK_LEFT, EYEGESTURE_TYPE.LOOK_RIGHT, EYEGESTURE_TYPE.BLINK] as const,
  } as const,

  // Additional test constants
  ADDITIONAL_TEST_CONSTANTS: {
    // Initial configuration
    INITIAL_CONFIG: {
      ignoreThreshold: 0.4,
      confirmThreshold: 0.85,
      confirmTime: 1000,
      cooldownTime: 1000,
    } as const,

    // Updated thresholds
    UPDATED_CONFIG: {
      ignoreThreshold: 0.6, // Higher ignore threshold
      confirmThreshold: 0.95, // Higher confirm threshold
      confirmTime: 1500, // Shorter confirmation time
      cooldownTime: 800, // Shorter cooldown time
    } as const,

    // Test scores for different threshold scenarios
    SCORES: {
      VALID_FOR_OLD_IGNORE: 0.5, // Above old ignore (0.4) but below new ignore (0.6)
      VALID_FOR_NEW_IGNORE: 0.7, // Above new ignore threshold (0.6)
      VALID_FOR_OLD_CONFIRM: 0.9, // Above old confirm (0.85) but below new confirm (0.95)
      VALID_FOR_NEW_CONFIRM: 0.98, // Above new confirm threshold (0.95)
    } as const,
  } as const,
} as const;

export const mockExpectedSettings = {
  ignoreThreshold: 0.3,
  confirmThreshold: 0.5,
  confirmTime: 500,
  cooldownTime: 1000,
};

export const mockExpectedInitializationValues = {
  ignoreThreshold: 0.3,
  confirmThreshold: 0.5,
  confirmTime: 500,
  cooldownTime: 1000,
};
