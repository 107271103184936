import React from 'react';
import { TEYEGESTURE_TYPE } from '@/Models/Gesture';
import { FeedbackContainer } from './ConfirmationFeedback.styles';
import GestureCheck from '@/Components/GestureFlag/Check/GestureCheck';

interface ConfirmationFeedbackProps {
  gesture: TEYEGESTURE_TYPE;
}

const ConfirmationFeedback: React.FC<ConfirmationFeedbackProps> = React.memo(({ gesture }) => {
  // Optimizar selectores de Zustand con shallow comparison

  return (
    <FeedbackContainer id={gesture + '-feedback'}>
      <GestureCheck gesture={gesture} $visible={true} />
    </FeedbackContainer>
  );
});

ConfirmationFeedback.displayName = 'ConfirmationFeedback';

export default ConfirmationFeedback;
