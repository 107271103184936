import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { UI_CONTROL } from '@/Models/UserSession/types/domain';
import { GESTURE_SETTING_KEYS, TEYEGESTURE_TYPE, useGestureSetting, useGestureSettings, useGestureSettingsUpdate } from '@/Models/Gesture';
import { useDebounceValue } from '@/Hooks/useDebounceValue';
import { SliderContainer, SliderLabel, SliderTrack, Slider, SliderWrapper } from './ConfirmationSlider.styles';
import useConfigurationStore from '@/Models/UserSession/store/useConfigurationStore';

interface ConfirmationSliderProps {
  gesture: TEYEGESTURE_TYPE;
}

const { CONFIRM_TIME } = GESTURE_SETTING_KEYS;

/**
 * Componente para controlar el tiempo de confirmación de un gesto
 * Optimizado para evitar re-renders innecesarios
 */
const ConfirmationSlider: React.FC<ConfirmationSliderProps> = ({ gesture }) => {
  // Obtener la configuración actual del gesto desde el store de gestos

  const { updateStoredGestureSettings } = useConfigurationStore();
  const currentGestureConfirmTime = useGestureSetting(gesture, CONFIRM_TIME);
  const confirmTime = useMemo(() => currentGestureConfirmTime, [gesture]);

  // Obtener la función para actualizar la configuración del gesto
  const { updateGestureSetting } = useGestureSettingsUpdate();

  // Keep track of previous reaction time to prevent unnecessary updates
  const prevReactionTimeRef = useRef(confirmTime);

  // Manejar cambios en la configuración con memorización adecuada
  const handleSettingsChange = useCallback(
    (value: number) => {
      if (value !== prevReactionTimeRef.current) {
        prevReactionTimeRef.current = value;
        // Actualizar solo el atributo confirmTime
        updateGestureSetting(gesture, CONFIRM_TIME, value);
        updateStoredGestureSettings(gesture, { [CONFIRM_TIME]: value });
      }
    },
    [gesture, updateGestureSetting, updateStoredGestureSettings]
  );

  // Initialize debounce value with current settings
  // Convert ms to seconds for UI
  const msToSeconds = (ms: number) => ms / 1000;
  const secondsToMs = (s: number) => Math.round(s * 1000);

  const { value, setValue, immediateValue } = useDebounceValue(msToSeconds(confirmTime), {
    delay: UI_CONTROL.DEBOUNCE.DEFAULT_DELAY,
    minDiff: UI_CONTROL.DEBOUNCE.MIN_DIFF,
    onChange: seconds => handleSettingsChange(secondsToMs(seconds)),
  });

  // Update value when settings change externally
  useEffect(() => {
    const valueInSeconds = msToSeconds(confirmTime);
    if (valueInSeconds !== value) {
      setValue(valueInSeconds);
    }
  }, [confirmTime, setValue, value]);

  // Memoize the change handler
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(parseFloat(e.target.value));
    },
    [setValue]
  );

  // Memoize progress calculation
  const progress = useMemo(() => {
    return ((immediateValue - UI_CONTROL.GESTURE.REACTION_TIME.MIN) / (UI_CONTROL.GESTURE.REACTION_TIME.MAX - UI_CONTROL.GESTURE.REACTION_TIME.MIN)) * 100;
  }, [immediateValue]);

  // Memoize style object
  const sliderStyle = useMemo(
    () =>
      ({
        '--progress': `${progress}%`,
      }) as React.CSSProperties,
    [progress]
  );

  return (
    <SliderContainer>
      <SliderLabel>
        <span>Confirmación</span>
        <span>{value?.toFixed(1)}s</span>
      </SliderLabel>
      <SliderWrapper>
        <SliderTrack />
        <Slider
          type="range"
          min={UI_CONTROL.GESTURE.REACTION_TIME.MIN}
          max={UI_CONTROL.GESTURE.REACTION_TIME.MAX}
          step={UI_CONTROL.GESTURE.REACTION_TIME.STEP}
          value={immediateValue}
          onChange={handleChange}
          style={sliderStyle}
        />
      </SliderWrapper>
    </SliderContainer>
  );
};

ConfirmationSlider.displayName = 'ConfirmationSlider';

// Use React.memo with a custom comparison function
export default React.memo(ConfirmationSlider, (prevProps, nextProps) => {
  return prevProps.gesture === nextProps.gesture;
});
