import React from 'react';
import { ReactComponent as EyeActive } from '@/Assets/icons/eye-active.svg';
import { ReactComponent as EyeInactive } from '@/Assets/icons/eye-inactive.svg';
import { ButtonIcon } from '@/Components/Shared/ButtonIcon';
import { EyeLabel, EyeStatus, StyledEyeControl } from './EyeStatusIndicator';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { EyeGestureSource, TEyeGestureSource } from '@/Models/Gesture';

const { LEFT_EYE } = EyeGestureSource;

interface EyeControlProps {
  side: TEyeGestureSource;
}

export const EyeControl: React.FC<EyeControlProps> = ({ side }) => {
  const {
    setEyeSource,
    user: {
      settings: {
        selectedKeyboardSettings: { eyeSource },
      },
    },
  } = useUserSessionStore();

  const onToggle = () => {
    const otherEye = side === LEFT_EYE ? 'rightEye' : 'leftEye';
    // Si el otro ojo está desactivado y este se va a desactivar, no permitir el cambio
    if ((otherEye === 'rightEye' && !eyeSource.rightEye && eyeSource.leftEye) || (otherEye === 'leftEye' && !eyeSource.leftEye && eyeSource.rightEye)) {
      return;
    }

    setEyeSource({
      ...eyeSource,
      leftEye: side === LEFT_EYE ? !eyeSource.leftEye : eyeSource.leftEye,
      rightEye: side !== LEFT_EYE ? !eyeSource.rightEye : eyeSource.rightEye,
    });
  };

  const isActive = side === LEFT_EYE ? eyeSource.leftEye : eyeSource.rightEye;

  return (
    <StyledEyeControl>
      <EyeStatus onClick={onToggle}>
        <ButtonIcon as={isActive ? EyeActive : EyeInactive} alt={`${side} eye ${isActive ? 'active' : 'inactive'}`} />
        <EyeLabel>
          Ojo {side === LEFT_EYE ? 'izquierdo' : 'derecho'} {isActive ? 'activo' : 'inactivo'}
        </EyeLabel>
      </EyeStatus>
    </StyledEyeControl>
  );
};

export default EyeControl;
