import styled from 'styled-components';
import { device } from '@/Layout/breakpoints';

export const AlertContainer = styled.div`
  margin-top: 15px;
  color: white;
  background-color: rgba(255, 0, 0, 0.843);
  padding: 0.5em 1em;
  border-radius: 5px;
  font-weight: 600;
  text-shadow: 2px 1px 0px rgb(0 0 0 / 55%);
  box-shadow: 3px 3px 4px rgba(255, 0, 0, 0.3);
  display: flex;
  align-items: center;
  gap: 0.75em;
  font-size: 1em;
  line-height: 1.2em;

  svg {
    width: 24px;
    height: 24px;
    filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.55));
  }

  ${device.mobilePortrait} {
    font-size: 0.9em;
    padding: 0.4em 0.8em;
    svg {
      width: 18px;
      height: 18px;
    }
  }

  ${device.mobileLandscape} {
    font-size: 0.85em;
    padding: 0.3em 0.6em;
    margin-top: 10px;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  ${device.tabletPortrait} {
    font-size: 1.1em;
    padding: 0.6em 1.2em;
    svg {
      width: 22px;
      height: 22px;
    }
  }

  ${device.tabletLandscape} {
    font-size: 1em;
    padding: 0.5em 1em;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  ${device.desktopPortrait} {
    font-size: 1.1em;
    padding: 0.6em 1.2em;
    svg {
      width: 22px;
      height: 22px;
    }
  }

  ${device.desktopLandscape} {
    font-size: 1.1em;
    padding: 0.6em 1.2em;
    svg {
      width: 22px;
      height: 22px;
    }
  }
`;
