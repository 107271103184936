import React from 'react';
import { TEYEGESTURE_TYPE } from '@/Models/Gesture';
import GestureCheck from './GestureCheck';

interface CheckProps {
  gesture: TEYEGESTURE_TYPE;
}

const Check: React.FC<CheckProps> = ({ gesture }) => {
  return <GestureCheck gesture={gesture} />;
};

export default Check;
