import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { TGestureSettings, TEYEGESTURE_TYPE, TGestureDetectionSettings } from '../../Gesture';
import { IUserSettings, defaultUserSettings } from '../types/configuration';

// Mock de servicios externos
const mockConfigurationService = {
  fetchSettings: async (): Promise<IUserSettings> => {
    // Simula delay de red
    await new Promise(resolve => setTimeout(resolve, 500));
    return { ...defaultUserSettings };
  },

  saveSettings: async (settings: IUserSettings): Promise<void> => {
    // Simula delay de red
    await new Promise(resolve => setTimeout(resolve, 500));
    console.log('[MOCK] Guardando configuración en servidor:', settings);
  },
};

// Mock de localStorage para testing
const mockStorage = {
  getItem: (key: string): string | null => {
    console.log('[MOCK] Leyendo de localStorage:', key);
    return null;
  },
  setItem: (key: string, value: string): void => {
    console.log('[MOCK] Escribiendo en localStorage:', key, value);
  },
  removeItem: (key: string): void => {
    console.log('[MOCK] Eliminando de localStorage:', key);
  },
};

interface ConfigurationState {
  // Configuration state
  settings: IUserSettings;
  isInitialized: boolean;
  isSyncing: boolean;
  lastSyncTime: number | null;
  syncError: string | null;
  configModalOpen: boolean;

  // Actions
  initializeSettings: () => Promise<void>;
  updateSettings: (newSettings: Partial<IUserSettings>) => Promise<void>;
  updateStoredGestureSettings: (gesture: TEYEGESTURE_TYPE, settings: Partial<TGestureSettings>) => Promise<void>;
  getStoredGestureSettings: (gesture: TEYEGESTURE_TYPE) => TGestureSettings;
  getAllStoredGestureSettings: () => TGestureDetectionSettings;
  syncWithServer: () => Promise<void>;
  resetToDefaults: () => void;
  setConfigModalOpen: (open: boolean) => void;
}

const useConfigurationStore = create<ConfigurationState>()(
  persist(
    (set, get) => ({
      // Initial state
      settings: defaultUserSettings,
      isInitialized: false,
      isSyncing: false,
      lastSyncTime: null,
      syncError: null,
      configModalOpen: false,

      // Setters
      setConfigModalOpen: (open: boolean) => {
        set({ configModalOpen: open });
      },
      // Initialize settings from localStorage and server
      initializeSettings: async () => {
        try {
          set({ isInitialized: false, isSyncing: true });

          // Cargar configuración del servidor (mock)
          const serverSettings = await mockConfigurationService.fetchSettings();

          set({
            settings: serverSettings,
            isInitialized: true,
            isSyncing: false,
            lastSyncTime: Date.now(),
            syncError: null,
          });
        } catch (error) {
          set({
            isInitialized: true,
            isSyncing: false,
            syncError: error instanceof Error ? error.message : 'Failed to initialize settings',
          });
          console.error('[ERROR] Error inicializando configuración:', error);
        }
      },

      // Update settings locally and queue sync with server
      updateSettings: async (newSettings: Partial<IUserSettings>) => {
        try {
          const settings = get().settings;
          const updatedSettings = {
            ...settings,
            ...newSettings,
          };

          set({
            settings: updatedSettings,
            isSyncing: true,
          });

          // Sync with server
          await get().syncWithServer();
        } catch (error) {
          set({
            syncError: error instanceof Error ? error.message : 'Failed to update settings',
            isSyncing: false,
          });
        }
      },

      // Sync current settings with the server
      syncWithServer: async () => {
        try {
          const settings = get().settings;

          // Simular sincronización con el servidor
          await mockConfigurationService.saveSettings(settings);

          set({
            lastSyncTime: Date.now(),
            isSyncing: false,
            syncError: null,
          });
        } catch (error) {
          set({
            syncError: error instanceof Error ? error.message : 'Failed to sync with server',
            isSyncing: false,
          });
          console.error('[ERROR] Error sincronizando con servidor:', error);
        }
      },

      // Update gesture settings
      updateStoredGestureSettings: async (gesture: TEYEGESTURE_TYPE, newSettings: Partial<TGestureSettings>) => {
        try {
          const settings = get().settings;
          console.log('DEBUG: updateStoredGestureSettings', settings);
          set({
            settings: {
              ...settings,
              selectedKeyboardSettings: {
                ...settings.selectedKeyboardSettings,
                gestureDetection: {
                  ...settings.selectedKeyboardSettings.gestureDetection,
                  [gesture]: {
                    ...settings.selectedKeyboardSettings.gestureDetection[gesture],
                    ...newSettings,
                  },
                },
              },
            },
            isSyncing: true,
          });

          await get().syncWithServer();
        } catch (error) {
          set({
            syncError: error instanceof Error ? error.message : 'Failed to update gesture settings',
            isSyncing: false,
          });
          console.error('[ERROR] Error actualizando configuración de gesto:', error);
        }
      },

      // Get gesture settings
      getStoredGestureSettings: (gesture: TEYEGESTURE_TYPE) => {
        const settings = get().settings;
        return settings.selectedKeyboardSettings.gestureDetection[gesture];
      },
      // Get all gesture settings
      getAllStoredGestureSettings: () => {
        const settings = get().settings;
        return settings.selectedKeyboardSettings.gestureDetection;
      },

      // Reset settings to defaults
      resetToDefaults: () => {
        set({
          settings: defaultUserSettings,
          lastSyncTime: null,
          syncError: null,
        });
      },
    }),
    {
      name: 'user-configuration-storage',
      storage: createJSONStorage(() => (process.env.NODE_ENV === 'test' ? mockStorage : localStorage)),
      partialize: state => ({
        settings: state.settings,
        lastSyncTime: state.lastSyncTime,
      }),
    }
  )
);

export default useConfigurationStore;
