import { useRef, useEffect, useCallback, useMemo } from 'react';
import { FaceLandmarker, FilesetResolver } from '@mediapipe/tasks-vision';
import { useDetectionStore } from '@/Models/Detection';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { KEYBOARD_TYPE } from '@/Models/Keyboard/types/types';
import { IUserSessionState } from '@/Models/useUserSessionStore';

const { VERTICAL_SPLIT_SELECTION, HORIZONTAL_SPLIT_SELECTION } = KEYBOARD_TYPE;

/**
 * Hook para manejar la detección de landmarks faciales usando MediaPipe
 * Optimizado para minimizar re-renders y manejar errores
 */
export const useDetectionLandMarker = () => {
  // Referencias para evitar re-renders y mantener estado entre renders
  const updateCountRef = useRef(0);
  const detectorRef = useRef<FaceLandmarker | null>(null);
  const lastVideoTimeRef = useRef<number>(0);
  const animationFrameRef = useRef<number | null>(null);
  const errorCountRef = useRef<number>(0);
  const isRunningRef = useRef<boolean>(false);

  // Selectores memoizados para el detection store
  const { detector, videoElement, setFaceLandMarkerDetector, setFaceLandMarkerDetectorResults, setBenchmark } = useDetectionStore(
    useCallback(
      state => ({
        detector: state.faceLandMark.detector,
        videoElement: state.webcam.videoElement,
        setFaceLandMarkerDetector: state.setFaceLandMarkerDetector,
        setFaceLandMarkerDetectorResults: state.setFaceLandMarkerDetectorResults,
        setBenchmark: state.setBenchmark,
      }),
      []
    )
  );

  // Selectores memoizados para el user session store
  const { selectedKeyboard, isAuthenticated } = useUserSessionStore(
    useCallback(
      (state: IUserSessionState) => ({
        selectedKeyboard: state.user.settings.selectedKeyboard,
        isAuthenticated: state.isAuthenticated,
      }),
      []
    )
  );

  // Actualizar métricas de rendimiento cada segundo
  useEffect(() => {
    const intervalId = setInterval(() => {
      setBenchmark({ PPS: updateCountRef.current });
      updateCountRef.current = 0;
    }, 1000);

    return () => clearInterval(intervalId);
  }, [setBenchmark]);

  // Función memoizada para limpiar el animation frame
  const cleanupAnimationFrame = useCallback(() => {
    if (animationFrameRef.current) {
      window.cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
      isRunningRef.current = false;
    }
  }, []);

  // Función memoizada para cargar el modelo
  const loadModel = useCallback(async () => {
    try {
      // Evitar cargar el modelo múltiples veces
      if (detectorRef.current) {
        console.log(' [APP INFO] Detector already loaded');
        return;
      }

      // Cargar el modelo con manejo de errores mejorado
      const vision = await FilesetResolver.forVisionTasks('https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm').catch(error => {
        console.error('[APP ERROR] Failed to resolve vision tasks:', error);
        throw new Error('Failed to initialize vision tasks');
      });

      detectorRef.current = await FaceLandmarker.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
          delegate: 'GPU',
        },
        outputFaceBlendshapes: true,
        outputFacialTransformationMatrixes: true,
        runningMode: 'VIDEO',
        numFaces: 1,
      }).catch(error => {
        console.error('[APP ERROR] Failed to create face landmarker:', error);
        throw new Error('Failed to initialize face landmarker');
      });

      if (detectorRef.current) {
        setFaceLandMarkerDetector(detectorRef.current);
        console.log(' [APP INFO] Face landmarker loaded successfully');
        // Resetear contador de errores cuando el modelo se carga exitosamente
        errorCountRef.current = 0;
      } else {
        throw new Error('Face landmarker initialization returned null');
      }
    } catch (error) {
      console.error('[APP ERROR] Failed to load model:', error);
      // Reintentar cargar el modelo después de un retraso si falla (máximo 3 intentos)
      if (errorCountRef.current < 3) {
        errorCountRef.current++;
        console.log(`[APP INFO] Retrying model load (attempt ${errorCountRef.current}/3)...`);
        setTimeout(loadModel, 2000);
      }
    }
  }, [setFaceLandMarkerDetector]);

  // Función memoizada para predecir landmarks faciales
  const predict = useCallback(() => {
    // Evitar ejecución si no está autenticado
    if (!isAuthenticated) {
      cleanupAnimationFrame();
      return;
    }

    // Evitar múltiples ejecuciones simultáneas
    if (!isRunningRef.current) {
      isRunningRef.current = true;
    }

    try {
      const startTimeMs = performance.now();

      // Verificar que todos los componentes necesarios estén disponibles
      if (!detector || !videoElement) {
        throw new Error('Detector or video element not available');
      }

      // Solo detectar si el frame de video ha cambiado
      if (lastVideoTimeRef.current !== videoElement.currentTime) {
        lastVideoTimeRef.current = videoElement.currentTime;

        // Ejecutar detección con manejo de errores
        const results = detector.detectForVideo(videoElement, startTimeMs);
        // Actualizar resultados y contador
        setFaceLandMarkerDetectorResults(results);
        updateCountRef.current++;

        // Resetear contador de errores en caso de éxito
        if (errorCountRef.current > 0) {
          errorCountRef.current = 0;
        }
      }
    } catch (error) {
      errorCountRef.current++;
      console.error(`[ERROR] Face detection failed (error #${errorCountRef.current}):`, error);

      // Si hay demasiados errores consecutivos, detener la detección
      if (errorCountRef.current > 5) {
        console.error('[ERROR] Too many consecutive errors, stopping detection');
        cleanupAnimationFrame();
        return;
      }
    }

    // Continuar el ciclo de animación si las condiciones son adecuadas
    if (isAuthenticated && [VERTICAL_SPLIT_SELECTION, HORIZONTAL_SPLIT_SELECTION].includes(selectedKeyboard)) {
      animationFrameRef.current = requestAnimationFrame(predict);
    } else {
      isRunningRef.current = false;
    }
  }, [isAuthenticated, detector, videoElement, selectedKeyboard, cleanupAnimationFrame, setFaceLandMarkerDetectorResults]);

  // Cargar modelo al montar el componente
  useEffect(() => {
    loadModel();
  }, [loadModel]);

  // Verificar si se cumplen las condiciones para iniciar la predicción
  const shouldPredict = useMemo(() => {
    return !!videoElement && !!detector && isAuthenticated && [VERTICAL_SPLIT_SELECTION, HORIZONTAL_SPLIT_SELECTION].includes(selectedKeyboard);
  }, [videoElement, detector, isAuthenticated, selectedKeyboard]);

  // Iniciar predicción cuando todo esté listo
  useEffect(() => {
    if (shouldPredict && !isRunningRef.current) {
      predict();
    }

    return () => cleanupAnimationFrame();
  }, [shouldPredict, predict, cleanupAnimationFrame]);

  // No retornamos nada ya que este es un hook de efecto
  return null;
};
