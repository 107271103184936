import React, { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { login } from '../../Services/AuthService';
import Preloader from '@/Components/Common/Preloader';
import { LoginContainer, FormContainer, StyledForm, FormGroup, Label, Field, LoginButton, LoginHeader } from './Login.styles';
import Logo from '@/Components/Logo/Logo';
import { AuthError, AuthErrorType } from '@/Models/UserSession';
import Alert from '@/Components/Alert';

interface LoginFormValues {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [showPreloader, setShowPreloader] = useState<boolean>(false);

  const initialValues: LoginFormValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Este campo es obligatorio!').email('Dirección de correo inválida'),
    password: Yup.string().required('Este campo es obligatorio!'),
  });

  const handleLoginError = (error: AuthError) => {
    switch (error.type) {
      case AuthErrorType.TIMEOUT:
      case AuthErrorType.NETWORK:
        setMessage('Error de conexión. Por favor, verifica tu conexión a internet e intenta nuevamente.');
        break;

      case AuthErrorType.UNAUTHORIZED:
        setMessage('Correo o contraseña inválidos.');
        break;

      case AuthErrorType.SERVER:
        setMessage('Error del servidor. Por favor, intenta más tarde.');
        break;

      default:
        setMessage('Ocurrió un error inesperado. Por favor, intenta nuevamente.');
    }
  };

  const handleLogin = async (formValue: LoginFormValues) => {
    const { email, password } = formValue;

    setMessage('');
    setLoading(true);
    setShowPreloader(true);

    try {
      await login(email, password);
      navigate('/home');
    } catch (error) {
      handleLoginError(error as AuthError);
    } finally {
      setLoading(false);
      setShowPreloader(false);
    }
  };

  return (
    <>
      {showPreloader && <Preloader message="Iniciando sesión..." />}
      <LoginContainer>
        <LoginHeader id="login-header">
          <Logo />
        </LoginHeader>

        <FormContainer id="form-container">
          <Formik<LoginFormValues> initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleLogin}>
            {({ isSubmitting, errors, touched, values }: FormikProps<LoginFormValues>) => (
              <StyledForm>
                <FormGroup>
                  <Label htmlFor="email">Correo electrónico</Label>
                  <Field name="email" type="text" aria-invalid={Boolean(errors.email && touched.email)} />
                  <ErrorMessage name="email" render={msg => <Alert message={msg} />} />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="password">Contraseña</Label>
                  <Field name="password" type="password" aria-invalid={Boolean(errors.password && touched.password)} />
                  <ErrorMessage name="password" render={msg => <Alert message={msg} />} />
                </FormGroup>
                <FormGroup>
                  <LoginButton type="submit" disabled={loading || isSubmitting || !values.email || !values.password || Object.keys(errors).length > 0}>
                    {isSubmitting && <Preloader message="Iniciando sesión..." />}
                    {!loading && <span>Iniciar sesión</span>}
                  </LoginButton>
                </FormGroup>
                {message && (
                  <FormGroup>
                    <Alert message={message} />
                  </FormGroup>
                )}
              </StyledForm>
            )}
          </Formik>
        </FormContainer>
      </LoginContainer>
    </>
  );
};

export default Login;
