import { useCallback } from 'react';
import { useGestureTrackingStore, TGestureTrackingStore } from '../index';
import { TEYEGESTURE_TYPE } from '../types/base/gestureEnums';
import { IGestureMachineContext } from '../types/stateMachine/gestureStates';

/**
 * Función interna para obtener el contexto de un gesto desde el store
 * @param state - Estado del store
 * @param gestureType - Tipo de gesto
 * @returns Contexto actual del gesto
 */
export function getGestureContextFromStore(state: TGestureTrackingStore, gestureType: TEYEGESTURE_TYPE): IGestureMachineContext | null {
  const interpreter = state.gestures[gestureType];
  if (!interpreter) return null;

  return interpreter.getSnapshot().context;
}

/**
 * Hook para obtener el contexto completo de un gesto
 * @param gestureType - Tipo de gesto a consultar
 * @returns Contexto completo del gesto
 */
export const useGestureContext = (gestureType: TEYEGESTURE_TYPE): IGestureMachineContext | null => {
  return useGestureTrackingStore(useCallback((state: TGestureTrackingStore) => getGestureContextFromStore(state, gestureType), [gestureType]));
};

/**
 * Hook para obtener el tiempo de inicio de confirmación de un gesto
 * @param gestureType - Tipo de gesto a consultar
 * @returns Tiempo de inicio de confirmación del gesto (timestamp en milisegundos)
 */
export const useGestureConfirmationStartTime = (gestureType: TEYEGESTURE_TYPE): number => {
  return useGestureTrackingStore(
    useCallback(
      (state: TGestureTrackingStore) => {
        const context = getGestureContextFromStore(state, gestureType);
        return context?.confirmationStartTime || 0;
      },
      [gestureType]
    )
  );
};
