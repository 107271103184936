import React from 'react';
import { EyeControl } from './EyeControl';
import { EyeContainer } from './EyeStatusIndicator';
import { EyeGestureSource } from '@/Models/Gesture';
import { IndicatorWrapper } from './EyeStatusIndicator';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { IUserSessionState } from '@/Models/useUserSessionStore';
const { LEFT_EYE } = EyeGestureSource;

const LeftEyeStatusIndicator: React.FC = () => {
  const left_eye = useUserSessionStore((state: IUserSessionState) => state.user.settings.selectedKeyboardSettings.eyeSource.leftEye);

  return (
    <IndicatorWrapper $isActive={left_eye}>
      <EyeContainer>
        <EyeControl side={LEFT_EYE} />
      </EyeContainer>
    </IndicatorWrapper>
  );
};

export default LeftEyeStatusIndicator;
