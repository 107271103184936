import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { UI_CONTROL } from '@/Models/UserSession/types/domain';
import { GESTURE_SETTING_KEYS, TEYEGESTURE_TYPE, useGestureSetting, useGestureSettingsUpdate } from '@/Models/Gesture';
import { useDebounceValue } from '@/Hooks/useDebounceValue';
import { SliderContainer, SliderLabel, SliderTrack, Slider, SliderWrapper } from '../ConfirmationSlider/ConfirmationSlider.styles';
import useConfigurationStore from '@/Models/UserSession/store/useConfigurationStore';

const { NORMALIZATION_FACTOR } = GESTURE_SETTING_KEYS;

interface NormalizationSliderProps {
  gesture: TEYEGESTURE_TYPE;
}

const MIN_NORMALIZATION = 0.3;
const MAX_NORMALIZATION = 1.0;
const STEP = 0.1;

const NormalizationSlider: React.FC<NormalizationSliderProps> = ({ gesture }) => {
  const normalizationFactor = useGestureSetting(gesture, NORMALIZATION_FACTOR);
  const { updateGestureSetting } = useGestureSettingsUpdate();
  const { updateStoredGestureSettings } = useConfigurationStore();
  // Keep track of previous normalization factor to prevent unnecessary updates
  const prevNormFactorRef = useRef(normalizationFactor);

  // Memoize current settings
  const currentNormalizationFactor = useMemo(() => normalizationFactor, [normalizationFactor]);

  // Handle settings change with proper memoization
  const handleSettingsChange = useCallback(
    (value: number) => {
      if (value !== prevNormFactorRef.current) {
        prevNormFactorRef.current = value;
        updateGestureSetting(gesture, NORMALIZATION_FACTOR, value);
        updateStoredGestureSettings(gesture, { [NORMALIZATION_FACTOR]: value });
      }
    },
    [gesture, updateGestureSetting, updateStoredGestureSettings]
  );

  // Initialize debounce value with current settings
  const { value, setValue, immediateValue } = useDebounceValue(currentNormalizationFactor ?? MAX_NORMALIZATION, {
    delay: UI_CONTROL.DEBOUNCE.DEFAULT_DELAY,
    minDiff: UI_CONTROL.DEBOUNCE.MIN_DIFF,
    onChange: handleSettingsChange,
  });

  // Update value when settings change externally
  useEffect(() => {
    const newValue = currentNormalizationFactor ?? MAX_NORMALIZATION;
    if (newValue !== value) {
      setValue(newValue);
    }
  }, [currentNormalizationFactor, setValue, value]);

  // Memoize the change handler
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(parseFloat(e.target.value));
    },
    [setValue]
  );

  // Memoize progress calculation
  const progress = useMemo(() => {
    return ((immediateValue - MIN_NORMALIZATION) / (MAX_NORMALIZATION - MIN_NORMALIZATION)) * 100;
  }, [immediateValue]);

  // Memoize style object
  const sliderStyle = useMemo(
    () =>
      ({
        '--progress': `${progress}%`,
      }) as React.CSSProperties,
    [progress]
  );

  return (
    <SliderContainer>
      <SliderLabel>
        <span>Sensibilidad</span>
        <span>{(value ?? MAX_NORMALIZATION).toFixed(1)}</span>
      </SliderLabel>
      <SliderWrapper>
        <SliderTrack />
        <Slider type="range" min={MIN_NORMALIZATION} max={MAX_NORMALIZATION} step={STEP} value={immediateValue} onChange={handleChange} style={sliderStyle} />
      </SliderWrapper>
    </SliderContainer>
  );
};

export default React.memo(NormalizationSlider, (prevProps, nextProps) => {
  return prevProps.gesture === nextProps.gesture;
});
