import styled from 'styled-components';
import { device } from '@/Layout/breakpoints';
import { SPACING, HEIGHTS, FLEX, COLORS } from './Home.constants';

export const HomeContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const HomeMain = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  ${device.mobilePortrait} {
    height: calc(100vh - ${SPACING.XS});
    max-height: calc(100vh - ${SPACING.XS});
  }

  ${device.mobileLandscape} {
    width: calc(100vw - ${SPACING.XS});
    height: calc(100vh - ${SPACING.XS});
    max-height: calc(100vh - ${SPACING.XS});
  }
`;

export const HomeHeader = styled.header`
  width: 95%;
  height: ${HEIGHTS.HEADER.MOBILE.CLAMP};
  /* outline: 1px solid ${COLORS.BORDER.DEBUG}; */

  ${device.mobilePortrait} {
    height: ${HEIGHTS.HEADER.MOBILE.CLAMP};
  }

  ${device.mobileLandscape} {
    height: ${HEIGHTS.HEADER.MOBILE.MIN};
  }

  ${device.tabletPortrait} {
    height: ${HEIGHTS.HEADER.TABLET.PORTRAIT.CLAMP};
  }

  ${device.tabletLandscape} {
    height: ${HEIGHTS.HEADER.TABLET.LANDSCAPE.CLAMP};
  }

  ${device.desktopPortrait} {
    height: ${HEIGHTS.HEADER.DESKTOP.PORTRAIT.CLAMP};
  }

  ${device.desktopLandscape} {
    height: ${HEIGHTS.HEADER.DESKTOP.LANDSCAPE.CLAMP};
  }
`;

export const HomeBody = styled.div`
  flex: 1;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  ${device.mobilePortrait} {
    flex-direction: column;
    height: calc(100% - ${HEIGHTS.HEADER.MOBILE.CLAMP});
  }

  ${device.mobileLandscape} {
    flex-direction: row;
  }

  ${device.tabletPortrait} {
    flex-direction: column;
  }

  ${device.tabletLandscape} {
    flex-direction: row;
  }
`;

export const HomeButtons = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;

  ${device.mobilePortrait} {
    flex-direction: row;
  }

  ${device.mobileLandscape} {
    flex-direction: row;
    justify-content: flex-start;
  }

  ${device.tabletPortrait} {
    flex-direction: row;
    justify-content: space-between;
  }

  ${device.tabletLandscape} {
    flex-direction: row;
    justify-content: space-between;
  }

  ${device.desktopPortrait} {
    flex-direction: row;
    justify-content: space-between;
  }

  ${device.desktopLandscape} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const DetectorColumn = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;

  ${device.mobilePortrait} {
    flex: ${FLEX.DETECTOR_COLUMN.MOBILE};
  }

  ${device.mobileLandscape} {
    // Removed margins and background color comments
  }
`;

export const ActionsColumn = styled.div`
  display: flex;
  width: auto;
  height: 100%;

  ${device.mobilePortrait} {
    flex: ${FLEX.ACTIONS_COLUMN.MOBILE};
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  ${device.mobileLandscape} {
    flex: ${FLEX.ACTIONS_COLUMN.TABLET};
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  ${device.tabletPortrait} {
    flex-grow: 1;
    flex-direction: column;
    gap: ${SPACING.XS};
  }

  ${device.tabletLandscape} {
    flex-direction: row;
    flex-grow: 1;
    gap: ${SPACING.XS};
    justify-content: center;
  }

  ${device.desktopPortrait} {
    flex-grow: 1;
    flex-direction: column;
    gap: ${SPACING.XS};
  }

  ${device.desktopLandscape} {
    flex-direction: column;
    flex-grow: 1;
    gap: 0;
    justify-content: center;
    width: 100%;
  }
`;
