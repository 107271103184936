import { useGestureKeyboardStore } from '@/Models/Keyboard';
import { persist, createJSONStorage } from 'zustand/middleware';
import { TEYEGESTURE_TYPE, TGestureSettings } from '@/Models/Gesture/types';
import { defaultGestureDetectionSettings } from '@/Models/Gesture/types';
import { FontSize } from '@/Layout/typography';

import { IUser, defaultDebugSettings } from '@/Models/UserSession/types';

import { TKeyboardSettings } from '@/Models/Keyboard/types';
import { KEYBOARD_TYPE } from '@/Models/Keyboard/types/types';
import { EYEGESTURE_TYPE } from '@/Models/Gesture/types';

import { create } from 'zustand';
import { defaultEyeDetectionSource, IEyeDetectionSource } from '@/Models/Detection/types/index';
import { IUserSettings } from '@/Models/UserSession/types/configuration';

const { HORIZONTAL_SPLIT_SELECTION, VERTICAL_SPLIT_SELECTION, HORIZONTAL_TRACKING_MOUSE } = KEYBOARD_TYPE;
const { LOOK_UP, BLINK } = EYEGESTURE_TYPE;

const defaultKeyboardSettings: TKeyboardSettings = {
  keyboardSpeakGesture: EYEGESTURE_TYPE.LOOK_UP,
  keyFontSize: 'md',
  phraseFontSize: 'md',
  eyeSource: { ...defaultEyeDetectionSource },
  gestureDetection: { ...defaultGestureDetectionSettings },
  isDarkTheme: true,
};

export const defaultUserSettings: IUserSettings = {
  version: 1,
  selectedKeyboard: KEYBOARD_TYPE.VERTICAL_SPLIT_SELECTION,
  selectedKeyboardSettings: { ...defaultKeyboardSettings },
  [KEYBOARD_TYPE.VERTICAL_SPLIT_SELECTION]: { ...defaultKeyboardSettings },
  [KEYBOARD_TYPE.HORIZONTAL_SPLIT_SELECTION]: { ...defaultKeyboardSettings },
  [KEYBOARD_TYPE.HORIZONTAL_TRACKING_MOUSE]: { ...defaultKeyboardSettings },
};

const initialUserState: IUser = {
  authToken: '',
  role: '',
  name: '',
  email: '',
  birthdate: '',
  id: '',
  settings: defaultUserSettings,
  debug: defaultDebugSettings,
};

const resetAllotherStores = () => {
  useGestureKeyboardStore.getState().restore();
};

const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen().catch(e => console.error(e));
  }
};

const releaseWebcam = () => {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(stream => {
        console.log('[STATUS] releaseWebcam', stream);
        stream.getTracks().forEach(track => track.stop());
      })
      .catch(err => console.error('Error releasing webcam:', err));
  }
};

// const validateAndRepairSettings = (settings: IUserSettings): IUserSettings => {
//   const defaultSettings = defaultUserSettings;

//   // Validate version
//   if (typeof settings.version !== 'number') {
//     settings.version = defaultSettings.version;
//   }

//   // Validate selected keyboard
//   if (!Object.values(KEYBOARD_TYPE).includes(settings.selectedKeyboard)) {
//     settings.selectedKeyboard = defaultSettings.selectedKeyboard;
//   }

//   // Helper function to validate keyboard settings
//   const validateKeyboardSettings = (keyboardSettings: TKeyboardSettings): TKeyboardSettings => {
//     const defaultKbSettings = defaultKeyboardSettings;

//     // Helper function to validate font size
//     const validateFontSize = (size: unknown): FontSize => {
//       const validSizes = ['xs', 'sm', 'md', 'lg', 'xl'];
//       return validSizes.includes(size as string) ? (size as FontSize) : defaultKbSettings.keyFontSize;
//     };

//     return {
//       keyboardSpeakGesture: Object.values(EYEGESTURE_TYPE).includes(keyboardSettings?.keyboardSpeakGesture) ? keyboardSettings.keyboardSpeakGesture : defaultKbSettings.keyboardSpeakGesture,
//       keyFontSize: validateFontSize(keyboardSettings?.keyFontSize),
//       phraseFontSize: validateFontSize(keyboardSettings?.phraseFontSize),
//       eyeSource: {
//         leftEye: typeof keyboardSettings?.eyeSource?.leftEye === 'boolean' ? keyboardSettings.eyeSource.leftEye : defaultKbSettings.eyeSource.leftEye,
//         rightEye: typeof keyboardSettings?.eyeSource?.rightEye === 'boolean' ? keyboardSettings.eyeSource.rightEye : defaultKbSettings.eyeSource.rightEye,
//       },
//       gestureDetection: {
//         ...defaultKbSettings.gestureDetection,
//         ...(keyboardSettings?.gestureDetection || {}),
//       },
//       isDarkTheme: typeof keyboardSettings?.isDarkTheme === 'boolean' ? keyboardSettings.isDarkTheme : defaultKbSettings.isDarkTheme,
//     };
//   };

//   // Validate and repair each keyboard configuration
//   Object.values(KEYBOARD_TYPE).forEach(keyboardType => {
//     settings[keyboardType] = validateKeyboardSettings(settings[keyboardType] || {});
//   });

//   // Ensure selectedKeyboardSettings matches the current keyboard settings
//   settings.selectedKeyboardSettings = settings[settings.selectedKeyboard];

//   return settings;
// };

const rehydrateStateCall = async (): Promise<IUser | null> => {
  try {
    const storedState = JSON.parse(localStorage.getItem('user-session-storage') || '{}');

    console.log('[STATUS] rehydrateState: Parsing stored state', storedState);
    if (storedState.state?.user) {
      const user = storedState.state.user;

      // Validate user basic info
      if (!user.id || !user.email) {
        console.warn('[STATUS] rehydrateState: Invalid user data, using default state');
        return null;
      }

      // Validate and repair settings if needed
      // user.settings = validateAndRepairSettings(user.settings || {});

      console.log('[STATUS] rehydrateState: Successfully validated and repaired user state');
      return user;
    }
  } catch (error) {
    console.error('[STATUS] rehydrateState: Error parsing stored state', error);
  }

  console.log('[STATUS] rehydrateState: No valid state found, using default');
  return null;
};

export interface IUserSessionState {
  user: IUser;
  isAuthenticated: boolean;
  updateUserSettings: (settings: IUserSettings) => void;
  getAuthToken: () => string;
  getKeyboardType: () => KEYBOARD_TYPE;
  getSpeakGesture: () => TEYEGESTURE_TYPE;
  setUserSessionState: (user: IUser) => void;
  setKeyboardType: (type: KEYBOARD_TYPE) => void;
  setKeyboardSettings: (settings: TKeyboardSettings) => void;
  setKeyFontSize: (size: FontSize) => void;
  setPhraseFontSize: (size: FontSize) => void;
  setEyeSource: (eyeSource: IEyeDetectionSource) => void;
  setGestureDetectionSettings: (gesture: TEYEGESTURE_TYPE, newGestureSettings: TGestureSettings) => void;
  setKeyboardTheme: (isDark: boolean) => void;
  setGestureEventTracing: (enabled: boolean) => void;
  isGestureEventTracingEnabled: () => boolean;
  logout: () => void;
  rehydrateState: () => Promise<void>;
}

const useUserSessionStore = create<IUserSessionState>()(
  persist(
    (set, get) => ({
      user: { ...initialUserState },
      isAuthenticated: false,
      setUserSessionState: user => {
        set({
          user,
          isAuthenticated: !!user,
        });
      },
      getAuthToken: () => {
        const user = get().user;
        return user?.authToken || '';
      },
      updateUserSettings: settings => {
        const currentUser = get().user;
        if (currentUser) {
          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                ...settings,
              },
            },
          });
        }
      },
      setKeyboardType: (type: KEYBOARD_TYPE) => {
        const currentUser: IUser = get().user;
        if (currentUser) {
          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                selectedKeyboard: type,
                selectedKeyboardSettings: currentUser.settings[type] as TKeyboardSettings,
              },
            },
          });
        }
      },
      getKeyboardType: () => {
        const currentUser = get().user;
        return currentUser?.settings.selectedKeyboard || HORIZONTAL_SPLIT_SELECTION;
      },
      setKeyboardSettings: (settings: TKeyboardSettings) => {
        const currentUser = get().user;
        if (currentUser) {
          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                selectedKeyboardSettings: settings,
                [currentUser.settings.selectedKeyboard]: {
                  ...(currentUser.settings[currentUser.settings.selectedKeyboard] as TKeyboardSettings),
                  ...settings,
                },
              },
            },
          });
        }
      },
      getSpeakGesture: () => {
        const currentUser = get().user;
        return currentUser?.settings.selectedKeyboardSettings.keyboardSpeakGesture;
      },
      setKeyFontSize: (size: FontSize) => {
        const currentUser = get().user;
        if (currentUser) {
          const selectedKeyboard = currentUser.settings.selectedKeyboard;
          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                selectedKeyboardSettings: {
                  ...currentUser.settings.selectedKeyboardSettings,
                  keyFontSize: size,
                },
                [selectedKeyboard]: {
                  ...(currentUser.settings[selectedKeyboard] as TKeyboardSettings),
                  keyFontSize: size,
                },
              },
            },
          });
        }
      },
      setPhraseFontSize: (size: FontSize) => {
        const currentUser = get().user;
        if (currentUser) {
          const selectedKeyboard = currentUser.settings.selectedKeyboard;
          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                selectedKeyboardSettings: {
                  ...currentUser.settings.selectedKeyboardSettings,
                  phraseFontSize: size,
                },
                [selectedKeyboard]: {
                  ...(currentUser.settings[selectedKeyboard] as TKeyboardSettings),
                  phraseFontSize: size,
                },
              },
            },
          });
        }
      },
      setEyeSource: ({ leftEye, rightEye }) => {
        console.log('[STATUS] setEyeSource', leftEye, rightEye);
        const currentUser = get().user;
        if (currentUser) {
          const currentKeyboard = currentUser.settings.selectedKeyboard;
          const settings = currentUser.settings[currentKeyboard] as TKeyboardSettings;

          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                selectedKeyboardSettings: {
                  ...settings,
                  eyeSource: {
                    ...settings.eyeSource,
                    leftEye,
                    rightEye,
                  },
                },
                [currentKeyboard]: {
                  ...settings,
                  eyeSource: {
                    ...settings.eyeSource,
                    leftEye,
                    rightEye,
                  },
                },
              },
            },
          });
        }
      },
      setGestureDetectionSettings: (gesture: TEYEGESTURE_TYPE, newGestureSettings: TGestureSettings) => {
        console.log('[STATUS] setGestureDetectionSettings', gesture, newGestureSettings);
        const currentUser = get().user;
        if (currentUser) {
          const currentKeyboard = currentUser.settings.selectedKeyboard;
          const settings = currentUser.settings[currentKeyboard] as TKeyboardSettings;

          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                selectedKeyboardSettings: {
                  ...settings,
                  gestureDetection: {
                    ...settings.gestureDetection,
                    [gesture]: {
                      ...settings.gestureDetection[gesture],
                      ...newGestureSettings,
                    },
                  },
                },
                [currentKeyboard]: {
                  ...settings,
                  gestureDetection: {
                    ...settings.gestureDetection,
                    [gesture]: {
                      ...settings.gestureDetection[gesture],
                      ...newGestureSettings,
                    },
                  },
                },
              },
            },
          });
        }
      },
      setKeyboardTheme: (isDark: boolean) => {
        const currentUser = get().user;
        if (currentUser) {
          const settings = currentUser.settings;
          const currentKeyboardType = settings.selectedKeyboard;

          const updatedSettings = {
            ...settings.selectedKeyboardSettings,
            isDarkTheme: isDark,
          } as TKeyboardSettings;

          set({
            user: {
              ...currentUser,
              settings: {
                ...settings,
                selectedKeyboardSettings: updatedSettings,
                [currentKeyboardType]: updatedSettings,
              },
            },
          });
        }
      },
      logout: () => {
        set({
          user: initialUserState,
          isAuthenticated: false,
        });
        resetAllotherStores();
        exitFullscreen();
        localStorage.removeItem('user-session-storage');
        releaseWebcam();
        //refresh window
        window.location.reload();
      },
      rehydrateState: async () => {
        const user = await rehydrateStateCall();
        if (user) {
          set({
            user,
            isAuthenticated: true,
          });
        }
      },
      setGestureEventTracing: (enabled: boolean) => {
        const currentUser = get().user;
        set({
          user: {
            // Si el usuario no existe, usar initialUserState
            ...(currentUser ?? initialUserState),
            debug: {
              // Si debug no existe, usar defaultDebugSettings
              ...(currentUser?.debug ?? defaultDebugSettings),
              traceGestureEvents: enabled,
            },
          },
        });
      },

      isGestureEventTracingEnabled: () => {
        const user = get().user;
        // Si el usuario no está definido o no tiene debug settings, usar el valor por defecto
        return user?.debug?.traceGestureEvents ?? defaultDebugSettings.traceGestureEvents;
      },
    }),
    {
      name: 'user-session-storage',
      storage: createJSONStorage(() => localStorage),
      skipHydration: true,
    }
  )
);

export default useUserSessionStore;
