import { EYEGESTURE_TYPE, TEYEGESTURE_TYPE } from './gestureEnums';
import { TGestureSettings } from './gestureTypes';

export const GestureLabels: Record<TEYEGESTURE_TYPE, string> = {
  [EYEGESTURE_TYPE.LOOK_LEFT]: 'Mirar Izquierda',
  [EYEGESTURE_TYPE.LOOK_RIGHT]: 'Mirar Derecha',
  [EYEGESTURE_TYPE.LOOK_UP]: 'Mirar Arriba',
  [EYEGESTURE_TYPE.LOOK_DOWN]: 'Mirar Abajo',
  [EYEGESTURE_TYPE.BLINK]: 'Pestañear',
};

/**
 * Configuración por defecto para los gestos
 */
export const defaultGestureSettings: TGestureSettings = {
  ignoreThreshold: 0.3,
  confirmThreshold: 0.5,
  confirmTime: 1000,
  cooldownTime: 500,
  normalizationFactor: 0.7,
  historyDepth: 10, // Valor por defecto para el historial
};
