import React from 'react';
import { LogoWrapper, LogoImage } from './Logo.styles';
import logo from '@/Assets/images/logo.svg';

const Logo = () => (
  <LogoWrapper>
    <LogoImage alt="logo" src={logo} id="login-logo" />
  </LogoWrapper>
);

export default Logo;
