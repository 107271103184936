import { TFontSize } from '@/Models/UserSession';
import { TEYEGESTURE_TYPE, EYEGESTURE_TYPE, TGestureDetectionSettings } from '@/Models/Gesture';
import { IEyeDetectionSource } from '@/Models/Detection/types/domain';
import { KEYBOARD_TYPE } from '@/Models/Keyboard/types/types';

const { VERTICAL_SPLIT_SELECTION, HORIZONTAL_SPLIT_SELECTION, HORIZONTAL_TRACKING_MOUSE } = KEYBOARD_TYPE;

const { BLINK, LOOK_UP, LOOK_DOWN, LOOK_LEFT, LOOK_RIGHT } = EYEGESTURE_TYPE;

export const KeyboardTypeLabels: Record<KEYBOARD_TYPE, string> = {
  [VERTICAL_SPLIT_SELECTION]: 'Teclado Dividido Vertical',
  [HORIZONTAL_SPLIT_SELECTION]: 'Teclado Dividido Horizontal',
  [HORIZONTAL_TRACKING_MOUSE]: 'Teclado con reconocimiento de Rostro',
};

/**
 * Mapeo de gestos permitidos para cada tipo de teclado
 */
export const KEYBOARD_AVAILABLE_SPEAK_GESTURES: Record<KEYBOARD_TYPE, TEYEGESTURE_TYPE[]> = {
  [VERTICAL_SPLIT_SELECTION]: [BLINK],
  [HORIZONTAL_SPLIT_SELECTION]: [LOOK_UP, LOOK_DOWN, BLINK],
  [HORIZONTAL_TRACKING_MOUSE]: [],
};

export const KEYBOARD_AVAILABLE_GESTURES: Record<KEYBOARD_TYPE, TEYEGESTURE_TYPE[]> = {
  [VERTICAL_SPLIT_SELECTION]: [LOOK_DOWN, LOOK_UP, BLINK],
  [HORIZONTAL_SPLIT_SELECTION]: [LOOK_LEFT, LOOK_RIGHT, LOOK_UP, LOOK_DOWN, BLINK],
  [HORIZONTAL_TRACKING_MOUSE]: [],
};

/**
 * Settings specific to keyboard configuration including gesture controls,
 * visual preferences and detection settings
 */
export type TKeyboardSettings = {
  keyboardSpeakGesture: TEYEGESTURE_TYPE;
  keyFontSize: TFontSize;
  phraseFontSize: TFontSize;
  eyeSource: IEyeDetectionSource;
  gestureDetection: TGestureDetectionSettings;
  isDarkTheme: boolean;
};
