import React from 'react';
import { getDeviceType, DeviceType } from '@/Layout/breakpoints';
import useGestureKeyboardStore from '@/Models/Keyboard/store/useGestureKeyboardStore';

export const useOrientationCheck = () => {
  const [isLandscape, setIsLandscape] = React.useState<boolean>(window.innerWidth > window.innerHeight);
  const [deviceType, setDeviceType] = React.useState<DeviceType>(getDeviceType(window.innerWidth, window.innerHeight));

  const { setDevice, setOrientation } = useGestureKeyboardStore();

  React.useEffect(() => {
    const checkDeviceAndOrientation = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const isLandscapeNow = width > height;

      // Update orientation state
      setIsLandscape(isLandscapeNow);
      setOrientation(isLandscapeNow ? 'LANDSCAPE' : 'PORTRAIT');

      // Update device type
      const newDeviceType = getDeviceType(width, height);
      setDeviceType(newDeviceType);
      setDevice(newDeviceType);

      // Log para debugging
      console.log(`Device: ${newDeviceType}, Orientation: ${isLandscapeNow ? 'LANDSCAPE' : 'PORTRAIT'}, Resolution: ${width}x${height}`);
    };

    // Initial check
    checkDeviceAndOrientation();

    // Add event listeners
    window.addEventListener('resize', checkDeviceAndOrientation);
    window.addEventListener('orientationchange', checkDeviceAndOrientation);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkDeviceAndOrientation);
      window.removeEventListener('orientationchange', checkDeviceAndOrientation);
    };
  }, [setOrientation, setDevice]);

  return { isLandscape, deviceType };
};

export default useOrientationCheck;
