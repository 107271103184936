import styled from 'styled-components';
import { Form as FormikForm, Field as FormikField, Formik } from 'formik';
import { device } from '@/Layout/breakpoints';

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  /* outline: 1px solid black; */

  ${device.mobileLandscape} {
    flex-direction: row;
    justify-content: flex-start;

    /* background: rgba(124, 24, 0, 0.5); */
  }

  ${device.tabletLandscape} {
    /* background: rgba(255, 0, 0, 1); */
    flex-direction: row;
    justify-content: flex-start;
    /* align-items: flex-start; */
  }

  /* ${device.tabletPortrait} { */
  /* background: rgba(124, 255, 0, 1); */
  /* } */

  ${device.desktopPortrait} {
    /* background: rgba(0, 255, 255, 1); */
  }

  ${device.desktopLandscape} {
    /* background: rgba(0, 255, 255, 1); */
  }

  /* background: blue; */
`;

export const LoginHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 30vh;
  width: 100vw;
  /* background: rgba(0, 0, 0, 1); */

  ${device.mobilePortrait} {
    /* background: rgba(124, 24, 0, 1); */
    height: 25vh;
  }
  ${device.mobileLandscape} {
    /* background: rgba(0, 0, 0, 1); */
    width: 40vw;
    height: 100vh;
    /* flex-direction: row; */
  }

  ${device.tabletPortrait} {
    /* outline: 1px solid black; */
    height: 30vh;
  }
  ${device.tabletLandscape} {
    width: 40vw;
    height: 100vh;
    /* background-color: rgba(0, 0, 0, 1); */
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  /* margin: 20px 45px; */
  justify-content: space-around;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  /* background: rgba(124, 0, 124, 1); */

  ${device.mobilePortrait} {
    /* background: rgba(124, 24, 0, 0.5); */
  }

  ${device.mobileLandscape} {
    /* background: rgba(0, 0, 124, 0.5); */
    height: 100vh;
    width: 60vw;
  }

  ${device.tabletPortrait} {
    /* background: rgba(0, 0, 244, 0.5); */
    width: 100vw;
  }

  ${device.tabletLandscape} {
    /* background: rgba(0, 0, 244, 0.5); */
    width: 60vw;
    align-items: flex-start;
  }

  ${device.desktopPortrait} {
    /* background: rgba(0, 0, 255, 1); */
  }
  ${device.desktopLandscape} {
    margin-top: 2em;
    /* background: rgba(0, 0, 255, 1); */
    width: 100vw;
  }
`;

export const StyledForm = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width: 300px; */
  /* gap: 1rem; */

  ${device.mobilePortrait} {
    /* background: rgba(12, 0, 224, 1); */
    width: calc(90vw - 2em);
  }

  ${device.mobileLandscape} {
    /* background: rgba(12, 0, 224, 1); */
    width: 80%;
  }

  ${device.tabletPortrait} {
    /* background: rgba(255, 0, 0, 1); */
    width: calc(70vw - 2em);
  }

  ${device.tabletLandscape} {
    /* background: rgba(12, 0, 224, 1); */
    width: 80%;
  }

  ${device.desktopPortrait} {
    /* background: rgba(0, 0, 255, 1); */
  }
  ${device.desktopLandscape} {
    /* background: rgba(0, 0, 255, 1); */
    width: 40vw;
    max-width: 640px;
  }
`;

export const FormGroup = styled.div`
  /* margin-top: 45px; */
  margin-bottom: 1em;

  ${device.mobilePortrait} {
    /* margin-top: 1em; */
    width: 100%;
    /* outline: 1px solid greenyellow; */
    margin-bottom: 1em;
  }

  ${device.mobileLandscape} {
    /* margin-top: 1em; */
    width: 100%;
    /* outline: 1px solid greenyellow; */
    margin-bottom: 1em;
  }
`;

export const Label = styled.label`
  display: block;
  font-weight: bolder;
  padding-bottom: 0.1em;
  font-size: 1.2em;
  color: white;
  letter-spacing: 1px;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
  /* outline: 1px solid red; */
  width: 100%;
  text-align: left;

  ${device.mobilePortrait} {
    /* font-size: 0.1em; */
  }
  ${device.mobileLandscape} {
    /* font-size: 0.1em; */
    padding-bottom: 0;
  }
`;

export const Field = styled(FormikField)<{ email?: boolean }>`
  width: 100%;
  padding: 1em 0.5em;
  border-radius: 5px;
  font-size: 1em;
  outline: 2px solid transparent;
  border: none;
  transition: all 0.3s ease;
  background-color: var(--dark-text-color);

  &[aria-invalid='true'] {
    outline-color: #ff0000;
    color: #ff0000;
    box-shadow: 0 0 3px 5px rgba(255, 0, 0, 0.8);
  }

  ${device.mobilePortrait} {
    /* min-width: 80vw; */
  }

  ${device.mobileLandscape} {
    padding: 0.75em 0.5em 0.5em;
    font-size: 1em;
  }
`;

export const LoginButton = styled.button`
  width: 100%;
  padding: 1.3em 0.5em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 1em;
  transition: all 1s ease;
  text-transform: uppercase;
  letter-spacing: 2px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #007bff2e;
    outline: 2px solid gray;
    cursor: not-allowed;
    color: gray;
    opacity: 0.3;
  }
`;
