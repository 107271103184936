import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import useUserSessionStore from '@/Models/useUserSessionStore';
import NotFoundPage from './Components/NotFound/NotFoundPage';
import Home from './Sections/Home/Home';
import Login from './Sections/Login/Login';
import { useEffect } from 'react';
import useOrientationCheck from './Components/useOrientationCheck/useOrientationCheck';
import MediaQueryHelper from '@/Components/MediaQueryHelper/MediaQueryHelper';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from './theme/defaultTheme';

function App() {
  const { isAuthenticated, rehydrateState } = useUserSessionStore();
  useOrientationCheck();

  useEffect(() => {
    rehydrateState();
  }, [rehydrateState]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/home" />} />
          <Route path="/home" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
          <Route path="/" element={isAuthenticated ? <Home /> : <Login />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <MediaQueryHelper />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
