import { TGestureSettings } from '../base/gestureTypes';

export const GESTUREMACHINE_STATE = {
  IDLE: 'idle',
  ACTIVE: 'active',
  CONFIRMING: 'confirming',
  COMPLETED: 'completed',
} as const;

export type TGESTUREMACHINE_STATE = (typeof GESTUREMACHINE_STATE)[keyof typeof GESTUREMACHINE_STATE];

/**
 * Context for the gesture state machine
 */
export interface IGestureMachineContext {
  initial: TGestureSettings;
  settings: TGestureSettings;
  confirmationStartTime: number;
  cooldownStartTime: number;
}

/**
 * Schema for the gesture state machine states
 */
export interface IGestureStateSchema {
  states: {
    [GESTUREMACHINE_STATE.IDLE]: {};
    [GESTUREMACHINE_STATE.ACTIVE]: {};
    [GESTUREMACHINE_STATE.CONFIRMING]: {};
    [GESTUREMACHINE_STATE.COMPLETED]: {};
  };
}

/**
 * Type that defines possible states and their metadata
 */
export type TGestureTypestate = {
  value: TGESTUREMACHINE_STATE;
  context: IGestureMachineContext;
};
