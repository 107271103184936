import React from 'react';
import styled from 'styled-components';
import DetectionFeedBack from '@/Components/Detector/DetectionFeedBack';
import { PauseButton } from '@/Components/PauseButton';
import { device } from '@/Layout/breakpoints';

// Estilos específicos para la preview
const PreviewContainer = styled.div`
  position: relative;
  aspect-ratio: 4/3;
  width: 100%;
  max-width: 240px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  outline: 4px solid rgba(255, 255, 255, 0.7);

  /* margin: 0 auto; */

  // Mobile portrait
  ${device.mobilePortrait} {
    max-width: 180px;
    margin: 0;
  }

  // Mobile landscape
  ${device.mobileLandscape} {
    max-width: 160px;
    margin: 0;
  }

  // Tablet portrait
  ${device.tabletPortrait} {
    max-width: 200px;
    margin: 0;
  }

  // Tablet landscape
  ${device.tabletLandscape} {
    max-width: 220px;
    margin: 0 auto;
  }

  // Desktop portrait
  ${device.desktopPortrait} {
    max-width: 240px;
    margin: 0 auto;
  }

  // Desktop landscape
  ${device.desktopLandscape} {
    max-width: 240px;
    margin: 0 auto;
  }
`;

interface DetectionControlProps {
  id?: string;
}

const DetectionControl: React.FC<DetectionControlProps> = ({ id }) => {
  return (
    <PreviewContainer id={id}>
      <DetectionFeedBack />
      <PauseButton size="large" />
    </PreviewContainer>
  );
};

export default DetectionControl;
