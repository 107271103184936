import React, { useEffect, useCallback } from 'react';
import { EYEGESTURE_TYPE } from '@/Models/Gesture';
import Check from '@/Components/GestureFlag/Check/Check';
import PhraseContainer from '@/Components/GestureKeyboard/PhraseContainer/PhraseContainer';
import Speak from '@/Components/GestureFlag/Speak/Speak';

import { KeyboardKey } from '../components/KeyboardKey/KeyboardKey';
import { KeyboardContainer, KeyboardSection, KeyboardSections, KeyboardKeys, SectionSeparator } from '../Keyboard.styles';
import useTextToSpeech from '@/Services/useTextToSpeech';
import useGestureKeyboardStore from '@/Models/Keyboard/store/useGestureKeyboardStore';

import { GESTUREMACHINE_STATE, useGestureState } from '@/Models/Gesture';

import { COMMANDS } from '@/Models/UserSession/types/domain';
import useUserSessionStore from '@/Models/useUserSessionStore';
import useConfigurationStore from '@/Models/UserSession/store/useConfigurationStore';
const { LOOK_UP, LOOK_DOWN } = EYEGESTURE_TYPE;

export const KeyboardVerticalSplit: React.FC = () => {
  // const { left, right, currentGesture, init, pick } = useGestureKeyboardStore();

  const left = useGestureKeyboardStore(state => state.left);
  const right = useGestureKeyboardStore(state => state.right);
  const currentGesture = useGestureKeyboardStore(state => state.currentGesture);
  const init = useGestureKeyboardStore(state => state.init);
  const pick = useGestureKeyboardStore(state => state.pick);
  const clearKeyboardGesture = useGestureKeyboardStore(useCallback(state => state.clearKeyboardGesture, []));
  const keyboardSpeakGesture = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.keyboardSpeakGesture);
  const configModalOpen = useConfigurationStore(state => state.configModalOpen);

  // Get the current state of each gesture using the useGestureState hook
  const topGestureState = useGestureState(LOOK_UP);
  const bottomGestureState = useGestureState(LOOK_DOWN);

  // Initialize flags based on gesture states
  const isTopActive = topGestureState === GESTUREMACHINE_STATE.ACTIVE || topGestureState === GESTUREMACHINE_STATE.CONFIRMING,
    isBottomActive = bottomGestureState === GESTUREMACHINE_STATE.ACTIVE || bottomGestureState === GESTUREMACHINE_STATE.CONFIRMING,
    isTopIdle = topGestureState === GESTUREMACHINE_STATE.IDLE,
    isBottomIdle = bottomGestureState === GESTUREMACHINE_STATE.IDLE;

  useEffect(() => {
    init();
  }, [init]);

  const pickTop = useCallback(() => {
    pick(left.keys, left.commands);
  }, [left.keys, left.commands, pick]);

  const pickBottom = useCallback(() => {
    pick(right.keys, right.commands);
  }, [right.keys, right.commands, pick]);

  useEffect(() => {
    if (configModalOpen) return;
    if (currentGesture === LOOK_UP) {
      pickTop();
    } else if (currentGesture === LOOK_DOWN) {
      pickBottom();
    } else if (currentGesture === keyboardSpeakGesture) {
      handleSynthesis(phrase);
      clearKeyboardGesture();
    }
  }, [currentGesture, pickTop, pickBottom, open, configModalOpen]);

  const reversedRightSideKeys = [...right.keys];
  const topSideKeys = left.commands.concat(left.keys);
  const bottomSideKeys = right.commands.concat(reversedRightSideKeys);

  // Usar selectores específicos para mejor rendimiento
  const phrase = useGestureKeyboardStore(state => state.phrase);
  const pickKey = useGestureKeyboardStore(state => state.pickKey);
  const { handleSynthesis } = useTextToSpeech();

  const handleClick = (key: string) => {
    if (key === COMMANDS.SPEAK) {
      handleSynthesis(phrase);
    } else {
      pickKey(key);
    }
  };

  return (
    <KeyboardContainer id="vertical-keyboard">
      <KeyboardSections id="keyboard-sections" $layout="vertical">
        <KeyboardSection id="top-section" $isTop $isActive={isTopActive} $isIdle={isTopIdle}>
          <Check gesture={LOOK_UP} />
          <KeyboardKeys id="top-keys" $layout="vertical">
            {topSideKeys.map((key: string) => (
              <KeyboardKey key={key} value={key} onClick={handleClick} />
            ))}
          </KeyboardKeys>
        </KeyboardSection>

        <SectionSeparator id="section-separator">
          <PhraseContainer />
          <Speak />
        </SectionSeparator>

        <KeyboardSection id="bottom-section" $isBottom $isActive={isBottomActive} $isIdle={isBottomIdle}>
          <KeyboardKeys id="bottom-keys" $layout="vertical">
            <Check gesture={LOOK_DOWN} />
            {bottomSideKeys.map((key: string) => (
              <KeyboardKey key={key} value={key} onClick={handleClick} />
            ))}
          </KeyboardKeys>
        </KeyboardSection>
      </KeyboardSections>
    </KeyboardContainer>
  );
};
