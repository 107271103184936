import React from 'react';
import { TEYEGESTURE_TYPE } from './gestureEnums';

export interface IGestureScore {
  value: number;
  timestamp: number;
}

/**
 * Constantes para las claves de la configuración de gestos
 * Permite referenciar las propiedades de TGestureSettings de manera segura
 */
export const GESTURE_SETTING_KEYS = {
  IGNORE_THRESHOLD: 'ignoreThreshold' as const,
  CONFIRM_THRESHOLD: 'confirmThreshold' as const,
  CONFIRM_TIME: 'confirmTime' as const,
  COOLDOWN_TIME: 'cooldownTime' as const,
  NORMALIZATION_FACTOR: 'normalizationFactor' as const,
  HISTORY_DEPTH: 'historyDepth' as const,
} as const;

export type TGestureScores = Record<TEYEGESTURE_TYPE, number>;
export type TGestureFlags = Record<TEYEGESTURE_TYPE, boolean>;

/**
 * Configuración base para los gestos
 */
export type TGestureSettings = {
  [GESTURE_SETTING_KEYS.IGNORE_THRESHOLD]: number;
  [GESTURE_SETTING_KEYS.CONFIRM_THRESHOLD]: number;
  [GESTURE_SETTING_KEYS.CONFIRM_TIME]: number;
  [GESTURE_SETTING_KEYS.COOLDOWN_TIME]: number;
  [GESTURE_SETTING_KEYS.NORMALIZATION_FACTOR]: number;
  [GESTURE_SETTING_KEYS.HISTORY_DEPTH]: number; // Hacemos esta propiedad requerida
};

/**
 * Tipo para las claves de la configuración de gestos
 */
export type TGESTURE_SETTING_KEYS = keyof TGestureSettings;

export const defaultGestureScores: TGestureScores = {
  look_up: 0,
  look_down: 0,
  look_left: 0,
  look_right: 0,
  blink: 0,
};

export const defaultGestureFlags: TGestureFlags = {
  look_up: false,
  look_down: false,
  look_left: false,
  look_right: false,
  blink: false,
};
