// API Configuration
const API_URL = process.env.REACT_APP_API;
const ENV = process.env.REACT_APP_ENV;

if (!API_URL) {
  throw new Error('REACT_APP_API no está configurada en el archivo .env');
}

// Configurar la URL de la API según el entorno
let FULL_API_URL: string;

if (ENV === 'development' && API_URL.includes('192.168.')) {
  // En desarrollo local, usar HTTPS con la IP local
  FULL_API_URL = `https://${API_URL}`;
} else if (ENV === 'development') {
  // En desarrollo (no local), usar HTTPS con la API de desarrollo
  FULL_API_URL = `https://${API_URL}`;
} else {
  // En producción, usar HTTPS con la API de producción
  FULL_API_URL = `https://${API_URL}`;
}

export default FULL_API_URL;
