import { EYEGESTURE_TYPE, TGestureFlags, TGestureScores } from '../Gesture';

const { LOOK_UP, LOOK_DOWN, LOOK_LEFT, LOOK_RIGHT, BLINK } = EYEGESTURE_TYPE;
/**
 * Type definition for score tracking state
 * Tracks historic, maximum, normalized, and last scores for eye gestures
 * Also includes flags for ignoring, active state, and confirmation status
 */
export type TScoreTrackingState = {
  /** Array of historic score records */
  historic: TGestureScores[];

  /** Maximum scores recorded for each gesture type */
  max: TGestureScores;

  /** Normalized scores for each gesture type */
  normalized: TGestureScores;

  /** Last recorded scores for each gesture type */
  last: TGestureScores;

  /** Flags indicating which gestures should be ignored */
  ignore: TGestureFlags;

  /** Flags indicating which gestures are currently active */
  active: TGestureFlags;

  /** Flags indicating which gestures have been confirmed */
  confirmed: TGestureFlags;
};

/**
 * Default empty state for score tracking
 */
export const EmptyScoreTrackingState: TScoreTrackingState = {
  historic: [],
  max: {
    [LOOK_UP]: 0,
    [LOOK_DOWN]: 0,
    [LOOK_LEFT]: 0,
    [LOOK_RIGHT]: 0,
    [BLINK]: 0,
  },
  normalized: {
    [LOOK_UP]: 0,
    [LOOK_DOWN]: 0,
    [LOOK_LEFT]: 0,
    [LOOK_RIGHT]: 0,
    [BLINK]: 0,
  },
  last: {
    [LOOK_UP]: 0,
    [LOOK_DOWN]: 0,
    [LOOK_LEFT]: 0,
    [LOOK_RIGHT]: 0,
    [BLINK]: 0,
  },
  ignore: {
    [LOOK_UP]: false,
    [LOOK_DOWN]: false,
    [LOOK_LEFT]: false,
    [LOOK_RIGHT]: false,
    [BLINK]: false,
  },
  active: {
    [LOOK_UP]: false,
    [LOOK_DOWN]: false,
    [LOOK_LEFT]: false,
    [LOOK_RIGHT]: false,
    [BLINK]: false,
  },
  confirmed: {
    [LOOK_UP]: false,
    [LOOK_DOWN]: false,
    [LOOK_LEFT]: false,
    [LOOK_RIGHT]: false,
    [BLINK]: false,
  },
};
