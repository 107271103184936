import React from 'react';
import { StyledPauseButton } from './PauseButton.styles';
import { useDetectionStore } from '@/Models/Detection';

interface PauseButtonProps {
  size?: 'small' | 'large';
  className?: string;
}

export const PauseButton: React.FC<PauseButtonProps> = ({ size = 'large', className }) => {
  const { paused, setPaused } = useDetectionStore();

  const togglePause = () => {
    setPaused(!paused);
  };

  return (
    <StyledPauseButton onClick={togglePause} title={paused ? 'Reanudar' : 'Pausar'} $size={size} className={className}>
      {paused ? '▶' : '⏸'}
    </StyledPauseButton>
  );
};
