import React, { useCallback, useMemo } from 'react';
import { COMMANDS, KEY_LIST, LEFT_COMMANDS, RIGHT_COMMANDS } from '@/Models/UserSession/types/domain';
import useGestureKeyboardStore from '@/Models/Keyboard/store/useGestureKeyboardStore';

import useTextToSpeech from '@/Services/useTextToSpeech';
import PhraseContainer from '@/Components/GestureKeyboard/PhraseContainer/PhraseContainer';
import { KeyboardKey } from '../components/KeyboardKey/KeyboardKey';
import { KeyboardKeys, CommandsRow } from './KeyboardWithTracking.styles';
import { KeyboardContainer, KeyboardSections } from '../Keyboard.styles';

const KeyboardRow = React.memo(({ keys, onKeyClick }: { keys: string[]; onKeyClick: (key: string) => void }) => (
  <>
    {keys.map(key => (
      <KeyboardKey key={key} value={key} onClick={() => onKeyClick(key)} />
    ))}
  </>
));

KeyboardRow.displayName = 'KeyboardRow';

export const KeyboardWithTracking: React.FC = () => {
  const phrase = useGestureKeyboardStore(state => state.phrase);
  const pickKey = useGestureKeyboardStore(state => state.pickKey);
  const { handleSynthesis } = useTextToSpeech();

  const handleClick = useCallback(
    (key: string) => {
      if (key === COMMANDS.SPEAK) {
        handleSynthesis(phrase);
      } else {
        pickKey(key);
      }
    },
    [handleSynthesis, phrase, pickKey]
  );

  const memoizedCommands = useMemo(
    () => ({
      left: LEFT_COMMANDS,
      right: RIGHT_COMMANDS,
    }),
    []
  );

  return (
    <KeyboardContainer id="tracking-keyboard">
      <PhraseContainer />
      <KeyboardSections $layout="horizontal">
        <KeyboardKeys>
          <KeyboardRow keys={KEY_LIST} onKeyClick={handleClick} />
        </KeyboardKeys>
        <CommandsRow>
          <KeyboardRow keys={memoizedCommands.left} onKeyClick={handleClick} />
          <KeyboardRow keys={memoizedCommands.right} onKeyClick={handleClick} />
        </CommandsRow>
      </KeyboardSections>
    </KeyboardContainer>
  );
};
