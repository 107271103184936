import { TGestureSettings, TGestureScores, TGestureFlags } from '../base/gestureTypes';
import { TEYEGESTURE_TYPE } from '../base/gestureEnums';
import { defaultGestureSettings } from '../base/gestureConstants';

/**
 * Tipo que define la configuración para todos los gestos soportados.
 * Cada tipo de gesto debe tener su propia configuración de umbrales y tiempos.
 *
 * @example
 * const settings: TGestureDetectionSettings = {
 *   look_up: {
 *     ignoreThreshold: 0.3,
 *     confirmThreshold: 0.5,
 *     confirmTime: 500,
 *     cooldownTime: 1000,
 *     confirmationStartTime: 0,
 *     normalizationFactor: 0.7
 *   },
 *   // ... configuración para otros gestos
 * };
 */
export type TGestureDetectionSettings = {
  [key in TEYEGESTURE_TYPE]: TGestureSettings;
};

/**
 * Configuración por defecto para todos los tipos de gestos.
 * Utiliza los mismos valores base para cada tipo de gesto.
 */
export const defaultGestureDetectionSettings: TGestureDetectionSettings = {
  look_up: { ...defaultGestureSettings },
  look_down: { ...defaultGestureSettings },
  look_left: { ...defaultGestureSettings },
  look_right: { ...defaultGestureSettings },
  blink: {
    ...defaultGestureSettings,
    // Valores específicos para el parpadeo
    confirmTime: 2000, // Más rápido para el parpadeo
    cooldownTime: 500, // Menor tiempo de espera entre parpadeos
  },
};

export interface IScoreTrackingState {
  historic: TGestureScores[];
  max: TGestureScores;
  normalized: TGestureScores;
  last: TGestureScores;
  ignore: TGestureFlags;
  active: TGestureFlags;
  confirmed: TGestureFlags;
}
