import styled from 'styled-components';
import Webcam from 'react-webcam';
import { device } from '@/Layout/breakpoints';

export const DetectionContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* outline: 3px solid rgba(255, 10, 255, 1); */
  overflow: hidden;
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;

  /* Mobile portrait */
  ${device.mobilePortrait} {
    width: calc(100% - 1.3em);
    height: calc(100% - 1.3em);
    /* min-height: 160px;
    max-height: 240px; */
    /* outline: purple solid 1px; */
  }

  /* Mobile landscape */
  ${device.mobileLandscape} {
    /* width: 160px;
    height: 120px;
    min-width: 160px; */
    width: auto;
    /* height: calc(100% - 0.5em); */
  }

  /* Tablet y Desktop */
  ${device.tabletPortrait} {
    width: 13vw;
    min-width: 200px;
    aspect-ratio: 4/3;
  }
`;

export const StyledWebcam = styled(Webcam)`
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  width: 100%;
  height: auto;
  border-radius: 10px;
  flex: 1;
`;

export const DetectorCanvas = styled.canvas<{ $paused: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  width: 100%;
  transform: scaleX(-1);
  height: auto;
  display: ${props => (props.$paused ? 'none' : 'block')};
`;

export const CounterContainer = styled.div`
  font-size: 10px;
  font-family: 'JetBrains Mono', 'Monaco', 'Consolas', 'Ubuntu Mono', monospace;
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 3px;
  border-radius: 5px;
`;

export const PauseButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;

  ${device.mobileLandscape} {
    display: none;
  }
  ${device.tabletLandscape} {
    display: none;
  }
`;
