import { defaultEyeDetectionSource } from '@/Models/Detection/types/index';
import { defaultGestureDetectionSettings, EYEGESTURE_TYPE } from '@/Models/Gesture';
import { TKeyboardSettings } from '@/Models/Keyboard/types/domain';
import { KEYBOARD_TYPE } from '@/Models/Keyboard/types/types';
import { DEFAULT_FONT_SIZES } from '@/Models/UserSession';
console.log('DEBUG KEYBOARD_TYPE:', KEYBOARD_TYPE);

const { BLINK, LOOK_UP } = EYEGESTURE_TYPE;
const { KEY_FONT_SIZE, PHRASE_FONT_SIZE } = DEFAULT_FONT_SIZES;
const { VERTICAL_SPLIT_SELECTION, HORIZONTAL_SPLIT_SELECTION, HORIZONTAL_TRACKING_MOUSE } = KEYBOARD_TYPE;

/**
 * User settings interface that includes keyboard preferences and configurations
 * Extends settings for each keyboard type with specific keyboard settings
 */
export interface IUserSettings {
  navigation?: unknown;
  id?: string;
  keyboardDistribution?: string;
  keyboardInputMode?: string;
  version: number;
  selectedKeyboard: KEYBOARD_TYPE;
  selectedKeyboardSettings: TKeyboardSettings;
  isLandscape?: boolean;
  // Keyboard settings for each keyboard type
  [keyboardType: string]: unknown;
}
export const defaultUserSettings: IUserSettings = {
  version: 1,
  selectedKeyboard: VERTICAL_SPLIT_SELECTION,
  selectedKeyboardSettings: {
    keyboardSpeakGesture: LOOK_UP,
    keyFontSize: KEY_FONT_SIZE,
    phraseFontSize: PHRASE_FONT_SIZE,
    eyeSource: { ...defaultEyeDetectionSource },
    gestureDetection: { ...defaultGestureDetectionSettings },
    isDarkTheme: false,
  },
  [VERTICAL_SPLIT_SELECTION]: {
    keyboardSpeakGesture: BLINK,
    keyFontSize: KEY_FONT_SIZE,
    phraseFontSize: PHRASE_FONT_SIZE,
    eyeSource: { ...defaultEyeDetectionSource },
    gestureDetection: { ...defaultGestureDetectionSettings },
    isDarkTheme: false,
  },
  [HORIZONTAL_SPLIT_SELECTION]: {
    keyboardSpeakGesture: LOOK_UP,
    keyFontSize: KEY_FONT_SIZE,
    phraseFontSize: PHRASE_FONT_SIZE,
    eyeSource: { ...defaultEyeDetectionSource },
    gestureDetection: { ...defaultGestureDetectionSettings },
    isDarkTheme: false,
  },
  [HORIZONTAL_TRACKING_MOUSE]: {
    keyboardSpeakGesture: LOOK_UP,
    keyFontSize: KEY_FONT_SIZE,
    phraseFontSize: PHRASE_FONT_SIZE,
    eyeSource: { ...defaultEyeDetectionSource },
    gestureDetection: { ...defaultGestureDetectionSettings },
    isDarkTheme: false,
  },
};
