import React, { useCallback, useState, useEffect, useRef } from 'react';
import { UI_CONTROL } from '@/Models/UserSession/types/domain';
import { useGestureSettingsUpdate, useGestureSetting, GESTURE_SETTING_KEYS, TEYEGESTURE_TYPE, TGESTURE_SETTING_KEYS } from '@/Models/Gesture';
import { RangeContainer, RangeLabel, RangeSlider, SliderTrack, SliderRange, SliderThumb, ThumbValue } from './MultiRangeSlider.styles';
import { useDetectionGetGestureScore } from '@/Models/Detection';
import useConfigurationStore from '@/Models/UserSession/store/useConfigurationStore';
const { IGNORE_THRESHOLD, CONFIRM_THRESHOLD } = GESTURE_SETTING_KEYS;
const MINIMUM_THRESHOLD_DISTANCE = 0.1;

interface MultiRangeSliderProps {
  gesture: TEYEGESTURE_TYPE;
}

const MultiRangeSlider: React.FC<MultiRangeSliderProps> = ({ gesture }) => {
  const ignoreThreshold = useGestureSetting(gesture, IGNORE_THRESHOLD);
  const confirmThreshold = useGestureSetting(gesture, CONFIRM_THRESHOLD);
  const { updateGestureSetting } = useGestureSettingsUpdate();
  const { updateStoredGestureSettings } = useConfigurationStore();
  const { score, normalizedScore } = useDetectionGetGestureScore(gesture);
  const [currentIgnoreThreshold, setCurrentIgnoreThreshold] = useState(ignoreThreshold || 0);
  const [currentConfirmThreshold, setCurrentConfirmThreshold] = useState(confirmThreshold || 1);
  const isDragging = useRef<TGESTURE_SETTING_KEYS | null>(null);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const calculateValue = (clientX: number) => {
    const sliderRect = sliderRef.current?.getBoundingClientRect();
    if (!sliderRect) return UI_CONTROL.GESTURE.THRESHOLD.MIN;

    let percent = (clientX - sliderRect.left) / sliderRect.width;
    percent = Math.max(0, Math.min(1, percent));
    const range = UI_CONTROL.GESTURE.THRESHOLD.MAX - UI_CONTROL.GESTURE.THRESHOLD.MIN;
    const newValue = Math.round((UI_CONTROL.GESTURE.THRESHOLD.MIN + percent * range) * 20) / 20;

    return newValue;
  };

  const handleMouseDown = (e: React.MouseEvent, type: TGESTURE_SETTING_KEYS) => {
    isDragging.current = type;

    if (sliderRef.current) {
      sliderRef.current.style.pointerEvents = 'none';
    }
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDragging.current) return;

      const value = calculateValue(e.clientX);

      if (isDragging.current === IGNORE_THRESHOLD) {
        const maxAllowed = currentConfirmThreshold - MINIMUM_THRESHOLD_DISTANCE;
        const newValue = Math.min(Math.max(value, UI_CONTROL.GESTURE.THRESHOLD.MIN), maxAllowed);

        setCurrentIgnoreThreshold(prev => {
          if (prev !== newValue) {
            updateGestureSetting(gesture, IGNORE_THRESHOLD, newValue);
            updateStoredGestureSettings(gesture, { [IGNORE_THRESHOLD]: newValue });
            return newValue;
          }
          return prev;
        });
      } else {
        const minAllowed = currentIgnoreThreshold + MINIMUM_THRESHOLD_DISTANCE;
        const newValue = Math.max(Math.min(value, UI_CONTROL.GESTURE.THRESHOLD.MAX), minAllowed);

        setCurrentConfirmThreshold(prev => {
          if (prev !== newValue) {
            updateGestureSetting(gesture, CONFIRM_THRESHOLD, newValue);
            updateStoredGestureSettings(gesture, { [CONFIRM_THRESHOLD]: newValue });
            return newValue;
          }
          return prev;
        });
      }
    },
    [currentConfirmThreshold, currentIgnoreThreshold, updateGestureSetting, gesture]
  );

  const handleMouseUp = () => {
    isDragging.current = null;

    if (sliderRef.current) {
      sliderRef.current.style.pointerEvents = 'auto';
    }
  };

  const handleTouchStart = (e: React.TouchEvent, type: TGESTURE_SETTING_KEYS) => {
    if (e.touches.length > 1) return;
    isDragging.current = type;
    if (sliderRef.current) {
      sliderRef.current.style.pointerEvents = 'none';
    }
  };

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (!isDragging.current || e.touches.length > 1) return;
      const touch = e.touches[0];
      const value = calculateValue(touch.clientX);

      if (isDragging.current === IGNORE_THRESHOLD) {
        const maxAllowed = currentConfirmThreshold - MINIMUM_THRESHOLD_DISTANCE;
        const newValue = Math.min(Math.max(value, UI_CONTROL.GESTURE.THRESHOLD.MIN), maxAllowed);

        setCurrentIgnoreThreshold(prev => {
          if (prev !== newValue) {
            updateGestureSetting(gesture, IGNORE_THRESHOLD, newValue);
            updateStoredGestureSettings(gesture, { [IGNORE_THRESHOLD]: newValue });
            return newValue;
          }
          return prev;
        });
      } else {
        const minAllowed = currentIgnoreThreshold + MINIMUM_THRESHOLD_DISTANCE;
        const newValue = Math.max(Math.min(value, UI_CONTROL.GESTURE.THRESHOLD.MAX), minAllowed);

        setCurrentConfirmThreshold(prev => {
          if (prev !== newValue) {
            updateGestureSetting(gesture, CONFIRM_THRESHOLD, newValue);
            updateStoredGestureSettings(gesture, { [CONFIRM_THRESHOLD]: newValue });
            return newValue;
          }
          return prev;
        });
      }
    },
    [currentConfirmThreshold, currentIgnoreThreshold, updateGestureSetting, gesture]
  );

  const handleTouchEnd = () => {
    isDragging.current = null;
    if (sliderRef.current) {
      sliderRef.current.style.pointerEvents = 'auto';
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    slider.addEventListener('touchstart', handleTouchStart as any, { passive: false });
    slider.addEventListener('touchmove', handleTouchMove, { passive: false });
    slider.addEventListener('touchend', handleTouchEnd);

    return () => {
      slider.removeEventListener('touchstart', handleTouchStart as any);
      slider.removeEventListener('touchmove', handleTouchMove);
      slider.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchMove]);

  useEffect(() => {
    if (isDragging.current !== null) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging.current]);

  const getPercent = (value: number) => Math.round(((value - UI_CONTROL.GESTURE.THRESHOLD.MIN) / (UI_CONTROL.GESTURE.THRESHOLD.MAX - UI_CONTROL.GESTURE.THRESHOLD.MIN)) * 100);

  return (
    <RangeContainer>
      <RangeLabel>
        <span>Rango de Detección</span>
        <span>
          {currentIgnoreThreshold.toFixed(2)} - {currentConfirmThreshold.toFixed(2)}
        </span>
        <span>{normalizedScore.toFixed(2)}</span>
      </RangeLabel>
      <RangeSlider id="gesture-slider" ref={sliderRef}>
        <SliderTrack $isDragging={isDragging.current !== null} />

        <SliderRange style={{ left: `${getPercent(currentIgnoreThreshold)}%`, right: `${100 - getPercent(currentConfirmThreshold)}%` }} />
        <SliderRange $active style={{ left: `${getPercent(currentConfirmThreshold)}%`, right: `0%` }} />
        <SliderRange $score={normalizedScore} style={{ left: `0%`, right: `${100 - getPercent(normalizedScore)}%` }} />
        <SliderThumb
          style={{ left: `${getPercent(currentIgnoreThreshold)}%`, backgroundColor: isDragging.current === IGNORE_THRESHOLD ? 'blue' : 'gray' }}
          onMouseDown={e => handleMouseDown(e, IGNORE_THRESHOLD)}
          onTouchStart={e => handleTouchStart(e, IGNORE_THRESHOLD)}
        />
        <SliderThumb
          $active
          style={{ left: `${getPercent(currentConfirmThreshold)}%`, backgroundColor: isDragging.current === CONFIRM_THRESHOLD ? 'blue' : 'gray' }}
          onMouseDown={e => handleMouseDown(e, CONFIRM_THRESHOLD)}
          onTouchStart={e => handleTouchStart(e, CONFIRM_THRESHOLD)}
        />
        {isDragging.current === IGNORE_THRESHOLD && <ThumbValue $left={`${getPercent(currentIgnoreThreshold)}%`}>{currentIgnoreThreshold.toFixed(2)}</ThumbValue>}
        {isDragging.current === CONFIRM_THRESHOLD && <ThumbValue $left={`${getPercent(currentConfirmThreshold)}%`}>{currentConfirmThreshold.toFixed(2)}</ThumbValue>}
      </RangeSlider>
    </RangeContainer>
  );
};

export default MultiRangeSlider;
