import React, { useEffect } from 'react';
import { getDeviceType } from '@/Layout/breakpoints';
import { HelperContainer, InfoRow } from './MediaQueryHelper.styles';
import useGestureKeyboardStore from '@/Models/Keyboard/store/useGestureKeyboardStore';

const MediaQueryHelper: React.FC = () => {
  // Usar el store para obtener y actualizar el estado
  const { deviceType, orientation } = useGestureKeyboardStore();
  const { setDevice, setOrientation } = useGestureKeyboardStore();

  useEffect(() => {
    const updateMediaInfo = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const isLandscape = width > height;
      const aspectRatio = width / height;

      // Actualizar orientación
      const newOrientation = isLandscape ? 'LANDSCAPE' : 'PORTRAIT';
      setOrientation(newOrientation);

      // Actualizar tipo de dispositivo
      const newDeviceType = getDeviceType(width, height);
      setDevice(newDeviceType);
    };

    // Actualización inicial
    updateMediaInfo();

    // Suscribirse a cambios de tamaño y orientación
    window.addEventListener('resize', updateMediaInfo);
    window.addEventListener('orientationchange', updateMediaInfo);

    return () => {
      window.removeEventListener('resize', updateMediaInfo);
      window.removeEventListener('orientationchange', updateMediaInfo);
    };
  }, [setDevice, setOrientation]);

  // Calcular información adicional para mostrar
  const width = window.innerWidth;
  const height = window.innerHeight;
  const aspectRatio = width / height;

  return (
    <HelperContainer>
      <InfoRow>Device Type: {deviceType}</InfoRow>
      <InfoRow>Orientation: {orientation}</InfoRow>
      <InfoRow>
        Dimensions: {width}x{height}
      </InfoRow>
      <InfoRow>Aspect Ratio: {aspectRatio.toFixed(3)}</InfoRow>
    </HelperContainer>
  );
};

export default MediaQueryHelper;
