import React from 'react';
import { Container, Title } from '@/Components/ConfigModal/ConfigModal.styles';
import LeftEyeStatusIndicator from './LeftEyeStatusIndicator';
import RightEyeStatusIndicator from './RightEyeStatusIndicator';
import EyeStatusLegend from './EyeStatusLegend';
import styled from 'styled-components';

const IndicatorsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin: 16px 0;
`;

interface IndicatorWrapperProps {
  $isActive?: boolean;
}

export const IndicatorWrapper = styled.div<IndicatorWrapperProps>`
  background: ${props => (props.$isActive ? '#007bffe7' : '#2a2a2a')};
  outline: 1px solid ${props => (props.$isActive ? '#007bff' : '#444')};
  border-radius: 6px;
  padding: 13px;
  transition: all 0.2s ease;
  min-width: 200px;

  &:hover {
    outline: 2px solid ${props => (props.$isActive ? 'white' : '#007bff')};
  }
`;

export const EyeContainer = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-between;
  padding: 13px 26px;
  width: 50%;
`;

export const StyledEyeControl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  min-width: 200px;
  /* width: 100%; */
`;

export const EyeControl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  min-width: 200px;
  width: 100%;
`;

export const EyeStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const EyeLabel = styled.span`
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.5rem;
`;

interface EyeStatusIndicatorProps {
  id?: string;
}

export const EyeStatusIndicator: React.FC<EyeStatusIndicatorProps> = ({ id }) => {
  return (
    <Container id={id}>
      <Title>Configuración Ojos</Title>
      <EyeStatusLegend />
      <IndicatorsContainer>
        <LeftEyeStatusIndicator />
        <RightEyeStatusIndicator />
      </IndicatorsContainer>
    </Container>
  );
};

export default EyeStatusIndicator;
