/**
 * @file WebcamComponent.tsx
 * @description Componente para manejar la cámara web
 */

// Importar referencias a tipos DOM explícitamente
/// <reference path="../../types/dom.d.ts" />

import React, { useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import { useDetectionStore } from '@/Models/Detection';
import styled from 'styled-components';

const HiddenWebcam = styled(Webcam)`
  position: absolute;
  width: 100px;
  height: 100px;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
`;

const WebcamComponent = () => {
  const isWebcamOn = useDetectionStore(state => state.webcam.isWebcamOn);
  const setWebcamStream = useDetectionStore(state => state.setWebcamStream);
  const setIsWebcamOn = useDetectionStore(state => state.setIsWebcamOn);
  const setVideoElement = useDetectionStore(state => state.setVideoElement);
  const setWebcamRef = useDetectionStore(state => state.setWebcamRef);

  const localWebcamRef = useRef<Webcam>(null);

  useEffect(() => {
    const hasGetUserMedia = () => {
      return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    };

    if (hasGetUserMedia() && localWebcamRef?.current) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: 640,
            height: 480,
            frameRate: { ideal: 30 },
          },
        })
        .then(stream => {
          if (localWebcamRef.current) {
            const video = localWebcamRef.current.video;
            if (video) {
              video.srcObject = stream;
              video.playsInline = true;
              setVideoElement(video);
              setWebcamStream(stream);
              setIsWebcamOn(true);
            }
          }
        })
        .catch(error => {
          console.error('Error accessing camera:', error);
        });
    }
  }, [isWebcamOn, setVideoElement, setWebcamStream, setIsWebcamOn]);

  useEffect(() => {
    if (localWebcamRef.current) {
      setWebcamRef(localWebcamRef);
      setIsWebcamOn(true);
    } else {
      setIsWebcamOn(false);
    }
  }, [localWebcamRef, setWebcamRef, setIsWebcamOn]);

  return (
    <HiddenWebcam
      audio={false}
      ref={localWebcamRef}
      videoConstraints={{
        width: 640,
        height: 480,
        frameRate: { ideal: 30 },
      }}
    />
  );
};

export default WebcamComponent;
