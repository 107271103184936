import React, { useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { TEYEGESTURE_TYPE, useGestureState, GESTUREMACHINE_STATE } from '@/Models/Gesture';
import { COLORS, SPACING } from '@/Components/GestureKeyboard/Keyboard.constants';
import check from '@/Assets/icons/check.svg';
import eyeActive from '@/Assets/icons/eye-active.svg';

const { IDLE, ACTIVE, CONFIRMING, COMPLETED } = GESTUREMACHINE_STATE;

// Constants for GestureCheck
const CHECK_SIZES = {
  CONTAINER: '120px',
  ICON: '80px',
  STROKE: '10',
  CIRCLE_RADIUS: '55',
} as const;

const pulseAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
`;

const CheckContainer = styled.div<{ $active?: boolean; $completed?: boolean; $visible: boolean }>`
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: all 0.3s ease;
  background-color: white;
  z-index: 100;
  width: ${CHECK_SIZES.CONTAINER};
  height: ${CHECK_SIZES.CONTAINER};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 ${SPACING.XS} ${SPACING.MD} rgba(0, 0, 0, 0.1);
  opacity: ${props => (props.$visible ? 1 : 0)};
  pointer-events: none;
  visibility: ${props => (props.$visible ? 'visible' : 'hidden')};

  ${({ $active, $completed }) =>
    $active &&
    !$completed &&
    css`
      animation: ${pulseAnimation} 1s ease-in-out infinite;
      opacity: 1;
    `}

  ${({ $completed }) =>
    $completed &&
    css`
      opacity: 1;
      transform: translate(-50%, -50%) scale(1.1);
    `}
`;

const CheckIcon = styled.img`
  width: ${CHECK_SIZES.ICON};
  height: ${CHECK_SIZES.ICON};
`;

const EyeIcon = styled.img`
  width: ${CHECK_SIZES.ICON};
  height: ${CHECK_SIZES.ICON};
`;

interface GestureCheckProps {
  gesture: TEYEGESTURE_TYPE;
  $visible?: boolean;
}

const GestureCheck: React.FC<GestureCheckProps> = React.memo(({ gesture, $visible }) => {
  // Referencias para la animación
  const progressRef = useRef(0);
  const animationFrameRef = useRef<number>();
  const lastTimeRef = useRef(0);

  // Estados del store

  const gestureState = useGestureState(gesture);

  // const { confirmation, config, startConfirmation, completeConfirmation, cancelConfirmation } = useGestureConfirmationStore();

  // Efecto para manejar la confirmación
  useEffect(() => {
    if (gestureState === IDLE) {
      progressRef.current = 0;
      lastTimeRef.current = 0;
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      return;
    }

    // Si está en confirmación, manejar la animación

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [gestureState]);

  return (
    <CheckContainer $visible={$visible || gestureState !== IDLE} $active={gestureState === ACTIVE || gestureState === CONFIRMING} $completed={gestureState === COMPLETED} id={`check-${gesture}`}>
      {gestureState === COMPLETED && <CheckIcon src={check} alt="check" />}
      {gestureState !== IDLE && (gestureState === ACTIVE || (gestureState === CONFIRMING && <EyeIcon src={eyeActive} alt="check" />))}
    </CheckContainer>
  );
});

GestureCheck.displayName = 'GestureCheck';

export default GestureCheck;
