import styled from 'styled-components';
import { device } from '@/Layout/breakpoints';

export const GestureFlagContainer = styled.div<{ $active?: boolean }>`
  position: relative;
  width: clamp(1.7em, 4vw, 40px);
  height: auto;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: transform;
  transition: transform 0.2s ease;

  ${props =>
    props.$active &&
    `
    transform: scale(1.05);
  `}

  ${device.mobilePortrait} {
    width: clamp(2.3em, 5vw, 3em);
  }
  ${device.mobileLandscape} {
    width: clamp(2em, 5vw, 50px);
  }
  ${device.tabletPortrait} {
    width: clamp(2.5em, 6vw, 60px);
  }
  ${device.tabletLandscape} {
    width: clamp(3em, 7vw, 70px);
  }
  ${device.desktopPortrait} {
    width: clamp(3.5em, 8vw, 80px);
  }
  ${device.desktopLandscape} {
    width: clamp(4em, 10vw, 100px);
  }
`;

export const ProgressCircle = styled.svg<{ $active?: boolean; $progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  will-change: transform;
  opacity: ${props => (props.$active ? 1 : 0)};
  transition: opacity 0.2s ease;

  circle {
    transform-origin: center;
    transition: stroke-dasharray 0.1s ease;
    stroke: ${props => (props.$progress >= 100 ? '#4CAF50' : '#FFFFFF')};
    opacity: ${props => (props.$active ? 0.8 : 0)};
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 60%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
`;
