import React, { useEffect, useCallback, useMemo } from 'react';
import { COMMANDS } from '@/Models/UserSession/types/domain';
import { EYEGESTURE_TYPE, GESTUREMACHINE_STATE, useGestureState } from '@/Models/Gesture';
import useGestureKeyboardStore from '@/Models/Keyboard/store/useGestureKeyboardStore';
import Check from '../../GestureFlag/Check/Check';
import PhraseContainer from '../PhraseContainer/PhraseContainer';
import { KeyboardSections, KeyboardSection, KeyboardKeys } from '../Keyboard.styles';
import { KeyboardKey } from '../components/KeyboardKey';
import { KeyboardContainer } from '../Keyboard.styles';
import useTextToSpeech from '@/Services/useTextToSpeech';
import useUserSessionStore from '@/Models/useUserSessionStore';
import useConfigurationStore from '@/Models/UserSession/store/useConfigurationStore';

const { ACTIVE, CONFIRMING, IDLE } = GESTUREMACHINE_STATE;
const { LOOK_LEFT, LOOK_RIGHT } = EYEGESTURE_TYPE;

export const KeyboardHorizontalSplit: React.FC = () => {
  // Memoized selectors for better performance
  const left = useGestureKeyboardStore(useCallback(state => state.left, []));
  const right = useGestureKeyboardStore(useCallback(state => state.right, []));
  const currentGesture = useGestureKeyboardStore(useCallback(state => state.currentGesture, []));
  const clearKeyboardGesture = useGestureKeyboardStore(useCallback(state => state.clearKeyboardGesture, []));
  const keyboardSpeakGesture = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.keyboardSpeakGesture);
  const configModalOpen = useConfigurationStore(state => state.configModalOpen);
  const pick = useGestureKeyboardStore(useCallback(state => state.pick, []));
  const phrase = useGestureKeyboardStore(useCallback(state => state.phrase, []));
  const pickKey = useGestureKeyboardStore(useCallback(state => state.pickKey, []));

  const loookLeftStatus = useGestureState(LOOK_LEFT);
  const loookRightStatus = useGestureState(LOOK_RIGHT);

  const showLeft = loookLeftStatus === ACTIVE || loookLeftStatus === GESTUREMACHINE_STATE.CONFIRMING;
  const showRight = loookRightStatus === ACTIVE || loookRightStatus === GESTUREMACHINE_STATE.CONFIRMING;

  // Initialize keyboard
  useEffect(() => {
    useGestureKeyboardStore.getState().init();
  }, []);

  // Memoized pick functions
  const pickLeft = useCallback(() => {
    pick(left.keys, left.commands);
  }, [left.keys, left.commands, pick]);

  const pickRight = useCallback(() => {
    pick(right.keys, right.commands);
  }, [right.keys, right.commands, pick]);

  // Handle gesture changes
  useEffect(() => {
    if (configModalOpen) return;

    if (currentGesture === LOOK_LEFT) {
      pickLeft();
    } else if (currentGesture === LOOK_RIGHT) {
      pickRight();
    } else if (currentGesture === keyboardSpeakGesture) {
      handleSynthesis(phrase);
      clearKeyboardGesture();
    }
  }, [currentGesture, pickLeft, pickRight, configModalOpen]);

  const { handleSynthesis } = useTextToSpeech();

  // Memoized click handler
  const handleClick = useCallback(
    (key: string) => {
      if (key === COMMANDS.SPEAK) {
        handleSynthesis(phrase);
      } else {
        pickKey(key);
      }
    },
    [handleSynthesis, phrase, pickKey]
  );

  const leftSideKeys = left.commands.concat(left.keys);
  const rightSideKeys = right.commands.concat(right.keys);

  // Memoized key rendering function
  const renderKeys = useCallback(
    (keys: string[], isLeft?: boolean) => (
      <KeyboardKeys $isLeft={isLeft} $layout="horizontal">
        {keys.map((key: string) => (
          <KeyboardKey key={key} value={key} onClick={handleClick} />
        ))}
      </KeyboardKeys>
    ),
    [handleClick]
  );

  // Memoized sections
  const leftSection = useMemo(
    () => (
      <KeyboardSection id="left-section" $isLeft={true} $isActive={showLeft} $isIdle={showRight}>
        {leftSideKeys.length > 0 && renderKeys(leftSideKeys, true)}
        <Check gesture={LOOK_LEFT} />
      </KeyboardSection>
    ),
    [leftSideKeys, loookLeftStatus, renderKeys]
  );

  const rightSection = useMemo(
    () => (
      <KeyboardSection id="right-section" $isActive={showRight} $isIdle={showLeft}>
        {rightSideKeys.length > 0 && renderKeys(rightSideKeys)}
        <Check gesture={LOOK_RIGHT} />
      </KeyboardSection>
    ),
    [right.keys, right.commands, loookRightStatus, renderKeys]
  );

  return (
    <KeyboardContainer id="horizontal-split-keyboard">
      <PhraseContainer />
      <KeyboardSections $layout="horizontal" id="horizontal-split-sections">
        {leftSection}
        {rightSection}
      </KeyboardSections>
    </KeyboardContainer>
  );
};
