/**
 * Defines which eyes are being used for detection
 * Can enable/disable left and right eye independently
 */
export interface IEyeDetectionSource {
  leftEye: boolean;
  rightEye: boolean;
}

export const defaultEyeDetectionSource: IEyeDetectionSource = {
  leftEye: true,
  rightEye: true,
};
