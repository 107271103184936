import styled from 'styled-components';

export const DoomPanel = styled.div<{ $isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: ${props => (props.$isVisible ? '0' : '-400px')};
  width: 400px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  border-right: 2px solid #00ff00;
  color: #00ff00;
  font-family: 'Courier New', monospace;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  transition: left 0.3s ease;
`;

export const ToggleButton = styled.button`
  position: absolute;
  top: 10px;
  right: -30px;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.9);
  border: 2px solid #00ff00;
  border-left: none;
  color: #00ff00;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'Courier New', monospace;
  font-weight: bold;
  z-index: 9999;
`;

export const PanelHeader = styled.div`
  padding: 10px;
  border-bottom: 1px solid #00ff00;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PanelContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Section = styled.div`
  margin-bottom: 10px;
`;

export const SectionTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  border-bottom: 1px dashed #00ff00;
  padding-bottom: 2px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
`;

export const Th = styled.th`
  text-align: left;
  padding: 4px;
  border-bottom: 1px solid #00ff00;
`;

export const Td = styled.td<{ $highlight?: boolean }>`
  padding: 4px;
  border-bottom: 1px dashed rgba(0, 255, 0, 0.3);
  color: ${props => (props.$highlight ? '#ffff00' : 'inherit')};
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`;

export const LogsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const LogSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  border-bottom: 1px dashed #00ff00;
  padding-bottom: 2px;
`;

export const LogContent = styled.div`
  max-height: 150px;
  overflow-y: auto;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border: 1px solid rgba(0, 255, 0, 0.3);
`;

export const LogEntry = styled.div<{ $type: 'activation' | 'confirmation' }>`
  margin: 2px 0;
  color: ${({ $type }) => ($type === 'activation' ? '#00ff00' : '#ffff00')};
`;
