import React, { useMemo, useState, useEffect } from 'react';
import plainCircle from '@/Assets/icons/plain-circle.svg';
import { GestureFlagContainer, ProgressCircle, IconWrapper } from './GestureFlag.styles';
import { ButtonIcon } from '@/Components/Shared/ButtonIcon';

/**
 * Props for the GestureFlag component
 */
export type GestureflagProps = {
  /** Whether the gesture is active */
  active?: boolean;
  /** URL of the SVG icon to display */
  svgUrl?: string;
  /** Optional class name for styling */
  className?: string;
  /** Whether to fill the progress circle completely */
  fill?: boolean;
  /** Color of the icon */
  iconColor?: string;
};

/**
 * Component that displays a gesture flag with optional progress animation
 */
export const Gestureflag: React.FC<GestureflagProps> = ({ active = false, svgUrl, className, fill = false, iconColor = 'currentColor' }) => {
  const [progress, setProgress] = useState(0);
  const [animationStartTime, setAnimationStartTime] = useState(0);
  const ANIMATION_DURATION = 1000; // 1 second animation duration

  useEffect(() => {
    if (fill) {
      // If fill is true, set progress to 100% immediately
      setProgress(100);
      return;
    }

    if (!active) {
      // If not active, reset progress
      setProgress(0);
      setAnimationStartTime(0);
      return;
    }

    // If becoming active, start the animation
    if (active && animationStartTime === 0) {
      setAnimationStartTime(Date.now());
    }

    // Animation logic
    const calculateProgress = () => {
      if (animationStartTime === 0) return 0;

      const now = Date.now();
      const elapsed = now - animationStartTime;
      const percentage = (elapsed / ANIMATION_DURATION) * 100;
      return Math.min(Math.max(percentage, 0), 100);
    };

    const updateProgress = () => {
      const currentProgress = calculateProgress();
      setProgress(currentProgress);

      if (currentProgress < 100 && active) {
        requestAnimationFrame(updateProgress);
      }
    };

    if (active) {
      requestAnimationFrame(updateProgress);
    }

    // Cleanup
    return () => {
      if (!active) {
        setAnimationStartTime(0);
      }
    };
  }, [active, fill, animationStartTime]);

  const dashArray = useMemo(() => {
    const circumference = 2 * Math.PI * 25;
    return `${(circumference * progress) / 100} ${circumference}`;
  }, [progress]);

  return (
    <GestureFlagContainer className={className} $active={active}>
      <ProgressCircle viewBox="0 0 50 50" $active={active} $progress={progress}>
        <circle cx="25" cy="25" r="23" fill="none" stroke="currentColor" strokeWidth="4" strokeDasharray={dashArray} strokeDashoffset="0" strokeLinecap="round" transform="rotate(-90 25 25)" />
      </ProgressCircle>
      <IconWrapper>
        <ButtonIcon src={svgUrl || plainCircle} alt="gesture-icon" $color={iconColor} />
      </IconWrapper>
    </GestureFlagContainer>
  );
};

export default Gestureflag;
