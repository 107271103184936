// Spacing
export const SPACING = {
  NONE: '0',
  XXS: '4px',
  XS: '8px',
  SM: '12px',
  MD: '16px',
  LG: '24px',
  XL: '32px',
} as const;

// Keyboard Layout Types
export type KeyboardLayout = 'horizontal' | 'vertical';

// Grid Layout
export const GRID = {
  TEMPLATE: {
    COLUMNS: {
      VERTICAL: '1fr',
      HORIZONTAL: '1fr 1fr',
    },
    ROWS: {
      VERTICAL: {
        DEFAULT: '35% 30% 35%',
        COMPACT: '33.33% 33.33% 33.33%',
        DESKTOP: '40% 20% 40%',
        TABLET: '43% 14% 43%',
      },
      HORIZONTAL: {
        DEFAULT: '1fr', // Single row for horizontal layout
        TABLET: '1fr',
        COMPACT: '1fr',
      },
    },
  },
  GAP: SPACING,
} as const;

// Layout
export const LAYOUT = {
  WIDTH: {
    FULL: '100%',
    FILL: '-webkit-fill-available',
  },
  HEIGHT: {
    FULL: '100%',
    FILL: '-webkit-fill-available',
  },
  PADDING: SPACING,
  MARGIN: SPACING,
} as const;

// Colors
export const COLORS = {
  BACKGROUND: {
    PRIMARY: '#000000',
    SECONDARY: '#1a1a1a',
    OVERLAY: 'rgba(0, 0, 0, 0.7)',
    ACCENT: {
      BLUE: 'rgba(120, 120, 240, 0.8)',
      PINK: 'rgba(255, 20, 147, 0.8)',
      GRAY: 'rgba(128, 128, 128, 0.8)',
    },
  },
  BORDER: {
    PRIMARY: '#333333',
    SECONDARY: '#444444',
  },
  DEBUG: {
    OUTLINE: {
      PINK: '#ff1493',
      BLUE: '#4169e1',
      GREEN: '#32cd32',
      GOLD: '#ffd700',
      WHITE: '#ffffff',
    },
    BACKGROUND: {
      PINK: 'rgba(255, 20, 147, 0.2)',
      BLUE: 'rgba(65, 105, 225, 0.2)',
      GREEN: 'rgba(50, 205, 50, 0.2)',
      GOLD: 'rgba(255, 215, 0, 0.2)',
    },
  },
} as const;

// Borders
export const BORDERS = {
  STYLE: {
    SOLID: 'solid',
    DOTTED: 'dotted',
    DASHED: 'dashed',
    DOUBLE: 'double',
    INSET: 'inset',
  },
  WIDTH: {
    THIN: '1px',
    MEDIUM: '2px',
    THICK: '4px',
  },
  RADIUS: {
    NONE: '0',
    SM: '4px',
    MD: '8px',
    LG: '12px',
    XL: '16px',
    FULL: '9999px',
  },
} as const;

// Animations
export const ANIMATIONS = {
  DURATION: {
    FAST: '150ms',
    NORMAL: '300ms',
    SLOW: '500ms',
  },
  TIMING: {
    EASE: 'ease',
    LINEAR: 'linear',
    EASE_IN: 'ease-in',
    EASE_OUT: 'ease-out',
    EASE_IN_OUT: 'ease-in-out',
  },
} as const;
