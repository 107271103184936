import styled from 'styled-components';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledSelect = styled.select`
  background-color: #2a2a2a;
  color: white;
  padding: 13px 26px;
  border: 1px solid #007bff;

  border-radius: 6px;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: #ffffff;
  }

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  option {
    background-color: #1a1a1a;
  }
`;

export const DisabledMessage = styled.p`
  color: #666;
  font-style: italic;
  margin: 4px 0;
  font-size: 0.9rem;
`;
