import { useCallback } from 'react';
import { useGestureTrackingStore, TGestureTrackingStore, getGestureStateFromState } from '../index';
import { TEYEGESTURE_TYPE } from '../types/base/gestureEnums';
import { GESTUREMACHINE_STATE, TGESTUREMACHINE_STATE } from '../types';

/**
 * Hook para obtener el estado actual de un gesto
 * @param gestureType - Tipo de gesto a consultar
 * @returns Estado actual del gesto
 */
export const useGestureState = (gestureType: TEYEGESTURE_TYPE): TGESTUREMACHINE_STATE => {
  return useGestureTrackingStore(useCallback((state: TGestureTrackingStore) => getGestureStateFromState(state, gestureType), [gestureType]));
};
