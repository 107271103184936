import { useEffect, useRef, useState, useCallback } from 'react';
import { useDetectionStore, TScoreTrackingState, EmptyScoreTrackingState } from '@/Models/Detection';
import { Category } from '@mediapipe/tasks-vision';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { EYEGESTURE_TYPE, TGestureDetectionSettings, TEYEGESTURE_TYPE, TGestureScores, useGestureSetting, GESTURE_SETTING_KEYS, TEYEGESTURE_TYPE_KEY } from '@/Models/Gesture';
import { useAllGestureSettings } from './useAllGestureSettings';
import { LANDMARK_SCORE_KEY } from '../types/landMarkConstants';
import { IEyeDetectionSource } from '../types/domain';

const { LOOK_UP, LOOK_DOWN, LOOK_LEFT, LOOK_RIGHT, BLINK } = EYEGESTURE_TYPE;
const { NORMALIZATION_FACTOR } = GESTURE_SETTING_KEYS;
const { EYE_BLINK_LEFT, EYE_BLINK_RIGHT, EYE_LOOK_DOWN_LEFT, EYE_LOOK_DOWN_RIGHT, EYE_LOOK_IN_LEFT, EYE_LOOK_IN_RIGHT, EYE_LOOK_OUT_LEFT, EYE_LOOK_OUT_RIGHT, EYE_LOOK_UP_LEFT, EYE_LOOK_UP_RIGHT } =
  LANDMARK_SCORE_KEY;
const getZeroedGestureObject = () => {
  return {
    [EYE_BLINK_LEFT]: 0,
    [EYE_BLINK_RIGHT]: 0,
    [EYE_LOOK_DOWN_LEFT]: 0,
    [EYE_LOOK_DOWN_RIGHT]: 0,
    [EYE_LOOK_IN_LEFT]: 0,
    [EYE_LOOK_IN_RIGHT]: 0,
    [EYE_LOOK_OUT_LEFT]: 0,
    [EYE_LOOK_OUT_RIGHT]: 0,
    [EYE_LOOK_UP_LEFT]: 0,
    [EYE_LOOK_UP_RIGHT]: 0,
  };
};

/**
 * Computes new scores based on the provided categories, eye source, and gesture detection settings.
 *
 * @param categories The categories to compute scores from.
 * @param eyeSource The eye source configuration.
 * @param settings The gesture detection settings.
 * @returns The computed scores.
 */
const getNewScores = (categories: Category[], eyeSource: IEyeDetectionSource, settings: TGestureDetectionSettings) => {
  const gestures = getZeroedGestureObject();

  categories.forEach((shape: Category) => {
    const { displayName, categoryName, score } = shape;
    const id = (displayName || categoryName) as LANDMARK_SCORE_KEY;
    if (Object.values(LANDMARK_SCORE_KEY).includes(id)) {
      gestures[id] = score;
    }
  });

  // console.log('gestures', gestures);
  const { eyeLookUpLeft, eyeLookUpRight, eyeLookDownLeft, eyeLookDownRight, eyeLookOutLeft, eyeLookOutRight, eyeBlinkLeft, eyeBlinkRight, eyeLookInLeft, eyeLookInRight } = gestures;

  /**
   * Checks if an eye is enabled based on the provided configuration.
   *
   * @param isLeft Whether to check the left eye.
   * @returns Whether the eye is enabled.
   */
  const isEyeEnabled = (isLeft: boolean): boolean => {
    return isLeft ? eyeSource.leftEye : eyeSource.rightEye;
  };

  /**
   * Computes the blink score based on the provided left and right scores.
   *
   * @param leftScore The left score.
   * @param rightScore The right score.
   * @returns The computed blink score.
   */
  const computeBlinkScore = (leftScore: number, rightScore: number): number => {
    const leftEnabled = isEyeEnabled(true);
    const rightEnabled = isEyeEnabled(false);

    return getValidScores(leftScore, rightScore, leftEnabled, rightEnabled);
  };

  /**
   * Computes the lateral gaze score based on the provided eye, left out score, and right in score.
   *
   * @param eye The eye to compute the score for.
   * @param leftOutScore The left out score.
   * @param rightInScore The right in score.
   * @returns The computed lateral gaze score.
   */
  const computeLateralGazeScore = (eye: string, leftOutScore: number, rightInScore: number): number => {
    const isLookingLeft = eye === LOOK_LEFT;
    const leftEnabled = isEyeEnabled(true);
    const rightEnabled = isEyeEnabled(false);

    const leftScore = leftEnabled ? leftOutScore : 0;
    const rightScore = rightEnabled ? rightInScore : 0;

    if (!leftEnabled && !rightEnabled) return 0;

    let finalScore = 0;
    if (leftEnabled && rightEnabled) {
      const primaryWeight = 0.7;
      const secondaryWeight = 0.3;
      if (isLookingLeft) {
        finalScore = leftScore * primaryWeight + rightScore * secondaryWeight;
      } else {
        finalScore = rightScore * primaryWeight + leftScore * secondaryWeight;
      }
    } else if (leftEnabled) {
      finalScore = leftScore;
    } else if (rightEnabled) {
      finalScore = rightScore;
    }

    return finalScore;
  };

  /**
   * Computes the vertical gaze score based on the provided left score, right score, and gesture type.
   *
   * @param leftScore The left score.
   * @param rightScore The right score.
   * @param _gestureType The gesture type.
   * @returns The computed vertical gaze score.
   */
  const computeVerticalGazeScore = (leftScore: number, rightScore: number, _gestureType: TEYEGESTURE_TYPE): number => {
    const leftEnabled = isEyeEnabled(true);
    const rightEnabled = isEyeEnabled(false);

    if (!leftEnabled && !rightEnabled) return 0;

    return getValidScores(leftScore, rightScore, leftEnabled, rightEnabled) / (leftEnabled && rightEnabled ? 2 : 1);
  };

  /**
   * Returns valid scores based on the provided left score, right score, left enabled, and right enabled.
   *
   * @param leftScore The left score.
   * @param rightScore The right score.
   * @param isLeftEnabled Whether the left eye is enabled.
   * @param isRightEnabled Whether the right eye is enabled.
   * @returns The valid scores.
   */
  const getValidScores = (leftScore: number, rightScore: number, isLeftEnabled: boolean, isRightEnabled: boolean) => {
    if (!isLeftEnabled && !isRightEnabled) return 0;
    return (isLeftEnabled ? leftScore : 0) + (isRightEnabled ? rightScore : 0);
  };

  const record: TGestureScores = {
    [LOOK_UP]: computeVerticalGazeScore(eyeLookUpLeft, eyeLookUpRight, LOOK_UP),
    [LOOK_DOWN]: computeVerticalGazeScore(eyeLookDownLeft, eyeLookDownRight, LOOK_DOWN),
    [LOOK_LEFT]: computeLateralGazeScore(LOOK_LEFT, eyeLookOutLeft, eyeLookInRight),
    [LOOK_RIGHT]: computeLateralGazeScore(LOOK_RIGHT, eyeLookInLeft, eyeLookOutRight),
    [BLINK]: computeBlinkScore(eyeBlinkLeft, eyeBlinkRight),
  };

  return record;
};

export const useDetectionComputeScores = () => {
  const eyeSource = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.eyeSource);
  //todo: moved to gesture context , make a per gesture history depth
  const historyDepth = 5;
  const gestureDetectionSettings = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.gestureDetection);
  const result = useDetectionStore(state => state.faceLandMark.result);
  const setGestureScores = useDetectionStore(state => state.setGestureScores);
  const allGestureSettings = useAllGestureSettings();
  const paused = useDetectionStore(state => state.paused);
  const setBenchmark = useDetectionStore(state => state.setBenchmark);

  const [scoresState, setScoresState] = useState<TScoreTrackingState>({ ...EmptyScoreTrackingState });
  const callCounterRef = useRef(0);
  const lastMaxUpdateRef = useRef(Date.now());
  const maxValuesRef = useRef({
    look_up: 0,
    look_down: 0,
    look_left: 0,
    look_right: 0,
    blink: 0,
  });

  // Reset counter every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setBenchmark({ CPS: callCounterRef.current });
      callCounterRef.current = 0;
    }, 1000);

    return () => clearInterval(intervalId);
  }, [setBenchmark]); // Agregamos setBenchmark como dependencia

  // Función helper para normalizar scores
  const normalizeScore = useCallback(
    (score: number, gestureType: TEYEGESTURE_TYPE): number => {
      const normFactor = allGestureSettings[gestureType]?.[NORMALIZATION_FACTOR] ?? 1.0;
      // Escala el score usando normFactor como el nuevo valor máximo (100%)
      return Math.min(1, score / normFactor);
    },
    [gestureDetectionSettings]
  );

  const normalizeScores = useCallback(
    (scores: TGestureScores): TGestureScores => {
      const newScores: TGestureScores = {} as TGestureScores;
      (Object.keys(scores) as (keyof typeof scores)[]).forEach((category: TEYEGESTURE_TYPE) => {
        // console.log(category, category in EYEGESTURE_TYPE);
        if (Object.values(EYEGESTURE_TYPE).includes(category)) {
          // console.log('normalizando', category, scores[category]);
          newScores[category as keyof TGestureScores] = normalizeScore(scores[category], category as TEYEGESTURE_TYPE);
        }
      });
      return newScores;
    },
    [normalizeScore]
  );

  const updateScores = useCallback(
    (categories: Category[]) => {
      callCounterRef.current += 1;
      const newScores = getNewScores(categories, eyeSource, gestureDetectionSettings);
      // console.log('newScores', newScores);
      const normalized: TGestureScores = normalizeScores(newScores);
      // console.log('normalized', normalized);
      setScoresState(prevState => ({
        ...prevState,
        last: newScores,
      }));
      setGestureScores(normalized, newScores);
    },
    [eyeSource, gestureDetectionSettings, scoresState.last]
  );

  useEffect(() => {
    if (!paused) {
      // console.log('result', result.faceBlendshapes[0].categories);
      updateScores(result?.faceBlendshapes?.[0]?.categories || []);
    }
  }, [result, updateScores, paused]); // Agregamos updateScores como dependencia

  return scoresState;
};
