export const defaultTheme = {
  spacing: {
    xxxs: '0.2em',
    xxs: '0.5em',
    xs: '0.75em',
    sm: '1em',
    md: '1.25em',
    lg: '1.5em',
    xl: '2em',
    xxl: '3em',
  },
  colors: {
    background: {
      primary: '#1a1a1a',
      secondary: '#222',
      overlay: 'rgba(0, 0, 0, 0.7)',
      header: '#1a1a1aa6',
      danger: {
        hover: '#ff000066',
        active: '#ff000088',
      },
    },
    border: {
      primary: '#333',
      width: {
        sm: '1px',
        md: '2px',
        lg: '3px',
      },
    },
    text: {
      primary: 'white',
      secondary: '#888',
      muted: '#666',
    },
    button: {
      primary: {
        bg: '#007bff',
        border: '#0056b3',
        hover: '#0056b3',
      },
      secondary: {
        bg: 'transparent',
        border: '#333',
        hover: '#444',
      },
    },
    accent: {
      blue: '#007AFF',
    },
  },
  borderRadius: {
    sm: '6px',
    md: '8px',
    lg: '10px',
  },
  transitions: {
    normal: '0.2s ease',
  },
  shadows: {
    modal: '0 5px 15px rgba(0, 0, 0, 0.3)',
  },
};

// Type definition for the theme
export type Theme = typeof defaultTheme;
